<template>
    <q-step 
        :number="index" 
        :id="index" 
        :name="step.name" 
        :about="step.about" 
        :value="step.value" 
        imageHref="/statics/img/RowTable.png" 
        class="step" 
        width="90%"
    >
        <indicator-table 
            v-model="referenceContexts" 
            :loading="loading" 
            :dropdownOptions="dropdownOptions"
            :filter="filter"
            searchable 
            @input="onTableUpdated" 
            @filterUpdated="handleFilterUpdated" 
            @searchUpdated="handleSearchUpdated"
        ></indicator-table>
    </q-step>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import IndicatorTable from '../indicatortable/IndicatorTable.vue';

import { compareReferenceContextNames } from "../utils";
import { IntrospectionFragmentMatcher } from 'apollo-boost';

export default {
    name: 'report-step-data',
    components: {
        IndicatorTable
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        step: {
            type: Object,
            required: true
        },
        // the index of the step the report flow is currently in
        activeStep: {
            type: Number,
            default: null
        },
        report: {
            type: Object,
            required: true
        },
        indicators: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            data: [],
            referenceContexts: [],
            loading: true,
            filter: {
                search: '',
                indicator: 'reference'
            },
            initialised: false, 
            dropdownOptions: [
                {
                    label: 'Kenmerken',
                    value: 'reference'
                },
                {
                    label: 'Vragen',
                    value: 'question'
                },
            ], 
        }
    },
    methods: {
        onTableUpdated(contexts) {
            this.referenceContexts = contexts;

            const projection = this.buildConfigurationProjection();

            const updatedReport = { ...this.report };
            updatedReport.configuration.projection = projection;
            this.$emit('reportUpdated', updatedReport);
        },
        handleFilterUpdated: _.debounce(function(filter) {
            this.filter = filter;
            this.updateReferenceContexts();
        }, 200),
        handleSearchUpdated(search) {
            this.filter.search = search;
        },
        async updateReferenceContexts() {
            if(this.activeStep !== this.index-1 && this.initialised) return
            if(this.indicators.length === 0) return this.loading = true

            if(!this.initialised) this.loading = true;
            this.getReferenceContextsForIndicatorsOrQuestions();    
        },
        async getReferenceContextsForIndicatorsOrQuestions() {
            const indicatorType = this.filter.indicator;

            const relevantIndicators = this.indicators.filter(indicator => indicator.type === indicatorType);

            const contexts = this.labels.map(label => { return { id: label.id, title: label.name, masterId: label.masterId }})

            let referenceProjections = this.report.configuration.projection.filter(projection => projection.type !== 'projectName' && projection.type !== 'members' && projection.type !== 'empty');

            const parsedContexts = contexts.map(context => {
                const indicators = relevantIndicators.filter(indicator => indicator.labelIds.includes(context.id) || indicator.labelIds.includes(context.masterId));

                context.items = indicators.map(indicator => {
                    let checked = false;
                    let filters = { value: undefined, type: 'lt' };

                    if (referenceProjections) {
                        let matchingProjections = referenceProjections.find(projection => projection.indicator && projection.indicator.id === indicator.id)

                        if (matchingProjections) {
                            filters = matchingProjections.indicator.filters;
                            checked = matchingProjections.visible;
                        }   
                    }  
                    
                    return {
                        id: indicator.id,
                        masterId: indicator.masterId,
                        name: indicator.name,
                        type: indicator.type,
                        answerType: indicator.answerType,
                        answerValues: indicator.answerValues,
                        filters,
                        checked
                    }
                })

                return context
            })

            this.referenceContexts = parsedContexts.filter(context => context.items.length > 0);

            const projectNameProjection = this.report.configuration.projection.find(projection => projection.type === 'projectName');

            this.referenceContexts.push({
                id: 'Overig', 
                items: [{
                    answerType: 'dropdown', 
                    filters: {
                        type: 'lt', 
                        value: undefined
                    },
                    checked: Boolean(projectNameProjection && projectNameProjection.visible),
                    masterId: null,
                    id: 'projectName',
                    name: 'projectName', 
                    type: 'projectName'
                }], 
                masterId: null, 
                title: 'Overig'
            })

            this.initialised = true;
            this.loading = false;
        
            return this.referenceContexts.sort(compareReferenceContextNames);
        },
        buildConfigurationProjection() {
            const projection = this.report.configuration.projection;
            
            let builtProjection = [ ...projection ];

            this.referenceContexts.forEach(context => {
                context.items
                    .forEach(item => {
                        const existingProjectionIndex = builtProjection.findIndex(reportProjection => [item.id, item.type].includes(reportProjection.id));
                        
                        if(!item.checked && existingProjectionIndex === -1) return
                        else if(existingProjectionIndex !== -1) {
                            builtProjection[existingProjectionIndex].visible = item.checked;
                        }
                        else if(item.checked) {
                            const newProjection = this.getProjection(item);
                            builtProjection.push(newProjection);
                        }
                    })
            });

            builtProjection = builtProjection.filter(projection => projection.visible || projection.filterable);

            return builtProjection;
        },
        getProjection(item) {     
            if (item.masterId && (item.masterId.includes('crow-services') || item.masterId.includes('crow-works')) && item.type === 'question') {
                const projection = {
                    id: item.id,
                    type: 'indicatorBetterPerformance',
                    visible: true,
                    filterable: item.filterable || false,
                    indicator: item
                }
                return projection;
            } else {
                switch (item.type) {
                case 'reference':
                case 'question':
                    return {
                        id: item.id,
                        type: 'indicator',
                        visible: true,
                        filterable: item.filterable || false,
                        indicator: item
                    }
                default:
                    return {
                        id: item.type,
                        type: item.type,
                        visible: true,
                        filterable: false
                    } 
                }
            }  
        },
        async getOrganisations(organisationType) {
            return new Promise((resolve, reject) => {
                this.$apollo.query({
                    query: gql`
                        query Organisations($first: Int, $skip: Int, $where: JSON, $sort: String, $caseSensitive: Boolean) {
                            organisations(
                                first: $first
                                skip: $skip
                                where: $where
                                sort: [$sort]
                                caseSensitive: $caseSensitive
                            ) {
                                id
                                name
                            }
                        }
                    `,
                    variables: {
                        where: {
                            AND: [
                                {
                                    name__includes: this.filter.search || ''
                                },
                                organisationType !== 'Branch' ? {
                                    products__some: {
                                        slug__contains: organisationType.toLowerCase(),
                                        enabled: true
                                    }
                                } : {}, 
                                organisationType !== 'Branch' ? {
                                    type: 'main'
                                } : {
                                    type: 'branch'
                                }
                            ]
                        },
                        first: 10
                    }
                })
                .then(result => {
                    resolve(result.data.organisations)
                })
                .catch(error => reject(error.message))
            })
        },
        
    },
    mounted() {
        this.updateReferenceContexts();
    },
    watch: {
        indicators: {
            handler() {
                this.updateReferenceContexts();
            },
            deep: true
        },
        report: {
            handler() {
                this.updateReferenceContexts();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>

</style>