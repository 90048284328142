import gql from 'graphql-tag';

//MUTATIONS

export const CREATE_ROLE = gql`
    mutation CreateRole($organisationId: String!, $role: RoleInput!) {
        role_create(organisationId: $organisationId, role: $role) {
            id
            name
            status
            permissions {
                type
                permissions
                subContext
            }
        }
    }
`;

//update project settings

export const UPDATE_ROLE = gql`
    mutation UpdateRole($id: String!, $name: String!, $permissions: [PermissionInput]) {
        role_update(id: $id, name: $name, permissions: $permissions) {
            id
            name
            status
            permissions {
                type
                permissions
                subContext
            }
        }
    }
`;

export const DELETE_ROLE = gql`
    mutation DeleteRole($id: String!) {
        role_delete(id: $id) {
            id
        }
    }
`;

export const ADD_CROW_FORM = gql`
    mutation AddCrowForm(
        $name: String!
        $projectId: String!
        $clientId: String!
        $contractorId: String!
        $crowType: String!
        $formVersion: String!
        $durability: Boolean!
        $amsterdamPricing: Boolean!
        $categoryId: String
        $organisationId: String!
    ) {
        form_crowAddForm(
            name: $name
            projectId: $projectId
            clientId: $clientId
            contractorId: $contractorId
            crowType: $crowType
            formVersion: $formVersion
            durability: $durability
            amsterdamPricing: $amsterdamPricing
            categoryId: $categoryId
            organisationId: $organisationId
        )
    }
`;

export const UPDATE_PROJECT = gql`
    mutation updateProject($id: String!, $name: String, $clientProjectName: String, $contractorProjectName: String) {
        project_update(id: $id, name: $name, clientProjectName: $clientProjectName, contractorProjectName: $contractorProjectName) {
            id
            name
            clientProjectName
            contractorProjectName
        }
    }
`;

export const PUBLISH_PROJECT = gql`
    mutation publishProject($id: String!) {
        project_publish(id: $id) {
            id
            status
            clientStatus
            contractorStatus
        }
    }
`;

export const FINISH_PROJECT = gql`
    mutation finishProject($id: String!) {
        project_finish(id: $id) {
            id
            status
            clientStatus
            contractorStatus
        }
    }
`;

export const PROJECT_REPAIR_FORMIDS = gql`
    mutation repairProjectFormIds($id: String!) {
        project_repairFormIds(id: $id)
    }
`;

export const PROJECT_ADD_MEMBER = gql`
    mutation addMemberToProject(
        $id: String!
        $userId: String
        $email: String
        $roles: [String]
        $organisationId: String!
    ) {
        project_addMember(id: $id, userId: $userId, email: $email, roles: $roles, organisationId: $organisationId) {
            id
            members {
                userId
                user {
                    id
                    firstName
                    lastName
                    avatar
                }
                status
                roles
                email
                organisationId
                organisation {
                    id
                    name
                    logo
                }
            }
        }
    }
`;

export const PROJECT_CHANGE_MEMBER = gql`
    mutation changeMemberForProject(
        $id: String!
        $userId: String!
        $status: String
        $roles: [String]
        $organisationId: String!
    ) {
        project_changeMember(
            id: $id
            userId: $userId
            status: $status
            roles: $roles
            organisationId: $organisationId
        ) {
            id
        }
    }
`;

export const PROJECT_REMOVE_MEMBER = gql`
    mutation removeMemberForProject($id: String!, $userId: String, $organisationId: String!, $index: Int) {
        project_removeMember(id: $id, userId: $userId, organisationId: $organisationId, index: $index) {
            id
        }
    }
`;

export const DELETE_FORM = gql`
    mutation deleteForm($id: String!) {
        form_delete(id: $id) {
            id
        }
    }
`;

export const DELETE_PROJECT = gql`
    mutation deleteProject($id: String!) {
        project_delete(id: $id) {
            id
        }
    }
`;

export const CREATE_CROW_PROJECT = gql`
    mutation createProjectCrow($title: String!, $organisationId: String!, $organisationType: String!) {
        project_createCrow(name: $title, organisationId: $organisationId, organisationType: $organisationType) {
            id
            name
            indicatorIds
            formIds
            references {
                id
                name
            }
        }
    }
`;

// Values

export const SAVE_VALUE = gql`
    mutation SetValue(
        $componentId: String!
        $responseId: String
        $indicatorId: String!
        $formId: String!
        $organisationId: String!
        $value: JSON
        $satisfied: String
        $improvement: String
        $projectJwt: String!
    ) {
        value_set(
            valueIdentity: {
                componentId: $componentId
                responseId: $responseId
                indicatorId: $indicatorId
                formId: $formId
            }
            organisationId: $organisationId
            value: $value
            satisfied: $satisfied
            improvement: $improvement
            projectJwt: $projectJwt
        ) {
            id
            responseId
            value
            satisfied
            improvement
        }
    }
`;

export const SAVE_VALUE_VALUE = gql`
    mutation SetValueValue(
        $componentId: String!
        $responseId: String
        $indicatorId: String!
        $formId: String!
        $organisationId: String!
        $value: JSON,
        $projectJwt: String!
    ) {
        value_setValue(
            valueIdentity: {
                componentId: $componentId
                responseId: $responseId
                indicatorId: $indicatorId
                formId: $formId
            }
            organisationId: $organisationId
            value: $value
            projectJwt: $projectJwt
        ) {
            id
            value
            responseId
        }
    }
`;

export const SAVE_VALUE_AGREEMENT = gql`
    mutation SetValueAgreement(
        $componentId: String!
        $responseId: String
        $indicatorId: String!
        $formId: String!
        $organisationId: String!
        $agreement: String
        $projectJwt: String!
    ) {
        value_setAgreement(
            valueIdentity: {
                componentId: $componentId
                responseId: $responseId
                indicatorId: $indicatorId
                formId: $formId
            }
            organisationId: $organisationId
            agreement: $agreement
            projectJwt: $projectJwt
        ) {
            id
            agreement
            responseId
        }
    }
`;

export const SAVE_VALUE_SATISFIED = gql`
    mutation SetValueSatisfied(
        $componentId: String!
        $responseId: String
        $indicatorId: String!
        $formId: String!
        $organisationId: String!
        $satisfied: String
        $projectJwt: String!
    ) {
        value_setSatisfied(
            valueIdentity: {
                componentId: $componentId
                responseId: $responseId
                indicatorId: $indicatorId
                formId: $formId
            }
            organisationId: $organisationId
            satisfied: $satisfied
            projectJwt: $projectJwt
        ) {
            id
            satisfied
            responseId
        }
    }
`;

export const SAVE_VALUE_IMPROVEMENT = gql`
    mutation SetValueImprovement(
        $componentId: String!
        $responseId: String
        $indicatorId: String!
        $formId: String!
        $organisationId: String!
        $improvement: String
        $projectJwt: String!
    ) {
        value_setImprovement(
            valueIdentity: {
                componentId: $componentId
                responseId: $responseId
                indicatorId: $indicatorId
                formId: $formId
            }
            organisationId: $organisationId
            improvement: $improvement
            projectJwt: $projectJwt
        ) {
            id
            improvement
            responseId
        }
    }
`;

export const SAVE_SURVEY = gql`
    mutation saveSurvey($values: [valuesItemInput], $user: valueUserInput!) {
        value_saveSurvey(values: $values, user: $user) {
            id
        }
    }
`;

// Form

export const SEND_FORM = gql`
    mutation SendForm($id: String!) {
        form_send(id: $id) {
            id
            status
        }
    }
`;

export const AGREE_FORM = gql`
    mutation AgreeForm($id: String!) {
        form_agree(id: $id) {
            id
            status
        }
    }
`;

export const DISAGREE_FORM = gql`
    mutation DisagreeForm($id: String!) {
        form_disagree(id: $id) {
            id
            status
        }
    }
`;

export const ADMIN_AGREE_FORM = gql`
    mutation AdminAgreeForm($id: String!) {
        form_adminAgree(id: $id) {
            id
            status
        }
    }
`;

export const ADMIN_DISAGREE_FORM = gql`
    mutation AdminDisagreeForm($id: String!) {
        form_adminDisagree(id: $id) {
            id
            status
        }
    }
`;

export const CREATE_FORM = gql`
    mutation CreateForm($name: String!, $projectId: String!, $organisationId: String!, $templateId: String!, $status: String!, $categoryId: String!) {
        form_create(name: $name, projectId: $projectId, organisationId: $organisationId, templateId: $templateId, status: $status, categoryId: $categoryId) {
            id
            name
            status
            score
            settings {
                attendees {
                    id
                    avatar
                    firstName
                    lastName
                }
            }
            responses {
                values {
                    value
                }
            }
            template {
                id
                name
                masterId
                type
                answerType
            }
            companions {
                id
                score
                status
                responses {
                    values {
                        value
                    }
                }
            }
            organisationId
            finishedDate
        }
    }
`;

export const DUPLICATE_FORM = gql`
    mutation DuplicateForm($formId: String!, $name: String!, $projectId: String! $categoryId: String!) {
        form_duplicated(formId: $formId, name: $name, projectId: $projectId, categoryId: $categoryId) {
            id
            name
            status
            score
            settings {
                attendees {
                    id
                    avatar
                    firstName
                    lastName
                }
            }
            responses {
                values {
                    value
                }
            }
            template {
                id
                name
                masterId
                type
                answerType
            }
            companions {
                id
                score
                status
                responses {
                    values {
                        value
                    }
                }
            }
            organisationId
            finishedDate
        }
    }

`;

export const UPDATE_FORM = gql`
    mutation updateForm($id: String!, $name: String, $description: String, $settings: JSON, $components: [ComponentInput]) {
        form_update(id: $id, name: $name, description: $description, settings: $settings, components: $components) {
            id
        }
    }
`;

export const MARK_FORM_SENT  = gql`
    mutation formSurveySent($formId: String!) {
        form_surveySent(formId: $formId) {
            status
        }
    }
`;

export const FINISH_FORM = gql`
    mutation finishForm($id: String!) {
        form_finish(id: $id) {
            status
            settings {
                shareToken
                note
                attendees {
                    id
                    avatar
                    firstName
                    lastName
                }
                survey {
                    sendDate
                    availability {
                        from
                        to
                    }
                    completionMessageEnabled
                    completionMessage
                    startMessageEnabled
                    startMessage
                    enableNameQuestion
                    enableEmailQuestion
                    enableFunctionQuestion
                }
            }
            finishedDate
            finishedBy {
                id
                firstName
                lastName
            }
        }
    }
`;

// Authentication

export const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        user_login(email: $email, password: $password) {
            jwt
            refreshToken
            twofactorRequired
            twofactorQRcode
            user {
                id
                firstName
                lastName
                email
                status
                avatar
                phone
                changeEmail                
            }
        }
    }
`;

export const REFRESH = gql`
    mutation Refresh($refreshToken: String!) {
        user_refreshJwt(refreshToken: $refreshToken) {
            jwt
            refreshToken
            user {
                id
                firstName
                lastName
                email
                status
                avatar
                phone
                changeEmail
            }
        }
    }
`;

export const TWOFACTOR = gql`
    mutation TwofactorLogin($token: String!) {
        user_twofactorLogin(token: $token) {
            jwt
            refreshToken
            user {
                id
                firstName
                lastName
                email
                status
            }
        }
    }
`;

export const REGISTER = gql`
    mutation registerUser(
        $email: String!
        $password: String!
        $confirmPassword: String!
        $phone: String
        $firstName: String
        $lastName: String
    ) {
        user_register(
            user: {
                email: $email
                password: $password
                confirmPassword: $confirmPassword
                phone: $phone
                firstName: $firstName
                lastName: $lastName
            }
        ) {
            jwt
            user {
                id
                firstName
                lastName
                email
                status
                avatar
                phone
            }
        }
    }
`;

export const REGISTER_ORGANISATION = gql`
    mutation org_register($name: String!, $address: JSON!, $registrationId: String!, $branchId: String!, $organisationType: String) {
        org_register(name: $name, address: $address, registrationId: $registrationId, branchId: $branchId, organisationType: $organisationType) {
            name
            id
        }
    }
`;

export const ORGANISATION_INVITE_USER_REMOTE = gql`
    mutation org_inviteUserRemote($id: String!, $email: String!, $roleId: String) {
        org_inviteUserRemote(id: $id, email: $email, roleId: $roleId) {
            id
            users {
                userId
                status
                email
            }
        }
    }
`;

export const ORGANISATION_USER_INVITE = gql`
    mutation OrganisationUserInvite($id: String!, $email: String!, $roleId: String) {
        org_inviteUser(id: $id, email: $email, roleId: $roleId) {
            id
            users {
                userId
                status
                email
            }
        }
    }
`;

export const ORGANISATION_SET_MAIN_CONFIGURATION = gql`
    mutation OrganisationMainConfiguration ($id: String!, $mainConfigurationId: String) {
        org_updateMainConfiguration(id: $id, mainConfigurationId: $mainConfigurationId) {
            mainConfigurationId
        }
    }
`;

export const ACCEPT_ORGANISATION_INVITE = gql`
    mutation ($id: String!, $organisationId: String!) {
        user_acceptOrganisationInvite(id: $id, organisationId: $organisationId) {
            id
        }
    }
`;

export const DECLINE_ORGANISATION_INVITE = gql`
    mutation ($id: String!, $organisationId: String!) {
        user_declineOrganisationInvite(id: $id, organisationId: $organisationId) {
            id
        }
    }
`;

export const ORGANISATION_REMOVE_USER = gql`
    mutation OrganisationRemoveUser($id: String!, $userId: String!) {
        org_removeUser(id: $id, userId: $userId) {
            id
            users {
                userId
                status
                email
            }
        }
    }
`;

export const ORGANISATION_CANCEL_USER_INVITE = gql`
    mutation OrganisationCancelUserInvite($id: String!, $email: String!) {
        org_cancelInviteUser(id: $id, email: $email) {
            id
            users {
                userId
                status
                email
            }
        }
    }
`;

export const ORGANISATION_CHANGE_USER_ROLE = gql`
    mutation OrganisationChangeUserRole($id: String!, $userId: String!, $roleId: String!) {
        org_changeUserRole(id: $id, userId: $userId, roleId: $roleId) {
            id
            users {
                userId
                roleId
                status
            }
        }
    }
`;

export const ORGANISATION_ACTIVATE_USER = gql`
    mutation OrganisationActivateUser($id: String!, $userId: String!) {
        org_activateUser(id: $id, userId: $userId) {
            id
            users {
                userId
                roleId
                status
            }
        }
    }
`;

export const ORGANISATION_DEACTIVATE_USER = gql`
    mutation OrganisationDeactivateUser($id: String!, $userId: String!) {
        org_deactivateUser(id: $id, userId: $userId) {
            id
            users {
                userId
                roleId
                status
            }
        }
    }
`;
export const ORGANISATION_ENABLE_USER_TWOFACTOR = gql`
    mutation OrganisationEnableUserTwofactor($id: String!, $userId: String!) {
        org_enableUserTwofactor(id: $id, userId: $userId) {
            id
            users {
                userId
                roleId
                status
            }
        }
    }
`;
export const ORGANISATION_DISABLE_USER_TWOFACTOR = gql`
    mutation OrganisationDisableUserTwofactor($id: String!, $userId: String!) {
        org_disableUserTwofactor(id: $id, userId: $userId) {
            id
            users {
                userId
                roleId
                status
            }
        }
    }
`;

export const CHECK_EMAIL_AVAILABLE = gql`
    mutation checkEmailAvailable($email: String!) {
        user_checkEmailAvailable(email: $email) {
            value
        }
    }
`;

export const CONFIRM_EMAIL = gql`
    mutation user_validate($token: String!) {
        user_validate(token: $token) {
            message
        }
    }
`;

export const REQUEST_RESETPASSWORD = gql`
    mutation user_requestPasswordReset($email: String!) {
        user_requestPasswordReset(email: $email) {
            message
        }
    }
`;

export const REQUEST_SET_PASSWORD = gql`
    mutation user_requestSetPassword($email: String!) {
        user_requestSetPassword(email: $email) {
            message
        }
    }
`;

export const RESETPASSWORD = gql`
    mutation user_resetPassword($token: String!, $password: String!, $confirmPassword: String!) {
        user_resetPassword(token: $token, password: $password, confirmPassword: $confirmPassword) {
            message
        }
    }
`;

//QUERIES

export const USER_ORGANISATIONS = gql`
    query getUserOrganisations($userId: String!) {
        user(id: $userId) {
            status
            organisations {
                status
                organisation {
                    id
                    logo
                    name
                    status
                    address {
                        line1
                        line2
                        city
                    }
                }
            }
        }
    }
`;

// This query returns organisations which are potentially related to the specified user
export const USER_POTENTIAL_ORGANISATIONS = gql`
    query getPotentialOrganisations($where: JSON) {
        organisations(where: $where) {
            id
            type
            logo
            name
            address {
                city
                line1
            }
            status
            users {
                userId
                status
                role {
                    name
                }
            }
            whitelistedEmails
            settings {
                login {
                    whitelistedAutoApprove
                }
            }
        }
    }
`;

// export const USER_REQUESTED_ORGANISATIONS = gql`
//     query getUserOrganisations($where: JSON!) {
//         organisations(where: $where) {
//             #{ AND: [{ users__some: { userId: $userId } }, { users__some: { status: "requested" } }] }
//             id
//             logo
//             name
//             address {
//                 city
//                 line1
//             }
//         }
//     }
// `;

// Update user

export const UPDATE_USER_NAMES = gql`
    mutation updateUserNames($id: String!, $firstName: String!, $lastName: String!) {
        user_updateProfile(id: $id, data: { firstName: $firstName, lastName: $lastName }) {
            id
        }
    }
`;

export const UPDATE_USER_EMAIL = gql`
    mutation updateUserEmail($id: String!, $email: String!) {
        user_updateEmail(id: $id, email: $email) {
            id
            email
            changeEmail
        }
    }
`;

export const VALIDATE_USER_EMAIL = gql`
    mutation validateUserEmail($token: String!) {
        user_validateEmail(token: $token) {
            id
            email
        }
    }
`;

export const UPDATE_USER_PASSWORD = gql`
    mutation updateUserPassword($id: String!, $password: String!, $confirmPassword: String!) {
        user_updatePassword(id: $id, password: $password, confirmPassword: $confirmPassword) {
            id
        }
    }
`;

export const UPDATE_USER_PHONE = gql`
    mutation updateUserPhone($id: String!, $phone: String!) {
        user_updateProfile(id: $id, data: { phone: $phone }) {
            id
        }
    }
`;

export const UPDATE_USER_2FA = gql`
    mutation updateUser2FA($id: String!, $enabled: Boolean!) {
        user_updateSettings(id: $id, data: { login: { twofactorEnabled: $enabled } }) {
            id
        }
    }
`;

export const UPDATE_AUTO_LOGIN = gql`
    mutation updateAutoLogin($id: String!, $enabled: Boolean!) {
        user_updateSettings(id: $id, data: { login: { autoLogin: $enabled } }) {
            id
            settings {
                login {
                    autoLogin
                    lastLoginOrganisation
                }
            }
        }
    }
`;

export const UPDATE_USER_AVATAR = gql`
    mutation updateUserAvatar($id: String!, $avatar: String!) {
        user_updateAvatar(id: $id, avatar: $avatar) {
            id
        }
    }
`;

export const RESET_2FA = gql`
    mutation reset2FA($id: String!) {
        user_resetTwofactor(id: $id) {
            id
        }
    }
`;

export const ACCESS = gql`
    mutation Access($organisationId: String!) {
        user_accessOrganisation(organisationId: $organisationId) {
            jwt
            refreshToken
            twofactorRequired
            twofactorQRcode
            ssoRedirectUrl
            user {
                id
                firstName
                lastName
                avatar
                email
                status
                organisations {
                    organisationId
                    roleId
                    status
                }
            }
        }
    }
`;

export const REQUEST_ORGANISATION_ACCESS = gql`
    mutation requestOrganisationsAccess($id: String!, $organisationId: String!) {
        user_requestOrganisationAccess(id: $id, organisationId: $organisationId) {
            id
        }
    }
`;

export const CANCEL_ORGANISATION_ACCESS = gql`
    mutation cancelOrganisationsAccess($id: String!, $organisationId: String!) {
        user_cancelOrganisationRequest(id: $id, organisationId: $organisationId) {
            id
        }
    }
`;

export const ACCEPT_ORGANISATION_ACCESS = gql`
    mutation acceptOrganisationAccess($id: String!, $userId: String!) {
        org_acceptUser(id: $id, userId: $userId) {
            id
        }
    }
`;

export const DECLINE_ORGANISATION_ACCESS = gql`
    mutation declineOrganisationAccess($id: String!, $userId: String!) {
        org_declineUser(id: $id, userId: $userId) {
            id
        }
    }
`;

export const ADD_WHITELISTED_EMAIL = gql`
    mutation addWhitelistedEmail($id: String!, $email: String!) {
        org_addWhitelistedEmail(id: $id, email: $email) {
            id
            whitelistedEmails
        }
    }
`;

export const REMOVE_WHITELISTED_EMAIL = gql`
    mutation removeWhitelistedEmail($id: String!, $email: String!) {
        org_removeWhitelistedEmail(id: $id, email: $email) {
            id
            whitelistedEmails
        }
    }
`;

export const ORG_UPDATE_SSO = gql`
    mutation OrgUpdateSso(
        $organisationId: String!
        $slug: String
        $customDomain: String
        $enabled: Boolean
        $ssoRequired: Boolean
        $loginUrl: String
        $logoutUrl: String
        $defaultRole: String
        $idpCertificate: String
    ) {
        org_updateDetails(
            id: $organisationId
            settings: {
                sso: {
                    slug: $slug
                    customDomain: $customDomain
                    enabled: $enabled
                    ssoRequired: $ssoRequired
                    loginUrl: $loginUrl
                    logoutUrl: $logoutUrl
                    defaultRole: $defaultRole
                    idpCertificate: $idpCertificate
                }
            }
        ) {
            id
            name
            settings {
                sso {
                    slug
                    customDomain
                    enabled
                    ssoRequired
                    loginUrl
                    logoutUrl
                    defaultRole
                    metadataUrl
                    callbackUrl
                    publicKey
                }
            }
        }
    }
`;

export const ORG_UPDATE_LOGIN_SETTINGS = gql`
    mutation OrgUpdateSso(
        $organisationId: String!
        $twofactor: Boolean
        $twofactorRoleIds: [String]
        $whitelistedAutoApprove: Boolean
        $whitelistedDefaultRoleId: String
    ) {
        org_updateDetails(
            id: $organisationId
            settings: {
                login: {
                    twofactor: $twofactor
                    twofactorRoleIds: $twofactorRoleIds
                    whitelistedAutoApprove: $whitelistedAutoApprove
                    whitelistedDefaultRoleId: $whitelistedDefaultRoleId
                }
            }
        ) {
            id
            name
            settings {
                login {
                    twofactor
                    twofactorRoleIds
                    twofactorRoles {
                        name
                    }
                    whitelistedAutoApprove
                    whitelistedDefaultRoleId
                    whitelistedDefaultRole {
                        name
                    }
                }
            }
        }
    }
`;

export const ORGANISATION_CREATE_API_KEY = gql`
    mutation org_addApiKey($organisationId: String!, $name: String!) {
        org_addApiKey(organisationId: $organisationId, name: $name) {
            id
            name
            integration {
                keys {
                    id
                    name
                    key
                    creationDate
                }
            }
        }
    }
`;

export const ORGANISATION_UPDATE_API_KEY = gql`
    mutation org_updateApiKey($organisationId: String!, $id: String!, $name: String!) {
        org_updateApiKey(organisationId: $organisationId, id: $id, name: $name) {
            id
            name
            integration {
                keys {
                    id
                    name
                    key
                    creationDate
                }
            }
        }
    }
`;

export const ORGANISATION_REMOVE_API_KEY = gql`
    mutation org_removeApiKey($organisationId: String!, $id: String!) {
        org_removeApiKey(organisationId: $organisationId, id: $id) {
            id
            name
            integration {
                keys {
                    id
                    name
                    key
                    creationDate
                }
            }
        }
    }
`;

export const REGISTER_ORGANISATION_BY_KVK = gql`
    mutation org_registerByKvk($name: String!, $address: JSON, $legal: JSON, $email: String!, $options: JSON) {
        org_registerByKvk(organisation: { name: $name, address: $address, legal: $legal }, email: $email, options: $options) {
            id
        }
    }
`;

export const UPDATE_SETTINGS = gql`
    mutation ChangeSettings($id: String!, $settings: JSON) {
        project_changeSettings(id: $id, settings: $settings) {
            id
            clientStatus
            contractorStatus
        }
    }
`;

// DataDynamiQ

export const CREATE_REPORT = gql`
    mutation CreateReport($name: String, $organisationId: String!, $users: [JSON], $configuration: JSON!) {
        report_create(name: $name, organisationId: $organisationId, users: $users, configuration: $configuration) {
            id
            name
            tableName
            organisationId
            users {
                user {
                    id
                    firstName
                    lastName
                    avatar
                }
                permissions
            }
            configuration
        }
    }
`;

export const DELETE_REPORT = gql`
    mutation DeleteReport($id: String!) {
        report_delete(id: $id)
    }
`;

export const REPORT_ADD_USER = gql`
    mutation ReportAddUser($id: String!, $userId: String!, $permissions: [String]!) {
        report_addUser(id: $id, userId: $userId, permissions: $permissions) {
            users {
                user {
                    id
                    firstName
                    lastName
                    avatar
                    organisations {
                        organisationId
                        roleId
                    }
                }
                permissions
            }
        }
    }
`;

export const REPORT_REMOVE_USER = gql`
    mutation ReportRemoveUser($id: String!, $userId: String!) {
        report_removeUser(id: $id, userId: $userId) {
            users {
                user {
                    id
                    firstName
                    lastName
                    avatar
                    organisations {
                        organisationId
                        roleId
                    }
                }
                permissions
            }
        }
    }
`;

export const REPORT_UPDATE_USER = gql`
    mutation ReportUpdateUser($id: String!, $userId: String!, $permissions: [String]!) {
        report_changeUser(id: $id, userId: $userId, permissions: $permissions) {
            users {
                user {
                    id
                    firstName
                    lastName
                    avatar
                    organisations {
                        organisationId
                        roleId
                    }
                }
                permissions
            }
        }
    }
`;

export const REPORT_ADD_ROLE = gql`
    mutation ReportAddRole($id: String!, $role: String!, $permissions: [String]!) {
        report_addRole(id: $id, role: $role, permissions: $permissions) {
            roles {
                role
                permissions
            }
        }
    }
`;

export const REPORT_UPDATE_ROLE = gql`
    mutation ReportUpdateRole($id: String!, $role: String!, $permissions: [String]!) {
        report_changeRole(id: $id, role: $role, permissions: $permissions) {
            roles {
                role
                permissions
            }
        }
    }
`;

export const REPORT_REMOVE_ROLE = gql`
    mutation ReportRemoveRole($id: String!, $role: String!) {
        report_removeRole(id: $id, role: $role) {
            roles {
                role
                permissions
            }
        }
    }
`;

export const REPORT_UPDATE_NAME = gql`
    mutation ReportUpdateName($id: String!, $name: String!) {
        report_updateName(id: $id, name: $name) {
            name
        }
    }
`;

export const REPORT_UPDATE_CONFIGURATION = gql`
    mutation ReportUpdateConfiguration($id: String!, $configuration: JSON!) {
        report_changeConfiguration(id: $id, configuration: $configuration) {
            configuration
        }
    }
`;

export const REPORT_REBUILD_RESULTSET = gql`
    mutation ReportRebuildResultSet($id: String!) {
        report_rebuildResultset(id: $id)
    }
`;

export const ADD_REFERENCELETTER_DOCUMENT = gql`
    mutation AddReferenceLetterDocument(
        $id: String!
        $documentId: String!
        $documentPath: String!
        $name: String!
    ) {
        referenceLetter_addDocument(
            id: $id
            documentId: $documentId
            documentPath: $documentPath
            name: $name
        ) {
            documents
        }
    }
`;

export const REMOVE_REFERENCELETTER_DOCUMENT = gql`
    mutation RemoveReferenceLetterDocument(
        $id: String!
        $documentId: String!
    ) {
        referenceLetter_removeDocument(
            id: $id
            documentId: $documentId
        ) {
            documents
        }
    }
`;

export const GET_DOCUMENT_PRESIGNED_URL = gql`
    query GetDocumentPredesignedUrl($documentId: String!, $projectJwt: String!) {
        documents_getPresignedUrl(documentId: $documentId, projectJwt: $projectJwt)
    }
`;

export const REFERENCE_LETTER_ORDER_CHANGED = gql`
    mutation ChangeOrder($id: String!, $order: JSON!) {
        referenceLetter_orderChanged(id: $id, order: $order) {
            order
        }
    }
`;

export const REFERENCE_LETTER_CONFIGURATION_UPDATED = gql`
    mutation ConfigurationUpdated(
        $referenceLetterId: String!
        $showAllProjectReferences: Boolean
        $exportAsLetter: Boolean
        $styleId: String
        $addressReceiver: AddressReceiverInput
        $introText: String
        $closingText: String
        $step: String
        $hiddenComponentIds: [String]
    ) {
        referenceLetter_configurationUpdated(
            referenceLetterId: $referenceLetterId
            showAllProjectReferences: $showAllProjectReferences
            exportAsLetter: $exportAsLetter
            styleId: $styleId
            addressReceiver: $addressReceiver
            introText: $introText
            closingText: $closingText
            step: $step
            hiddenComponentIds: $hiddenComponentIds
        ) {
            id
        }
    }
`;

export const CREATE_CONFIGURATION = gql`
    mutation CreateConfiguration($configuration: ConfigurationInput!) {
        org_configurationCreated(configuration: $configuration) {
            id
        }
    }
`;

export const UPDATE_CONFIGURATION = gql`
    mutation UpdateConfiguration($id: String!, $configuration: ConfigurationInput!) {
        org_configurationUpdated(id: $id, configuration: $configuration) {
            id
        }
    }
`;

export const DELETE_CONFIGURATION = gql`
    mutation DeleteConfiguration($id: String!) {
        org_configurationDeleted(id: $id)
    }
`;

export const CREATE_INDICATOR_GROUP = gql`
    mutation CreateIndicatorGroup($formId: String!, $name: String!, $labelId: String!, $componentIds: [String]!) {
        form_groupAdded(formId: $formId, name: $name, labelId: $labelId, componentIds: $componentIds) {
            id
            groups
            components {
                id
                disabled
                display
                indicator {
                    id
                    name
                    unit
                    type
                    required
                    masterId
                    answerType
                    displayType
                    description
                    answerValues {
                        value
                        label
                    }
                    context {
                        id
                        name
                        description
                        default
                    }
                    protectionLevel
                }
            }
            responses {
                id
                values {
                    id
                    indicatorId
                    componentId
                    masterIndicatorId
                    value
                }
            }
        }
    }
`;

export const DELETE_INDICATOR_GROUP = gql`
    mutation DeleteIndicatorGroup($formId: String!, $groupId: String!) {
        form_groupRemoved(formId: $formId, groupId: $groupId) {
            id
            groups
            components {
                id
                disabled
                display
                indicator {
                    id
                    name
                    unit
                    type
                    masterId
                    answerType
                    displayType
                    description
                    answerValues {
                        value
                        label
                    }
                    context {
                        id
                        name
                        description
                        default
                    }
                    protectionLevel
                }
            }
        }
    }
`;

export const EDIT_INDICATOR_GROUP = gql`
    mutation EditIndicatorGroup($formId: String!, $groupId: String!, $name: String!, $componentIds: [String]!) {
        form_groupEdited(formId: $formId, groupId: $groupId, name: $name, componentIds: $componentIds) {
            id
            groups
        }
    }
`;

export const GET_REPORT_DOWNLOAD = gql`
    mutation getCrowFormsDownload($formIds: [String]!) {
        report_crowForms(formIds: $formIds) {
            fileName
        }
    }
`;

export const ADD_ALL_INDICATORS = gql`
    mutation addAllIndicators($id: String!, $indicatorIds: [String]!) {
        project_addAllIndicators(id: $id, indicatorIds: $indicatorIds) {
            id
            references {
                id
                organisationId
                name
                components {
                    id
                    display
                    disabled
                    indicator {
                        id
                        name
                        type
                        required
                        masterId
                        answerType
                        displayType
                        unit
                        answerValues {
                            value
                            label
                        }
                        context {
                            id
                            name
                            default
                            order
                        }
                        organisationId
                        protectionLevel
                    }
                }
            }
        }
    }
`;

export const REMOVE_ALL_INDICATORS = gql`
    mutation removeAllIndicators($id: String!, $indicatorIds: [String]!) {
        project_removeAllIndicators(id: $id, indicatorIds: $indicatorIds) {
            id
        }
    }
`;

export const PROJECT_SET_PRIVACY = gql`
    mutation projectSetPrivacy($id: String!, $isPrivate: Boolean!) {
        project_setPrivate(id: $id, isPrivate: $isPrivate) {
            id
        }
    }
`;

export const UPDATE_LABEL = gql`
    mutation updateLabel($id: String!, $name: String, $description: String, $status: String) {
        label_update(id: $id, name: $name, description: $description, status: $status) {
            id
        }
    }
`;

export const DELETE_LABEL = gql`
    mutation deleteLabel($id: String!) {
        label_delete(id: $id) {
            description
        }
    }
`;

export const REMOVE_LABEL = gql`
    mutation removeLabel($id: String!) {
        label_remove(id: $id)
    }
`;

export const CREATE_LABEL = gql`
    mutation createLabel($organisationId: String!, $type: String!, $name: String!, $description: String, $status: String!, $formtemplateId: String, $projecttemplateId: String) {
        label_create(organisationId: $organisationId, type: $type, name: $name, description: $description, status: $status, formtemplateId: $formtemplateId, projecttemplateId: $projecttemplateId) {
            id
            name
            description
            masterId
            status
            order
        }
    }
`;

export const UPDATE_LABELS_ORDER = gql`
    mutation updateLabelsOrder($organisationId: String!, $oldLabelIds: [String]!, $newLabelIds: [String]!) {
        labels_order_updated(organisationId: $organisationId, oldLabelIds: $oldLabelIds, newLabelIds: $newLabelIds) 
    }
`;

export const UPDATE_INDICATOR_ORDER = gql`
    mutation updateIndicatorOrder($labels: [JSON]) {
        indicators_order_updated(labels: $labels)
    }
`;

export const CREATE_INDICATOR = gql`
    mutation createIndicator($indicator: IndicatorInput!, $organisationId: String!) {
        indicator_create(indicator: $indicator, organisationId: $organisationId) {
            id
            name
            description
            isMaster
            masterId
            answerType
            displayType
            labelIds
            type
            labels {
                id
                name
            }
            answerValues {
                value
                label
            }
            formtemplateIds
            formtemplates {
                id
                name
            }
            projecttemplateIds
            projecttemplates {
                id
                name
            }
            status
        }
    }
`;

export const UPDATE_INDICATOR = gql`
    mutation updateIndicator(
        $id: String!, 
        $name: String, 
        $description: String, 
        $answerType: String, 
        $displayType: String, 
        $unit: String,
        $formtemplateIds: [String],
        $projecttemplateIds: [String],
        $status: String,
        $required: Boolean,
        $mandatory: Boolean,
        $canFillComment: Boolean,
        $canFillNotApplicable: Boolean,
        $answerValues: [AnswerValueInput],
        $showScore: Boolean) {
        indicator_update(
            id: $id, 
            name: $name, 
            description: $description, 
            answerType: $answerType, 
            displayType: $displayType, 
            unit: $unit,
            formtemplateIds: $formtemplateIds,
            projecttemplateIds: $projecttemplateIds,
            status: $status,
            required: $required,
            mandatory: $mandatory,
            canFillComment: $canFillComment,
            canFillNotApplicable: $canFillNotApplicable,
            answerValues: $answerValues,
            showScore: $showScore) {
            id
        }
    }
`;

export const REMOVE_INDICATOR = gql`
    mutation removeIndicator($id: String!) {
        indicator_remove(id: $id) {
            id
        }
    }
`;

export const UPDATE_INDICATOR_FORMTEMPLATES = gql`
    mutation updateIndicatorFormtemplates($id: String!, $formtemplateIds: [String]!) {
        indicator_update_formtemplates(id: $id, formtemplateIds: $formtemplateIds)
    }
`;

export const REMOVE_FORMTEMPLATE = gql`
    mutation removeFormtemplate($id: String!) {
        formtemplate_remove(id: $id) {
            id
        }
    }
`;

export const UPDATE_FORMTEMPLATE = gql`
    mutation updateFormtemplate($id: String!, $name: String, $description: String, $status: String) {
        formtemplate_update(id: $id, name: $name, description: $description, status: $status) {
            id
        }
    }
`;
export const CREATE_FORMTEMPLATE = gql`
    mutation createFormtemplate($type: String!, $answerType: String!, $name: String!, $description: String, $organisationId: String!, $status: String!) {
        formtemplate_create(type: $type, name: $name, answerType: $answerType, description: $description, organisationId: $organisationId, status: $status) {
            id
        }
    }
`;

export const CREATE_PROJECTTEMPLATE = gql`
    mutation createProjectTemplate($name: String!, $organisationId: String!) {
        projecttemplate_create(name: $name, organisationId: $organisationId) {
            id
        }
    }
`;

export const REMOVE_PROJECTTEMPLATE = gql`
    mutation removeProjectTemplate($id: String!) {
        projecttemplate_remove(id: $id) {
            id
        }
    }
`;

export const UPDATE_PROJECTTEMPLATE = gql`
    mutation updateProjectTemplate($id: String!, $name: String, $description: String, $status: String) {
        projecttemplate_update(id: $id, name: $name, description: $description, status: $status) {
            id
        }
    }
`;

export const ADD_LABEL_TO_FORMTEMPLATE = gql`
    mutation addLabelToFormtemplate($id: String!, $labelId: String!, $indicatorIds: [String]) {
        formtemplate_addLabel(id: $id, labelId: $labelId, indicatorIds: $indicatorIds) {
            id
            labels {
                id
                name
                description
                masterId
                status
                order
            }
        }
    }
`;

export const ADD_LABEL_TO_PROJECTTEMPLATE = gql`
    mutation addLabelToProjecttemplate($id: String!, $labelId: String!, $indicatorIds: [String]) {
        projecttemplate_addLabel(id: $id, labelId: $labelId, indicatorIds: $indicatorIds) {
            id
            labels {
                id
                name
                description
                masterId
                status
                order
            }
        }
    }
`;

export const FORMTEMPLATE_REMOVE_LABEL = gql`
    mutation removeLabelFromFormtemplate($id: String!, $labelId: String!) {
        formtemplate_removeLabel(id: $id, labelId: $labelId) {
            id
        }
    }
`;

export const PROJECTTEMPLATE_REMOVE_LABEL = gql`
    mutation removeLabelFromProjecttemplate($id: String!, $labelId: String!) {
        projecttemplate_removeLabel(id: $id, labelId: $labelId) {
            id
        }
    }
`;

export const FORMTEMPLATE_UPDATE_COMPONENT_ORDER = gql`
    mutation updateComponentOrder($id: String!, $componentIds: [String]!) {
        formtemplate_updateComponentOrder(id: $id, componentIds: $componentIds) {
            id
            components {
                id
                indicatorId
            }
        }
    }
`;

export const FORMTEMPLATE_UPDATE_LABEL_ORDER = gql`
    mutation updateLabelOrder($id: String!, $labelIds: [String]!) {
        formtemplate_updateLabelOrder(id: $id, labelIds: $labelIds) {
            id
            labels {
                id
            }
        }
    }
`;

export const DELETE_PROJECT_DOCUMENT = gql`
    mutation DeleteProjectDocument($documentId: String!, $projectJwt: String!) {
        documents_delete(documentId: $documentId, projectJwt: $projectJwt)
    }
`;

export const ORG_UPDATE_PROJECT_CONFIGURATION = gql`
    mutation OrgUpdateProjectConfiguration($id: String!, $configuration: projectConfigurationInput!) {
        org_updateProjectConfiguration(id: $id, configuration: $configuration) {
            settings {
                projectConfigurations
            }
        }
    }
`