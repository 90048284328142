<template>
    <div class="page-padding">
        <div v-if="!hasForms">
            <q-zerostate
                class="zerostate"
                img="/statics/img/survey_gradient.svg"
                title="Formulieren"
                :description="zerostateDescription"
            >
                <q-button v-can="'Project__create_crow'">Maak nieuw project aan</q-button>
            </q-zerostate>
        </div>
        <div v-else>
            <div class="flex space-between mb-l">
                <h2>Formulieren</h2>
                <jump-transition>
                    <q-button   
                            v-if="!freemiumOrganisation && formsMinimal.length"                 
                            class="export-button" variation="light"
                            icon="download"
                            @click="downloadExport"
                            >{{ exportButtonText }}
                    </q-button>
                </jump-transition>                   
            </div>
            <q-table-with-pagination
                :data="paginatedForms"
                :columns="getColumns"
                clickable
                :tableLoading="loadingForms"
                :maxRows="paginatedTableLength"
                :dataLength="formsMinimal.length"
                :isSearching="Boolean(selectedFiltersLength)"
                ref="formsTable"
                :searchingInFilter="searchingInFilter"
                @filterUpdated="handleFilterUpdated"
                @filterSearch="_filterSearchChanged"
                @pageSelection="handleTablePagination"
                @filterInitialized="handleFiltersInitialized"
                @tableInitialized="handleUpdateTable"
            >
                <template v-slot:row="{ row, column }">
                    <div v-if="column === 'name'" class="name-with-template">
                        <p class="ellipsis">{{ row.name }}</p>
                        <p class="ellipsis template">{{ row.templateName }}</p>
                    </div>
                    <span v-else-if="column === 'responseIds'">
                        <span v-if="!['client','contractor'].includes(row.type)">{{ row.responseIds.length }}</span>
                    </span>
                    <span v-else-if="column === 'scoreData_average'">
                        <span>{{ parseScore(row.average) }}</span>
                    </span>
                    <span v-else-if="column === 'statusLabel'">
                        <q-tag size="medium" :variation="getStatusVariation(row.defaultStatusLabel)">{{
                            row[column]
                        }}</q-tag>
                    </span>
                    <span v-else-if="column === 'creationDate'">
                        {{ row.creationDateFormatted }}
                    </span>
                    <span v-else :class="column == 'projectName' ? 'boldText' : ''" style="white-space:nowrap">{{
                        row[column]
                    }}</span>
                </template>
            </q-table-with-pagination>            
        </div>
    </div>
</template>

<script>

import gql from 'graphql-tag'

import { GET_FORMS_WITHOUT_REFERENCES, GET_FORMS_MINIMAL, ORGANISATIONS_MINIMAL, GET_PROJECTS_MINIMAL } from '../../graphql/queries';
import { getDate, extractError, getCalculatedRows, handleExportCsv, freemiumOrganisation } from '../../assets/js/utils';

export default {
    name: 'Forms',
    data() {
        return {
            loadingForms: true,
            loadingProjectFilterOptions: true,
            loadingTemplateFilterOptions: true,
            loadingOrganisationFilterOptions: true,
            loadingStatusFilterOptions: true,
            hasForms: true,
            zerostateDescription:
                'Je hebt nog geen meting aangemaakt of je bent nog niet uitgenodigd om aan een meting deel te nemen.',
            iconStyle: {
                marginLeft: '-10px',
                marginTop: '-2px'
            },
            forms: [],
            allFormTemplates: [],
            templateFilterOptions: [],
            projectsFilterOptions: [],
            responseMaxLength: null,
            whereQuery: {},
            projectsWhereQuery: {},
            sorting: ['creationDate__DESC'],
            paginatedTableLength: 10,
            paginatedForms: [],
            formsMinimal: [],
            allMinimalForms: [],
            selectedFiltersLength: 0,
            searchingInFilter: false,
            allContractorsInForms: [],
            allClientsInForms: [],
            organisationFilterSearch: '',
            projectFilterSearch: '',
            templateFilterSearch: '',
            statusFilterOptions: [],
            initializedTableLength: false,
            activeTableIndex: 0,
            exportingForms: false,
            exportedForms: []
        };
    },
    methods: {
        get() {
            const first = this.paginatedTableLength;
            const skip = this.paginatedTableLength * this.activeTableIndex;

            this.loadingForms = true;

            this.$apollo
                .query({
                    query: GET_FORMS_WITHOUT_REFERENCES,
                    variables: {
                        where: this.whereQuery,
                        projectsWhere: this.projectsWhereQuery,
                        sort: this.sorting,
                        first,
                        skip
                    },
                    fetchPolicy: 'cache'
                })
                .then(response => {
                    const forms = this.transformColumns(response.data.formsWithoutReferences);
                    
                    this.paginatedForms = forms;
                    if(!this.hasForms) this.hasForms = forms.length > 0;
                    this.loadingForms = false;
                })
                .catch(err => {
                    this.loadingForms = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        transformColumns(forms) {
            return forms.map(form => {
                const contractor = form.project.contractor ? form.project.contractor.name : '';
                const client = form.project.client ? form.project.client.name : '';
                const templateName = form.template?.name.includes('form_template') ? this.$t(`formtemplates.${form.template.name}`) : form.template?.name;
                const to = `/${['client','contractor'].includes(form.type) ? 'forms' : 'enquetes'}/${form.id}`;
                const responseIds = form.responseIds || [];

                const newForm = {
                    id: form.id,
                    name: form.name,
                    type: form.type,
                    templateName,
                    projectName: this.getProjectName(form.project),
                    contractor,
                    client,
                    creationDate: form.creationDate,
                    creationDateFormatted: this.getDate(form.creationDate),
                    status: form.status,
                    formType: form.formType,
                    to,
                    statusLabel: this.$t(`formStatus.${form.statusLabel}`),                    
                    defaultStatusLabel: form.statusLabel,
                    responses: responseIds.length,
                    responseIds,
                    average: this.parseScore(form.scoreData?.average),
                };

                return newForm;
            })
        },
        getDate(timestamp) {
            return getDate(timestamp);
        },
        getStatusVariation(statusLabel) {
            switch(statusLabel) {
                case'concept':
                case'start_fill':
                    return 'default'
                case'fill':
                case'completed':
                case'pending_crow_admin':
                case'crow_admin_disagreed':
                case'agreement_companion':
                case'pending_revision':
                case'pending_agreement':
                case'sent':
                    return 'warning'
                case'fill_revision':
                    return 'danger'
                case'finished':
                    return 'success'
                default:
                    return 'default'
            }
        },
        setFilters(filter) {
            const organisationType = this.$store.getters.getOrganisationType;

            if(Object.keys(filter).length) {
                const filters = Object.keys(filter).filter(filterName => {
                    if(typeof filter[filterName].filter === 'array' && filter[filterName].filter.length > 0) return true
                    if(typeof filter[filterName].filter === 'object' && Object.keys(filter[filterName].filter).length > 0) return true
                });
                this.selectedFiltersLength = filters.length;
                try {
                    this.$refs.formsTable.resetCounter();
                } catch(error) {}
            }

            const activeFilterKeys = Object.keys(filter)
                .filter(field => {
                    switch (field) {
                        case 'name':
                        case 'projectName':
                        case 'statusLabel':
                            return filter[field].filter.length > 0;
                        case 'responseIds':
                        case 'scoreData_average':
                            return filter[field].filter.length === 2
                        default:
                            return true;
                    }
                })

            if(activeFilterKeys.length > 0) this.whereQuery = {
                AND: [...activeFilterKeys
                    .map(field => {
                        switch (field) {
                            case 'name':
                                return organisationType === 'client' ? {
                                    name__in: this.getTemplateIds(filter[field].filter)
                                } : {
                                    templateId__in: this.getTemplateIds(filter[field].filter)
                                };
                            case 'projectName':
                                return {
                                    projectId__in: filter[field].filter
                                };
                            case 'creationDate':
                                return {
                                    creationDate__gte: filter[field].filter.from,
                                    creationDate__lte: filter[field].filter.to
                                };
                            case 'responseIds':
                                return {
                                    AND: [
                                        { responseIds__size_gte: filter[field].filter[0] },
                                        { responseIds__size_lte: filter[field].filter[1] }
                                    ]
                                }
                            case 'scoreData_average':
                                return {
                                    AND: [
                                        { scoreData_average__gte: filter[field].filter[0] },
                                        { scoreData_average__lte: filter[field].filter[1] }
                                    ]
                                }
                            case 'statusLabel':
                                return filter[field].filter && filter[field].filter.length > 0 ? 
                                {
                                    statusLabel__in: filter[field].filter
                                } : {}
                            case 'contractor': //contractor filter field must be handled in projectsWhere
                                return {}
                            default:
                                return {}
                        }
                    }), { OR: [{statusLabel__nin:['concept']}, { formType: 'survey' }] } ]
            } 
            else this.whereQuery = { OR: [{statusLabel__nin:['concept']}, { formType: 'survey' }] }

            if (filter.contractor)
                this.projectsWhereQuery = {
                    AND: [
                        ...Object.keys(filter)
                            .filter(field => field === 'contractor')
                            .map(field => {
                                switch (field) {
                                    case 'contractor':
                                        return filter[field].filter.length > 0 ?
                                        {
                                            contractorId__in: filter[field].filter
                                        } : {}
                                    default:
                                        break;
                                }
                            })
                    ]
                }
            if (filter.client)
                this.projectsWhereQuery = {
                    AND: [
                        ...Object.keys(filter)
                            .filter(field => field === 'client')
                            .map(field => {
                                switch (field) {
                                    case 'client':
                                        return filter[field].filter.length > 0 ?
                                        {
                                            clientId__in: filter[field].filter
                                        } : {}
                                    default:
                                        break;
                                }
                            })
                    ]
                }

            let sorting = [];

            this.sorting = Object.keys(filter)
                .filter(field => filter[field].latest)
                .map(field => {
                    const sort = this.getSortingCondition(field, filter[field].sorting);
                    sorting.push(sort);
                });

            if(sorting.length === 0) {
                const defaultSortColumn = this.getColumns.find(column => column.defaultSort);
                if(defaultSortColumn) {
                    const sort = this.getSortingCondition(defaultSortColumn.field, defaultSortColumn.defaultSort);
                    sorting.push(sort);
                }
            }

            this.sorting = sorting;
        },
        getSortingCondition(field, sorting) {
            if(field === 'responseIds') field += '__count';
            return `${field}__${sorting}`
        },
        handleUpdateTable() {
            if(!this.initializedTableLength) return
            this.get();
            this.getFormsMinimal();
        },
        handleFilterUpdated(filter) {
            this.setFilters(filter);
            this.handleUpdateTable();
        },
        handleFiltersInitialized(filter) {
            this.setFilters(filter);
            this.getFormsMinimal();
        },
        getTemplateIds(templateIds) {
            const organisationType = this.$store.getters.getOrganisationType;
            let selectedFormTemplates = [];
            if(organisationType === 'client') {
                selectedFormTemplates = this.allFormTemplates.filter(form => templateIds.includes(form.name))
                return selectedFormTemplates.map(form => form.name)
            } else {
                if(this.templateFilterOptions.length === 0) return templateIds
                const options = this.templateFilterOptions.filter(option => templateIds.includes(option.value));
                const allTemplateIds = options.reduce((accumulator, option) => accumulator.concat(option.templateIds), []);
                return [ ...new Set(allTemplateIds) ]
            }
        },
        _filterSearchChanged: _.debounce(function(data) {
            return this.filterSearchChanged(data);
        }, 500),
        filterSearchChanged({ column, filterData }) {
            const { search = '' } = filterData;
            if(search && search.length > 0) this.searchingInFilter = true;
            else this.searchingInFilter = false;

            switch (column) {
                case 'projectName':
                    this.projectFilterSearch = search;
                    break;
                case 'name':
                    this.templateFilterSearch = search;
                    break;
                case 'contractor':
                    this.organisationFilterSearch = search;
                    break;
                case 'client':
                    this.organisationFilterSearch = search;
                    break;
            }
        },
        setProjectFilterOptions(search) {
            this.loadingProjectFilterOptions = true;
            const projectIds = this.allMinimalForms.map(form => form.projectId);
            this.$apollo
                .query({
                    query: GET_PROJECTS_MINIMAL,
                    variables: {
                        where: {
                            id__in: projectIds
                        },
                        caseSensitive: false
                    },
                    fetchPolicy: 'cache-first'
                })
                .then(result => {
                    this.loadingProjectFilterOptions = false;
                    this.projectsFilterOptions = result.data.projects.map(project => {
                        return {
                            value: project.id,
                            label: this.getProjectName(project),
                            selected: false
                        };
                    });
                })
                .catch(error => {
                    this.loadingProjectFilterOptions = false;
                    console.log(error);
                });
        },
        getAllOrganisationsExistingInForms() {
            this.loadingOrganisationFilterOptions = true;
            this.$apollo
                .query({
                    query: gql`
                        query GetOrganisationsInForms {
                            formsWithoutReferences {
                                id
                                type
                                status
                                project {
                                    id
                                    client {
                                        id
                                        name
                                    }
                                    contractor {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    fetchPolicy: 'cache-first'
                })
                .then(result => {
                    this.allContractorsInForms = result.data.formsWithoutReferences
                        .filter(form => !(['client','contractor'].includes(form.type) && form.status === 'concept'))
                        .map(form => form.project.contractor)
                        .filter(contractor => contractor)
                        .filter((contractor, index, array) => array.findIndex(item => item.id === contractor.id) === index)
                        .map(contractor => {
                            return {
                                value: contractor.id,
                                label: contractor.name
                            }
                        })
                    this.allClientsInForms = result.data.formsWithoutReferences
                        .filter(form => !(['client','contractor'].includes(form.type) && form.status === 'concept'))
                        .map(form => form.project.client)
                        .filter(client => client)
                        .filter((client, index, array) => array.findIndex(item => item.id === client.id) === index)
                        .map(client => {
                            return {
                                value: client.id,
                                label: client.name
                            }
                        })
                    this.loadingOrganisationFilterOptions = false;
                })
                .catch(error => {
                    this.loadingOrganisationFilterOptions = false;
                });
        },
        checkHasForms() {
            this.$apollo
                .query({
                    query: gql`
                        query CheckHasForms {
                            formsWithoutReferences(first: 1) {
                                id
                            }
                        }
                    `,
                    first: 1,
                    fetchPolicy: 'network-only'
                })
                .then(result => {
                    this.hasForms = result.data.formsWithoutReferences.length > 0;
                })
                .catch(error => {});
        },
        getFormsMinimal(initialize = false) {
            this.$apollo.query({
                query: GET_FORMS_MINIMAL,
                variables: {
                    where: initialize ? {} : this.whereQuery,
                    projectsWhere: this.projectsWhereQuery
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                if(initialize) {
                    this.allMinimalForms = result.data.formsWithoutReferences;
                    if(this.formsMinimal.length === 0) this.formsMinimal = result.data.formsWithoutReferences;

                    this.statusFilterOptions = this.getStatusFilterOptions();
                    this.setTemplateFilterOptions()
                    this.setProjectFilterOptions()
                    
                    const responses = this.allMinimalForms.map(form => form.responseIds?.length || 0);
                    this.responseMaxLength = Math.max(...responses);
                }
                else this.formsMinimal = result.data.formsWithoutReferences;
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de formulieren' })
                console.log(error)
            })
        },
        handleTablePagination(selectedPageIndex) {
            this.activeTableIndex = selectedPageIndex - 1;
            if(!this.initializedTableLength) return

            this.get();
        },
        setTemplateFilterOptions() {
            this.loadingTemplateFilterOptions = true;
            const organisationType = this.$store.getters.getOrganisationType;
            if(organisationType === 'client') {
                const uniqueFormNames = [];
                this.allFormTemplates = this.allMinimalForms;
                this.templateFilterOptions = this.allMinimalForms
                    .filter(form => {
                        if(uniqueFormNames.includes(form.name)) return false
                        uniqueFormNames.push(form.name)
                        return true
                    })
                    .map(form => {
                        return {
                            label: form.name,
                            value: form.name
                        }
                    })
                this.loadingTemplateFilterOptions = false;
                return
            }

            const templateIds = this.allMinimalForms.map(form => form.templateId);
            this.$apollo
                .query({
                    query: GET_FORMS_WITHOUT_REFERENCES,
                    variables: {
                        where: {
                            templateId__in: templateIds
                        },
                        caseSensitive: false
                    },
                    fetchPolicy: 'cache-first'
                })
                .then(result => {
                    const templateOptions = [];
                    this.allFormTemplates = result.data.formsWithoutReferences;
                    result.data.formsWithoutReferences.forEach(form => {
                        const templateName = form.template.name.includes('form_template') ? this.$t(`formtemplates.${form.template.name}`) : form.template.name;
                        if(!form.template) return

                        const optionIndex = templateOptions.findIndex(option => option.label === templateName)

                        if(optionIndex !== -1) {
                            templateOptions[optionIndex].templateIds.push(form.template.id);
                        } else {
                            templateOptions.push({
                                value: form.template.id,
                                label: templateName,
                                selected: false,
                                templateIds: [form.template.id]
                            });
                        }
                    });
                    this.templateFilterOptions = templateOptions;
                    this.loadingTemplateFilterOptions = false;
                })
                .catch(error => {
                    this.loadingTemplateFilterOptions = false;
                    console.log(error);
                });
        },
        getStatusFilterOptions() {
            this.loadingStatusFilterOptions = true;
            let statusOptions = [];
            this.allMinimalForms.forEach(form => {
                if(statusOptions.find(option => option.value === form.statusLabel)) return
                statusOptions.push({
                    value: form.statusLabel,
                    label: this.$t(`formStatus.${form.statusLabel}`),
                    selected: false
                })
            })
            this.loadingStatusFilterOptions = false;

            return statusOptions
        },
        getProjectName(project) {
            const organisationType = this.$store.getters.getOrganisationType;
            const key = `${organisationType}ProjectName`;
            const projectName = project[key];
            return projectName || project.name
        },
        initializeFilterData() {
            this.checkHasForms();
            this.getFormsMinimal(true);
            this.getAllOrganisationsExistingInForms();
        },
        parseScore(score) {
            if(isNaN(score) || score === null) return '-'
            return Math.round(score * 10) / 10
        },
        async getFormBatch(first, skip) {
            for (let i = 0; i <= 3; i++) {
                await new Promise(r => setTimeout(r, 400*i*i))
                try {
                    const formBatch = await this.$apollo.query({
                        query: GET_FORMS_WITHOUT_REFERENCES,
                        variables: {
                            where: this.whereQuery,
                            sort: this.sorting,
                            first,
                            skip
                        },
                        fetchPolicy: 'no-cache'
                    });
                    return Array.isArray(formBatch.data.formsWithoutReferences) ? formBatch.data.formsWithoutReferences : null;
                } catch {}
            }
            return null;                
        },
        async downloadExport() {
            this.exportingForms = true;
            this.exportedForms = [];
            const first = 10;
            const formCount = this.formsMinimal.length;
            for (let skip = 0; skip < formCount; skip += first) {
                if (!this.exportingForms) return;
                const formBatch = await this.getFormBatch(first, skip);
                if (formBatch === null) {
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de formulieren' })
                    this.exportingForms = false;
                    return;
                }
                this.exportedForms = [ ...this.exportedForms, ...this.transformColumns(formBatch)];                
            }
            const columns = this.getColumns.map(column => { 
                if (column.field === 'responseIds') return { ...column, field: 'responses' };
                if (column.field === 'creationDate') return { ...column, field: 'creationDateFormatted' };
                if (column.field === 'scoreData_average') return { ...column, field: 'average' };
                if (column.field === 'name') return { ...column, field: 'templateName' };
                return column;
            });
            const templateIndex = columns.findIndex(column => column.field === 'templateName')
            if (templateIndex > -1) columns.splice(templateIndex + 1, 0, { label: 'Naam', field: 'name' });
            const fileName = this.selectedFiltersLength ? 'Formulieren (gefilterd).csv' : 'Formulieren.csv';
            handleExportCsv(this.exportedForms, columns, fileName);
            this.exportingForms = false;
        }
    },
    computed: {
        getColumns: function() {
            const organisationType = this.$store.getters.getOrganisationType;

            if (organisationType === 'contractor')
                return [
                    {
                        field: 'projectName',
                        label: 'Projectnaam',
                        width: '150px',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.filteredProjectsFilterOptions,
                        disableSorting: true,
                        loading: this.loadingProjectFilterOptions
                    },
                    {
                        field: 'name',
                        label: 'Formuliertemplate',
                        width: '150px',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.filteredTemplateFilterOptions,
                        loading: this.loadingTemplateFilterOptions
                    },
                    {
                        field: 'client',
                        label: 'Opdrachtgever',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.organisationFilterOptions,
                        disableSorting: true,
                        loading: this.loadingOrganisationFilterOptions
                    },
                    {
                        field: 'creationDate',
                        label: 'Datum',
                        width: '70px',
                        filter: true,
                        filterType: 'datePicker',
                        defaultSort: 'DESC'
                    },
                    {
                        field: 'responseIds',
                        label: 'Respondenten',
                        width: '100px',
                        filter: true,
                        filterType: 'number',
                        sliderMax: this.responseMaxLength
                    },
                    {
                        field: 'scoreData_average',
                        label: 'Score',
                        width: '100px',
                        filter: true,
                        filterType: 'number',
                        sliderMax: 10
                    },
                    {
                        field: 'statusLabel',
                        label: 'Status',
                        align: 'right',
                        width: '120px',
                        loadingStyle: 'badge',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.statusFilterOptions,
                        showSearchInput: false,
                        filterWidth: '240px',
                        loading: this.loadingStatusFilterOptions
                    }
                ];
            else if (organisationType === 'client')
                return [
                    {
                        field: 'projectName',
                        label: 'Projectnaam',
                        width: '150px',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.filteredProjectsFilterOptions,
                        disableSorting: true,
                        loading: this.loadingProjectFilterOptions
                    },
                    {
                        field: 'name',
                        label: 'Naam',
                        width: '150px',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.filteredTemplateFilterOptions,
                        loading: this.loadingTemplateFilterOptions
                    },
                    {
                        field: 'contractor',
                        label: 'Opdrachtnemer',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.organisationFilterOptions,
                        disableSorting: true,
                        loading: this.loadingOrganisationFilterOptions
                    },
                    {
                        field: 'creationDate',
                        label: 'Datum',
                        width: '60px',
                        filter: true,
                        filterType: 'datePicker',
                        defaultSort: 'DESC'
                    },
                    {
                        field: 'scoreData_average',
                        label: 'Score',
                        width: '100px',
                        filter: true,
                        filterType: 'number',
                        sliderMax: 10
                    },
                    {
                        field: 'statusLabel',
                        label: 'Status',
                        align: 'right',
                        width: '120px',
                        loadingStyle: 'badge',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.statusFilterOptions,
                        showSearchInput: false,
                        loading: this.loadingStatusFilterOptions
                    }
                ];
            else return [];
        },
        organisationFilterOptions: function() {
            const organisationType = this.$store.getters.getOrganisationType;
            if(organisationType === 'client') 
                return this.allContractorsInForms.filter(item => item.label.toLowerCase().includes(this.organisationFilterSearch.toLowerCase()))
            
            else return this.allClientsInForms.filter(item => item.label.toLowerCase().includes(this.organisationFilterSearch.toLowerCase()))
        },
        filteredProjectsFilterOptions: function() {
            return this.projectsFilterOptions.filter(option => option.label.toLowerCase().includes(this.projectFilterSearch.toLowerCase()))
        },
        filteredTemplateFilterOptions: function() {
            return this.templateFilterOptions.filter(option => option.label.toLowerCase().includes(this.templateFilterSearch.toLowerCase()))
        },
        freemiumOrganisation: function() {
            return freemiumOrganisation();
        },
        exportButtonText: function() {
            if (this.exportingForms) {
                const exportingPercentage = Math.round(100 * this.exportedForms.length / this.formsMinimal.length)
                return `${exportingPercentage}%`;
            } else {
                return 'Exporteren';
            }
        },
    },
    created() {
        this.initializeFilterData();
    },
    mounted() {
        this.paginatedTableLength = getCalculatedRows(10);
        this.initializedTableLength = true;
        this.handleUpdateTable();
    }
};
</script>

<style scoped lang="scss">
@import '../../components/qds/assets/style/_variables.scss';

.page-padding {
    padding-bottom: 0;
}

.zerostate {
    margin-top: 100px;
}

.boldText {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.forms {
    padding: 27px 50px 27px 40px;
}

h2 {
    font-weight: 500;
    font-size: 31px;
    line-height: 33px;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.name-with-template {
    display: flex;
    flex-direction: column;

    .template {
        font-size: 13px;
        color: $color-grey-5;
    }
}

progress[value] {
  --w: 100%; /* the width*/  
  --color:  /* the progress color */
    linear-gradient(90deg,#00A1AE,#006168) 0/var(--w);
  --background: lightgrey; /* the background color */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: var(--w);
  height: 30px;
  margin: 10px;
  border-radius: 0.7em;
  background: var(--background);
}
progress[value]::-webkit-progress-bar {
  border-radius: 0.7em;
  background: var(--background);
}
progress[value]::-webkit-progress-value {
  border-radius: 0.7em;
  background: var(--color);
}
progress[value]::-moz-progress-bar {
  border-radius: 0.7em;
  background: var(--color);
}
</style>
