<template>
    <div class="document-upload-step">
        <div class="content">
            <label for="document-upload" class="upload-field" :class="{ dragging, uploading: uploadingFile }" title="">
                <div class="center">
                    <div class="file-images">
                        <img class="default-file file" src="/statics/img/default-file.png" alt="">
                        <img class="pdf-file file" src="/statics/img/pdf-file.png" alt="">
                        <img class="word-file file" src="/statics/img/word-file.png" alt="">
                        <img class="animating-file file" src="/statics/img/default-file.png" alt="">
                    </div>

                    <p class="text"><span class="highlight">Klik om te uploaden</span> of sleep je bestanden hier naar toe</p>
                </div>
                <input ref="documentUpload" class="hidden-input" type="file" title="" @change="handleFileUpload" :disabled="uploadingFile" accept=".pdf, .rsx">
            </label>

            <div class="documents-wrapper white-fade-block">
                <transition-group name="documents-list" class="documents-list">
                    <div v-for="document in documentsArray" :key="document.id" class="document-row">
                        <div class="info">
                            <div class="left">
                                <img class="file-icon" :src="getImagePath(document.fileType)" alt="">
                                <p class="name">{{ document.documentName }}</p>
                                <p class="size">{{ document.size }}</p>
                            </div>
                            <div class="right">
                                <p v-if="!document.uploading" class="label">Geüpload op {{ document.uploadDate }} door {{ document.uploadedUserName }}</p>
                                <q-button size="xsmall" variation="danger" @click="handleDeleteDocument(document)" :loading="deletingDocumentId === document.documentId">Verwijderen</q-button>
                            </div>
                        </div>
                        <div 
                            class="loader" 
                            :class="{ show: document.uploading }"
                            :style="`--progress: ${document.progress}%`">
                            <div class="bar" :class="{ finished: document.progress === 100 }"></div>
                            <p class="percentage">{{ document.progress }}%</p>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
import { DELETE_PROJECT_DOCUMENT } from '@/graphql/mutations.js';

import { getFullName, parseSize } from '@/assets/js/utils.js';
import gql from 'graphql-tag';

export default {
    name: 'document-upload-step',
    props: {
        project: {
            type: Object,
            required: true
        },
        documents: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            dragging: false,
            uploadingFile: false,
            documentsArray: [],
            deletingDocumentId: ''
        }
    },
    methods: {
        async handleFileUpload(event) {
            const file = event.target.files[0];
            const fileType = file.name.split('.').pop();
            if(!['pdf', 'rsx'].includes(fileType)) {
                this.dragging = false;
                event.target.value = '';
                return this.$store.commit('notify', { type: 'info', message: 'Momenteel ondersteunen wij alleen pdf en rsx bestanden' })
            }

            if (fileType === 'rsx') {
                if (this.documentsArray.some(document => document.fileType === 'rsx')) {
                    this.dragging = false;
                    event.target.value = '';
                    return this.$store.commit('notify', { type: 'info', message: 'Er kan maar één RSX-document geüpload worden per project.' })
                }
            }

            const UPLOAD_ENDPOINT = `${process.env.UPLOAD_URL}/documents`;
            const authorizationHeader = `Bearer ${this.$store.getters.getJwt}`;
            const projectJwtString = localStorage.getItem('projectJwt')
            const projectJwt = JSON.parse(projectJwtString) || {};

            const formData = new FormData();
            formData.append('file', file);
            formData.append('organisationId', this.$store.getters.getCurrentOrganisation.id);
            formData.append('userId', this.$store.getters.getUser.id);
            formData.append('projectId', this.project.id);
            formData.append('projectName', this.project.name);
            formData.append('uploadedUserName', getFullName(this.$store.getters.getUser.firstName, this.$store.getters.getUser.lastName));
            formData.append('projectJwt', projectJwt.jwt);

            const id = JSON.stringify(Math.random() * 10000);
            const tempDocument = {
                id,
                documentId: id,
                documentName: file.name,
                originalSize: file.size,
                size: parseSize(file.size),
                projectId: this.project.id,
                projectName: this.project.name,
                progress: 0,
                uploading: true,
                fileType
            }

            this.documentsArray.push(tempDocument);
            this.animateFileUpload(tempDocument);

            event.target.value = '';

            this.$http
                .post(UPLOAD_ENDPOINT, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: authorizationHeader,
                        'application-context': 'application-context'
                    },
                })
                .then(async (uploadResponse) => {
                    if (!this.hasRsxStandardSet) return uploadResponse;
                    if (fileType !== 'rsx') return uploadResponse;

                    this.$store.commit('notify', { type: 'info', message: 'RSX gegevens worden verwerkt in het project...' })
                    
                    await this.updateProjectReferences()

                    this.$store.commit('notify', { type: 'success', message: 'RSX gegevens zijn verwerkt!' })

                    this.$emit('reloadReferences')

                    return uploadResponse;
                })
                .catch(() => {
                    this.$store.commit('error', { type: 'danger', message: 'Er ging iets fout tijdens het verwerken van de RSX gegevens' })
                })
                .then((response) => {
                    const document = response.data;
                    
                    this.finishFileUpload(tempDocument, document);
                })
                .catch(() => {
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het uploaden van dit document' })
                    this.errorFileUpload(tempDocument);
                    this.uploadingFile = false;
                    this.dragging = false;
                });
        },
        async animateFileUpload(tempDocument) {
            this.progressDocument(tempDocument);
            this.uploadingFile = true;
        },
        errorFileUpload(tempDocument) {
            console.log('error')
            const index = this.documentsArray.findIndex(document => document.documentId === tempDocument.documentId);
            this.documentsArray.splice(index, 1);
            this.$emit('documentsUpdated', this.documentsArray);
        },
        finishFileUpload(tempDocument, createdDocument) {
            const index = this.documentsArray.findIndex(document => document.documentId === tempDocument.documentId);
            const uploadTimestamp = Date.now();
            const uploadDate = new Date(uploadTimestamp).toLocaleString('nl-NL', { day: 'numeric', month: 'short', year: 'numeric' });
            const user = this.$store.getters.getUser;
            
            this.documentsArray[index] = {
                ...tempDocument,
                documentId: createdDocument.documentId,
                documentUrl: createdDocument.documentUrl,
                uploadTimestamp,
                uploadDate,
                uploadedUserName: getFullName(user.firstName, user.lastName),
                progress: 100,
                uploading: false
            }
            this.uploadingFile = false;
            this.dragging = false;
            this.$emit('documentsUpdated', this.documentsArray);


        },
        async progressDocument(tempDocument) {
            const index = this.documentsArray.findIndex(document => document.documentId === tempDocument.documentId);
            const max = 99;

            while(this.documentsArray[index].uploading) {
                await new Promise(r => setTimeout(r, 400));

                const maxRandom = (99 - this.documentsArray[index].progress) / 2;
                const min = 5;

                let randomIncrement = Math.round(Math.random() * maxRandom + min);
                if(this.documentsArray[index].progress + randomIncrement > max) randomIncrement = max - this.documentsArray[index].progress;
                const newProgress = this.documentsArray[index].progress + randomIncrement;
                if(this.documentsArray[index].progress !== 100) this.documentsArray[index].progress = newProgress;
                
                if(this.documentsArray[index].progress >= max) break
            }
        },
        async updateProjectReferences() {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql`
                        mutation($projectId: String!)  {
                            documents_updateProjectSuggestions(
                                projectId: $projectId
                                standardSetVersion: "1.0"
                            )
                        }
                    `,
                    variables: {
                        projectId: this.project.id
                    }
                })
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(error);
                })
            })
        },
        handleDeleteDocument(document) {
            this.deletingDocumentId = document.documentId;
            
            const projectJwtString = localStorage.getItem('projectJwt')
            const projectJwt = JSON.parse(projectJwtString) || {};

            this.$apollo.mutate({
                mutation: DELETE_PROJECT_DOCUMENT,
                variables: {
                    documentId: document.documentId,
                    projectJwt: projectJwt.jwt
                }
            })
            .then(() => {
                const index = this.documentsArray.findIndex(arrayDocument => arrayDocument.documentId === document.documentId);
                this.documentsArray.splice(index, 1);
                this.$emit('documentsUpdated', this.documentsArray);
                this.$store.commit('notify', { type: 'success', message: 'Document is succesvol verwijderd' })

                this.$emit('reloadReferences')
            })
            .catch(error => {
                console.log(error)
                this.deletingDocumentId = '';
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het uploaden van dit document' })
            })
        },
        setDragEventlistener() {
            const input = this.$refs.documentUpload;
            input.addEventListener('dragover', this.handleDragOver);
            input.addEventListener('dragleave', this.handleDragLeave);
        },
        removeDragEventlistener() {
            const input = this.$refs.documentUpload;
            input.removeEventListener('dragover', this.handleDragOver);
            input.removeEventListener('dragleave', this.handleDragLeave);
        },
        handleDragOver() {
            this.dragging = true;
        },
        handleDragLeave() {
            this.dragging = false;
        },
        getImagePath(fileType) {
            switch(fileType) {
                case'pdf':
                    return '/statics/img/pdf-file.png'
                case'docx':
                    return '/statics/img/word-file.png'
                case'rsx':
                    return '/statics/img/rsx-file.png'
                default:
                    return '/statics/img/default-file.png'
            }
        }
    },
    computed: {
        hasRsxStandardSet: function() {
            return this.$store.getters.getActiveProducts.some(product => product.slug === 'standard_set');
        }
    },
    created() {
        this.documentsArray = [ ...this.documents ];
    },
    mounted() {
        this.setDragEventlistener();
    },
    beforeDestroy() {
        this.removeDragEventlistener();
    },
    watch: {
        documents: {
            handler() {
                this.documentsArray = [ ...this.documents ];
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.document-upload-step {
    .content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 50px;

        .upload-field {
            display: grid;
            place-items: center;
            position: relative;
            height: 200px;
            width: 100%;
            border-radius: 14px;
            border: 2px dashed #E3E3E3;
            cursor: pointer;
            transition: .2s ease;

            &.uploading {
                pointer-events: none;
                opacity: 0.9;
            }

            &:hover,
            &.dragging,
            &:active,
            &.uploading {
                border-color: $color-blue-darker;
                background-color: lighten($color-blue-lighter, 25%);

                .center {
                    
                    .file-images {
                        translate: 0 -14px;
                        transition: translate 1s cubic-bezier(.15,.75,.2,1);

                        .pdf-file,
                        .word-file {
                            transition: 1s cubic-bezier(.15,.75,.2,1);
                        }
                        .pdf-file {
                            translate: -100% -35%;
                            rotate: -20deg;
                        }
                        .word-file {
                            translate: 0% -35%;
                            rotate: 20deg;
                        }
                    }
                }
            }

            .center {
                display: grid;
                place-items: center;
                gap: 14px;

                .file-images {
                    position: relative;
                    transition: translate .3s ease;

                    .file {
                        height: 64px;
                        width: 64px;
                    }

                    .default-file {
                        z-index: 1;
                    }

                    .animating-file {
                        position: absolute;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        translate: -50% -50%;
                        z-index: 5;
                        border: 1px solid green;
                        opacity: 0;
                    }

                    .pdf-file,
                    .word-file {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        translate: -50% -50%;
                        scale: 0.7;
                        opacity: 0.6;
                        transition: .3s ease;
                        z-index: -1;
                    }
                }

                .text {
                    .highlight {
                        font-weight: 500;
                        color: $color-blue-darker;
                    }
                }
            }

            .hidden-input {
                position: absolute;
                opacity: 0;
                inset: 0;
                cursor: pointer;
            }
        }

        .documents-wrapper {
            padding-inline: 20px;
            margin-inline: -20px;
            max-height: calc(100vh - 81px - 125px - 50px - 204px - 30px - 60px);
            overflow: auto;

            .documents-list {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .document-row {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    border-radius: 4px;
                    border: 1px solid #DEE2E6;
                    padding: 10px 16px;
                    box-shadow: 0 2px 5px -1px #DEE2E6;

                    .info {
                        display: flex;
                        justify-content: flex-end;
                        max-width: 100%;

                        .left:hover + .right {
                            opacity: 0;
                        }

                        .left {
                            position: absolute;
                            left: 16px;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            max-width: 50%;
                            z-index: 1;
                            background-color: white;
                            transition: max-width 400ms ease;

                            &:hover {
                                max-width: 100%;
                            }
        
                            .file-icon {
                                width: 20px;
                                height: 20px;
                            }
        
                            .name {
                                width: 100%;
                                max-width: min-content;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                font-weight: 500;
                            }
        
                            .size {
                                white-space: nowrap;
                                color: #757575;
                            }
                        }
                        .right {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 12px;
                            width: 50%;
                            height: 22px;
                            transition: opacity 300ms ease;

                            .label {
                                font-size: 14px;
                                color: $color-grey-7;
                                white-space: nowrap;
                            }

                        }
                    }

                    .loader {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        width: 100%;
                        max-height: 0;
                        overflow: hidden;
                        opacity: 0;
                        transition: 300ms cubic-bezier(.35,.64,.1,1) 300ms;

                        &.show {
                            opacity: 1;
                            margin-top: 8px;
                            max-height: 22px;
                        }

                        .bar {
                            position: relative;
                            flex-grow: 1;
                            height: 4px;
                            border-radius: 2px;
                            background-color: $color-grey-3;
                            overflow: hidden;
                            transition: 200ms ease 100ms;

                            &.finished {
                                margin-left: auto;
                                flex-grow: 0;
                                transition: 300ms cubic-bezier(.35,.64,.1,1) 100ms;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                inset: 0;
                                inset-inline-end: calc(100% - var(--progress));
                                background-color: $color-primary;
                                transition: inset-inline-end 400ms ease-in-out;
                            }
                        }
                        .percentage {
                            text-align: right;
                            width: 40px;
                        }
                    }

                }
            }

        }
    }
}

.documents-list-move, .documents-list-enter-active, .documents-list-leave-active {
    transition: .2s ease;
    -webkit-transition: .2s ease;
}
.documents-list-enter, .documents-list-leave-to {
    opacity: 0;
    scale: 0.8;
    margin-top: -30px;
}
.documents-list-leave-to {
    margin-top: -58px;
}

.white-fade-block {
    position: relative;
    margin-top: -30px;
    
    &:before,
    &:after {
        content: "";
        position: sticky;
        display: block;
        inset-inline: 0;
        height: 20px;
        z-index: 5;
    }
    &:before {
        top: 0px;
        background: linear-gradient(180deg, white, transparent);
    }
    &:after {
        bottom: 0px;
        background: linear-gradient(0deg, white, transparent);
    }
}
</style>