<template>
    <div class="app-base">
        <div class="left app-base-left" :class="folded ? 'closed' : 'opened'">
            <q-navbar
                v-model="folded"
                :options="navbarItems"
                @input="saveFold"
                :notify="navbarNotificationType"
                @notificationClick="
                    showReleaseNotes = true;
                    notifcationSeen = true;
                "
            ></q-navbar>
        </div>

        <div id="content-overflow" class="right" :class="folded ? 'closed' : 'opened'">
            <div class="topbar" :class="{ visible: showTopBar }">
                <q-topbar :user="user" @search="handleSearchEvent"></q-topbar>
                <PortalTarget class="pageflow-portal" name="pageflow" />
            </div>
            <q-searchbox
                v-if="isSearching"
                id="q-searchbox"
                :loading="searchLoading"
                :results="globalSearchResults"
                @close="handleCloseSearchBox"
                @input="handleSearch"
            ></q-searchbox>

            <div class="content" :class="{ 'topbar-visible': showTopBar }">
                <router-view></router-view>
                <notifications />
            </div>

            <q-popup v-if="showReleaseNotes">
                <release-notes-modal @close="closePopup"></release-notes-modal>
            </q-popup>
        </div>

        <document-viewer ref="documentViewer"/>
    </div>
</template>

<script>
import { USER_ORGANISATIONS } from '@/graphql/mutations';
import { GET_USER, ORGANISATION_USERS_MINIMAL, GLOBAL_SEARCH } from '@/graphql/queries';
import { extractError, updateMenuState } from '@/assets/js/utils';
import ReleaseNotesModal from '@/components/infoModals/ReleaseNotesModal.vue';
import _ from 'lodash';
import base64url from 'base64-url';

import Notifications from '@/components/Notifications.vue';
import DocumentViewer from './DocumentViewer/DocumentViewer.vue';

const DEFAULT_CLIENT_MENU = [
    '/',
    '/projects',
    '/projects/mine',
    '/projects/finished',
    '/forms',
    '/companies',
    '/analysis', 
    '/reports',
    '/settings',
];
const DEFAULT_CONTRACTOR_MENU = [
    '/',
    '/projects',
    '/projects/mine',
    '/projects/finished',
    '/forms',
    '/reports',
    '/settings',
];

export default {
    name: 'AppBase',
    components: { 
        ReleaseNotesModal,
        Notifications,
        DocumentViewer
    },
    data() {
        return {
            isSearching: false,
            searchLoading: false,
            searchResults: {},
            organisations: [],
            pages: [],
            requiredSettingPermissions: [
                'User__view',
                'Organisation__user_invite',
                'Organisation__add_role',
                'Organisation__remove_role',
                'Organisation__update_details',
                'Organisation__sso_config',
            ],
            folded: JSON.parse(localStorage.getItem('navbar_folded')) || false,
            newReleaseNotes: true,
            showReleaseNotes: false,
            notifcationSeen: localStorage.getItem('releasenotes_badge') || false,
            topBarVisibleMouse: false,
            topBarVisibleScroll: false,
            lastScrollY: 0,
            topBarTimeout: null
        };
    },
    methods: {
        updateRequests() {
            this.$apollo.queries.userRequests.refetch();
        },
        closePopup() {
            this.showReleaseNotes = false;
        },
        logout() {
            this.$store.commit('resetState');
            this.$router.push('/login');
        },
        saveFold(folded) {
            localStorage.setItem('navbar_folded', folded);
            updateMenuState(this, folded);
        },
        getOrganisations() {
            this.$apollo
                .query({
                    query: USER_ORGANISATIONS,
                    variables: { userId: this.user.id },
                })
                .then((response) => {
                    const orgs = response.data.user.organisations || [];
                    this.organisations = orgs.filter((org) => org.status == 'active').map((org) => org.organisation);
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        getUser() {
            this.$apollo
                .query({
                    query: GET_USER,
                    variables: { id: this.user.id },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    this.$store.commit('setUser', response.data.user);
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        handleSearchEvent() {
            this.isSearching = true;
            this.$store.commit('addBodyListener', [
                'q-searchbox',
                () => {
                    this.isSearching = false;
                },
            ]);
        },
        handleCloseSearchBox() {
            this.isSearching = false;
            this.searchLoading = false;
            this.searchResults = [];
        },
        handleSearch(search) {
            this.searchLoading = true;
            return this._handleSearchInput(search);
        },
        handleSearchInput(search) {
            this.searchLoading = true;
            if (search)
                this.$apollo
                    .query({
                        query: GLOBAL_SEARCH,
                        variables: {
                            search,
                        },
                        fetchPolicy: 'no-cache',
                    })
                    .then((result) => {
                        delete result.data.shared_globalSearch.__typename;
                        const { projects = [], forms: surveys = [], users = [], organisations = [] } = result.data.shared_globalSearch;
                        this.searchResults = {
                            projects,
                            surveys,
                            users,
                            organisations
                        }
                    })
                    .catch((error) => {
                        this.searchLoading = false;
                        console.log(error.message);
                    });
            else this.searchResults = {};
        },
        _handleSearchInput: _.debounce(function (inputValue) {
            if (inputValue) return this.handleSearchInput(inputValue);
            else {
                this.isSearching = false;
                this.searchResults = {};
                this.$store.commit('resetBodyListeners');
            }
        }, 500),
        subRoutePermissionsFilter(item) {
            let subOptions = item.subOptions || [];
            item.subOptions = subOptions.filter((option) => {
                if (!option.requiredPermissions) return true;

                return option.requiredPermissions.every((requiredPermission) => {
                    const slugArray = requiredPermission.split('__');
                    const entity = slugArray[0];
                    const permission = slugArray[1];

                    return this.ability.get().can(permission, entity);
                });
            });

            return item;
        },
        handleMouseMove(event) {
            const scrollX = window.scrollX;
            const scrollY = window.scrollY;
            const mouseX = event.pageX - scrollX;
            const mouseY = event.pageY - scrollY;
            const navBar = this.folded ? 60 : 240;
            if(mouseY < 81 || mouseX < navBar) {
                if(!this.topBarVisibleMouse) this.setBarVisible(true);
            } else {
                if(this.topBarVisibleMouse) this.setBarVisible(false, 1000);
            }
        },
        async setBarVisible(visible, timeout) {
            if(this.topBarTimeout) clearTimeout(this.topBarTimeout);
            if(timeout) return this.topBarTimeout = setTimeout(() => { this.topBarVisibleMouse = visible }, timeout);
            this.topBarVisibleMouse = visible;
        },
        handleScroll(event) {
            if(event.target.id !== 'content-overflow') return
            const scrollOffset = 200;
            const scrollTop = event.target.scrollTop;

            if(scrollTop > this.lastScrollY && !this.topBarVisibleScroll) return this.lastScrollY = scrollTop;
            if(scrollTop < this.lastScrollY && this.topBarVisibleScroll && scrollTop > 80) this.lastScrollY = scrollTop - scrollOffset;

            if(scrollTop === 0 && this.lastScrollY < 80 && this.topBarVisibleScroll) return this.topBarVisibleScroll = false;
            else if(scrollTop > this.lastScrollY + scrollOffset && this.topBarVisibleScroll) {
                this.topBarVisibleScroll = false;
                this.lastScrollY = scrollTop;
            }
            else if(scrollTop < this.lastScrollY - scrollOffset && !this.topBarVisibleScroll) {
                this.topBarVisibleScroll = true;
                this.lastScrollY = scrollTop + scrollOffset;
            }
        },
        getRelativeProjectName(project) {
            if(!project) return ''
            const key = `${this.organisationType}ProjectName`;
            return project[key] || project.name
        }
    },
    computed: {
        globalSearchResults() {
            const results = {};
            Object.keys(this.searchResults).forEach((category) => {
                const categoryResults = this.searchResults[category].map((result) => {
                    let name = '';
                    let link = '/';
                    let avatar = null;
                    let avatarFallback = null;

                    switch (category) {
                        case 'organisations':
                            link = `/companies/${result.id}`;
                            avatar = result.logo;
                            avatarFallback = `${result.name.charAt(0)}${result.name.charAt(1)}`;
                            name = result.name;
                            break;
                        case 'users':
                            link = `/settings/users`;
                            avatar = result.avatar;
                            avatarFallback =
                                result.firstName && result.lastName
                                    ? `${result.firstName.charAt(0)}${result.lastName.charAt(0)}`
                                    : null;
                            name =
                                result.firstName && result.lastName
                                    ? `${result.firstName} ${result.lastName}`
                                    : `${result.email}`;
                            break;
                        case 'projects': 
                            const projectName = this.getRelativeProjectName(result);
                            link = `/${category}/${result.id}?title=${projectName}`;
                            name = projectName;
                            break;
                        case 'surveys':
                            // this catches the case when a project is deleted, 
                            // but the corresponding forms aren't
                            if(!result.project.id) return null
                            
                            const formProjectName = this.getRelativeProjectName(result.project);
                            link = `/enquetes/${result.id}`;
                            name = result.name;
                            if(formProjectName) name += ` | ${formProjectName}`;
                            break;
                        default:
                            link = `/${category}/${result.id}`;
                            name = result.name;
                            break;
                    }
                    return {
                        name,
                        avatar,
                        avatarFallback,
                        link,
                    };
                })
                .filter(result => result);

                results[category] = categoryResults;
            });

            this.searchLoading = false;
            return results;
        },
        user() {
            return this.$store.getters.getUser;
        },
        navbarItems() {
            let baseNavbar = this.navbar;

            const canViewProjects = this.ability.get().can('view', 'Project');
            const canAnalyse = this.ability.get().can('view', 'Analysis');
            const projectMenuItem = baseNavbar.find((nav) => nav.to === '/projects/mine');
            if (projectMenuItem) projectMenuItem.to = canViewProjects ? '/projects' : '/projects/mine';

            const products = this.$store.getters.getActiveProducts;
            const activeProducts = products.filter((product) => product.enabled).map((product) => product.slug);

            baseNavbar = baseNavbar.filter((item) => {
                if (!canAnalyse && (item.to === '/analysis' || item.to === '/companies')) return false;

                const isClientWithoutCrowProduct =
                    !activeProducts.find((slug) => slug === 'crow_client') &&
                    activeProducts.find((slug) => slug === 'client');

                if (['/analysis', '/settings', '/'].includes(item.to)) {
                    return !isClientWithoutCrowProduct;
                }
                return true;
            });

            const allowSettings = this.requiredSettingPermissions.some((slug) => {
                const slugArray = slug.split('__');
                const entity = slugArray[0];
                const permission = slugArray[1];

                return this.ability.get().can(permission, entity);
            });

            const settingsMenu = baseNavbar.find((nav) => nav.to === '/settings');
            if (settingsMenu) settingsMenu.badge = this.userRequests > 0 ? this.userRequests : null;
            let navbar = allowSettings ? baseNavbar : baseNavbar.filter((item) => item.to !== '/settings');

            if ((!activeProducts.includes('pd_full') && (!activeProducts.includes('pd_basis'))) && this.$store.getters.getCurrentOrganisation.id !== 'crow-master-organisation') navbar = navbar.filter((item) => item.to !== '/reports');

            if (this.$store.getters.getCurrentOrganisation.id === 'crow-master-organisation') navbar = navbar.filter((item) => item.to !== '/analysis');

            return this.$store.getters.getOrganisationType === 'client'
                ? navbar.filter((item) => DEFAULT_CLIENT_MENU.includes(item.to)).map(this.subRoutePermissionsFilter)
                : navbar
                      .filter((item) => DEFAULT_CONTRACTOR_MENU.includes(item.to))
                      .map(this.subRoutePermissionsFilter);
        },
        navbarNotificationType() {
            return this.notifcationSeen ? null : this.newReleaseNotes ? 'present' : 'confetti';
        },
        showTopBar() {
            return this.topBarVisibleMouse || this.topBarVisibleScroll || this.isSearching
        },
        navbar() {
            const organisationType = this.$store.getters.getOrganisationType;
            const key = `${organisationType}Status`;

            const filter = {
                status: {
                    sorting: 'DESC',
                    filter: ['finished'],
                    search: '',
                    latest: true
                }
            };
            const finishedProjectsQuery = base64url.encode(JSON.stringify(filter));

            return [
                {
                    to: '/',
                    label: 'Dashboard',
                    icon: 'dashboard',
                    active: 'dashboard-gradient',
                },
                {
                    to: '/projects/mine',
                    label: 'Projecten',
                    icon: 'helmet',
                    active: 'helmet-gradient',
                    subOptions: [
                        {
                            to: '/projects',
                            label: 'Alle projecten',
                            requiredPermissions: ['Project__view'],
                        },
                        {
                            to: '/projects/mine',
                            label: 'Mijn projecten',
                        },
                        {
                            to: `/projects/finished?filter=${finishedProjectsQuery}`,
                            label: 'Afgeronde projecten',
                            requiredPermissions: ['Project__view']
                        },
                    ],
                },
                {
                    to: '/forms',
                    label: 'Formulieren',
                    icon: 'survey',
                    active: 'survey-gradient',
                },
                {
                    to: '/companies',
                    label: 'Bedrijven',
                    icon: 'building',
                    active: 'building-gradient',
                },
                {
                    to: '/analysis',
                    label: 'Analyse',
                    icon: 'chart',
                    active: 'chart-gradient',
                },
                {
                    to: '/reports',
                    label: 'Analyse',
                    icon: 'chart',
                    active: 'chart-gradient',
                },
                {
                    to: '/settings',
                    label: 'Instellingen',
                    icon: 'settings',
                    active: 'settings-gradient',
                    badge: 'beta',
                },
            ]
        },
        organisationType: function() {
            return this.$store.getters.getOrganisationType
        }
    },
    apollo: {
        userRequests: {
            query: ORGANISATION_USERS_MINIMAL,
            variables() {
                return {
                    organisationId: this.$store.getters.getCurrentOrganisation.id,
                };
            },
            fetchPolicy: 'no-cache',
            update: (data) => {
                const requests = data.organisation.users.filter((user) => user.status === 'requested');
                return requests.length;
            },
        },
    },
    created() {
        this.ability.set(this.$store.getters.getJwtPayload);
    },
    mounted() {
        this.$root.$on('mousemove', this.handleMouseMove);
        this.$root.$on('scroll', this.handleScroll);
        const vm = this;
        document.addEventListener('keydown', function (event) {
            if (event.ctrlKey && event.key == ' ') {
                vm.isSearching = !vm.isSearching;
            }
        });

        this.documentViewerController.Initialize(this.$refs.documentViewer);
    },
    beforeDestroy() {
        this.$root.$off("mousemove", this.handleMouseMove);
        this.$root.$off("scroll", this.handleScroll);
    },
    watch: {
        showTopBar: function() {
            this.$root.$emit('topbarToggle', this.showTopBar)
        }
    }
};
</script>

<style lang="scss" scoped>

.app-base {
    display: flex;

    .tooltip-portal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 105;
        pointer-events: none;
    }

    .popup-portal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 104;

        &:empty {
            pointer-events: none;
        }
    }

    .left {
        height: 100vh;
        flex-grow: 1;
        z-index: 1;
        transition: max-width .3s ease;

        &.opened {
            max-width: 240px;
        }

        &.closed {
            max-width: 60px;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        position: relative;
        max-height: 100vh;
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: scroll;
        transition: max-width .3s ease;

        &.closed {
            max-width: calc(100vw - 60px);
        }
        &.opened {
            max-width: calc(100vw - 240px);
        }

        .topbar {
            position: sticky;
            // bottom: 80px;
            // right: 0;
            background: white;
            z-index: 102;
            height: 80px;
            width: 100%;
            top: -81px;
            transition: top .3s ease;

            &.visible {
                top: 0;
            }

            .pageflow-portal {
                inset-inline: 0;
            }
        }

        .content {
            // overflow-x: auto;
        }
    }
}
</style>
