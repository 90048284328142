<template>
    <div class="detail-page-padding" style="max-width: 900px;">
        <div class="space-between">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.go(-1)">Beheer</arrow-button>
            </div>
        </div>
        <div class="mb-l">
            <div class="settings-intro">
                <div class="space-between vertical-center mb-m">
                    <h1>Collega's</h1>

                    <div>
                        <q-button
                            v-if="!hybridModeActive"
                            v-can="'Organisation__invite_user'"
                            icon="users"
                            iconSize="22"
                            :iconStyle="{ width: '14px', height: '22px', marginLeft: '-9px' }"
                            variation="secondary"
                            size="medium"
                            @click="createUser = true"
                            >Collega uitnodigen</q-button
                        >
                    </div>
                </div>
                <p>
                    Hier kan je collega’s uitnodigen, openstaande uitnodigingen bekijken en terugtrekken (ook als de
                    gebruiker <br />vanuit een project is uitgenodigd) en de actieve gebruikers zien en instellingen per
                    gebruiker aanpassen.
                </p>
            </div>
        </div>

        <div v-if="(requestedAndInvitedUsers && requestedAndInvitedUsers.length) || searchInvitedUsers" class="panel q-card-small shared pb-xs">
            <div class="head space-between vertical-center">
                <h2>Uitnodigingen en verzoeken</h2>
                <q-input labelPosition="inside"
                    size="small"
                    style="width: 170px;"
                    variation="blank"
                    placeholder="Zoek een gebruiker"
                    clearable
                    @input="searchForInvitedUsers"
                ></q-input>
            </div>

            <q-table :data="requestedAndInvitedUsers" :columns="invitedColumns">
                <template v-slot:row="{ row, column }">
                    <span class="avatar-row padding-left" v-if="column == 'avatar'">
                        <q-avatar size="small" :img="row.avatar">{{ initials(row.firstName, row.lastName) }}</q-avatar>
                    </span>
                    <span v-else-if="column == 'email'">
                        <div>
                            <span class="ellipsis" v-if="row.email">{{ row.email }}</span>
                            <span class="ellipsis" v-else-if="row.organisation.email">{{ row.organisation.email }}</span>
                            <q-tooltip v-if="row.status === 'new'" position="top">
                                <template v-slot:tooltip>
                                    <p>
                                        De gebruiker heeft zich geregistreerd, maar zijn e-mailadres is nog niet
                                        bevestigd.<br />
                                        Hij kan dus nog niet inloggen bij
                                        {{ $store.getters.getCurrentOrganisation.name }}.
                                    </p>
                                </template>
                                <q-tag class="mini-tag" variation="warning" size="small">E-mail niet gevalideerd</q-tag>
                            </q-tooltip>
                        </div>
                    </span>
                    <span v-else-if="column == 'status'">
                        <q-tag size="small" :variation="statusVariation(row.organisation.status)">{{
                            $t(`userOrgStatus.${row.organisation.status}`)
                        }}</q-tag>
                    </span>
                    <span v-else-if="column == 'role'">
                        <span>{{ $t(row.organisation.role.name) }}</span>
                    </span>
                    <span v-else-if="column == 'actions'" class="padding-right flex-end vertical-center">
                        <div v-if="row.organisation.status === 'requested'" class="flex" style="gap: 12px;">
                            <q-button
                                v-can="'Organisation__decline_user'"
                                @click="cancelRequest(row.organisation.status, { userId: row.organisation.userId, email: row.organisation.email })"
                                icon="close"
                                iconSize="10"
                                :iconStyle="{ width: '12px', height: '12px', marginLeft: '-6px' }"
                                style="margin-bottom: -2px;"
                                variation="ghost"
                                size="small"
                            >Afwijzen</q-button>
                            <q-button
                                v-can="'Organisation__accept_user'"
                                @click="acceptRequest(row.organisation.userId)"
                                icon="check"
                                iconSize="18"
                                :iconStyle="{ width: '12px', height: '12px', marginLeft: '-6px' }"
                                style="margin-bottom: -2px;"
                                variation="secondary"
                                size="small"
                            >Accepteren</q-button>
                        </div>
                        <div v-if="['invited','remote_added','remote_invited'].includes(row.organisation.status)">
                            <q-button
                                v-can="'Organisation__cancel_invite_user'"
                                @click="cancelRequest(row.organisation.status, { userId: row.organisation.userId, email: row.organisation.email })"
                                icon="close"
                                iconSize="10"
                                :iconStyle="{ width: '12px', height: '12px', marginLeft: '-6px' }"
                                style="margin-bottom: -2px;"
                                variation="ghost"
                                size="small"
                                >Intrekken</q-button
                            >
                        </div>
                    </span>
                    <div v-else>
                        <span>{{ row[column] }}</span>
                    </div>
                </template>
            </q-table>
        </div>

        <div class="panel q-card-small shared pb-xs">
            <div class="head space-between vertical-center">
                <h2>Actieve gebruikers</h2>
                <q-input labelPosition="inside"
                    size="small"
                    style="width: 170px;"
                    variation="blank"
                    placeholder="Zoek een gebruiker"
                    @input="searchForActiveUsers"
                ></q-input>
            </div>

            <q-table :data="acceptedUsers" :columns="userColumns" :loading="loading">
                <template v-slot:row="{ row, column }">
                    <span class="vertical-center avatar-row padding-left" v-if="column == 'avatar'">
                        <q-avatar size="small" :img="row.avatar">{{ initials(row.firstName, row.lastName) }}</q-avatar>
                    </span>
                    <span v-else-if="column == 'email'">
                        <div>
                            <span class="ellipsis">{{ row.email }}</span>
                            <q-tooltip v-if="row.status === 'new'" position="top">
                                <template v-slot:tooltip>
                                    <p>
                                        De gebruiker heeft zich geregistreerd, maar zijn e-mailadres is nog niet
                                        bevestigd.<br />
                                        Hij kan dus nog niet inloggen bij
                                        {{ $store.getters.getCurrentOrganisation.name }}.
                                    </p>
                                </template>
                                <q-tag class="mini-tag" variation="warning" size="small">E-mail niet gevalideerd</q-tag>
                            </q-tooltip>
                        </div>
                    </span>
                    <span v-else-if="column == 'name'">
                        <span>{{ row.firstName }} {{ row.lastName }}</span>
                    </span>
                    <span v-else-if="column == 'status'">
                        <q-tag size="small" :variation="statusVariation(row.organisation.status)">{{
                            $t(`userOrgStatus.${row.organisation.status}`)
                        }}</q-tag>
                    </span>
                    <span v-else-if="column == 'role'">
                        <span>{{ $t(row.organisation.role.name) }}</span>
                    </span>
                    <span v-else-if="column == 'actions'" class="padding-right action-button">
                        <q-button
                            v-can="'Organisation__manage_user'"
                            variation="light"
                            size="xsmall"
                            @click="edit(row.id)"
                            >Wijzigen</q-button
                        >
                    </span>
                    <div v-else>
                        <span>{{ row[column] }}</span>
                    </div>
                </template>
            </q-table>
        </div>

        <q-popup v-if="createUser">
            <user-invite-modal :users="users" @close="closePopup()"></user-invite-modal>
        </q-popup>
        
        <q-popup v-if="editUser" showCloseButton @close="closePopup()">
            <user-modal :user="editModalUser" :onlyUser="Boolean(organisationUsers.length == 1)" :twofactor="globalTwofactorEnabled" @close="closePopup()"></user-modal>
        </q-popup>
    </div>
</template>

<script>
import _ from 'lodash';
import ArrowButton from '@/components/ArrowButton';
import UserInviteModal from '@/pages/app/settings/UserInviteModal';
import UserModal from '@/pages/app/settings/UserModal';
import { userInitials } from '@/assets/js/utils';

import {
    ACCEPT_ORGANISATION_ACCESS,
    DECLINE_ORGANISATION_ACCESS,
    ORGANISATION_CANCEL_USER_INVITE
} from '@/graphql/mutations';
import { ORGANISATION_USERS, ORGANISATION_USERS_MINIMAL } from '@/graphql/queries';
import { extractError } from '../../../assets/js/utils';

export default {
    components: {
        ArrowButton,
        UserInviteModal,
        UserModal
    },
    data() {
        return {
            organisationUsers: [],
            organisationUsersMinimal: [],
            usersLoading: true,
            organisationLoading: true,
            globalTwofactorEnabled: false,
            invitedColumns: [
                {
                    field: 'avatar',
                    label: '',
                    width: '90px',
                    loadingStyle: 'none'
                },
                {
                    field: 'email',
                    label: 'E-mail',
                    width: '200px'
                },
                {
                    field: 'role',
                    label: 'Rol',
                    width: '150px'
                },
                {
                    field: 'status',
                    label: 'Status',
                    width: '150px'
                },
                {
                    field: 'actions',
                    label: '',
                    align: 'right',
                    width: '250px'
                }
            ],
            userColumns: [
                {
                    field: 'avatar',
                    label: '',
                    width: '90px',
                    loadingStyle: 'none'
                },
                {
                    field: 'email',
                    label: 'E-mail',
                    width: '200px'
                },
                {
                    field: 'name',
                    label: 'Naam',
                    width: '140px'
                },
                {
                    field: 'role',
                    label: 'Rol',
                    width: '120px'
                },
                {
                    field: 'status',
                    label: 'Status',
                    width: '90px'
                },
                {
                    field: 'actions',
                    label: '',
                    align: 'right'
                }
            ],
            slugState: null,
            slugErrorMessage: '',
            editing: [],
            createUser: false,
            editUser: false,
            editModalUser: {},
            searchInvitedUsers: '',
            searchActiveUsers: ''
        };
    },
    methods: {
        toggle(type) {
            if (this.editing.includes(type)) this.editing = this.editing.filter(item => item !== type);
            else this.editing.push(type);
        },
        initials(firstName, lastName) {
            return userInitials(firstName, lastName);
        },
        statusVariation(status) {
            switch (status) {
                case 'active':
                    return 'success';
                    break;
                case 'requested':
                    return 'warning';
                    break;
                case 'invited':
                    return 'info';
                    break;
                case 'remote_invited':
                    return 'info';
                    break;
                case 'remote_added':
                    return 'info';
                    break;
                default:
                    return 'warning';
                    break;
            }
        },
        edit(userId) {
            this.editModalUser = this.users.find(u => u.id === userId);
            this.editUser = true;
        },
        closePopup() {
            this.createUser = false;
            this.editUser = false;
            this.fetch();
            this.getOrganisationMinimal();
        },
        acceptRequest(userId) {
            const vm = this;            
            this.$apollo
                .mutate({
                    mutation: ACCEPT_ORGANISATION_ACCESS,
                    variables: { id: this.$store.getters.getCurrentOrganisation.id, userId }
                })
                .then(response => {
                    this.$parent.$apollo.queries.userRequests.refetch();
                    this.$store.commit('notify', { type: 'success', message: `Verzoek geaccepteerd` });
                    vm.fetch();
                    vm.getOrganisationMinimal();
                })
                .catch(err => {
                    console.log(err);
                    vm.$store.commit('notify', extractError(err));
                });
        },
        cancelRequest(status, { userId, email }) {
            const vm = this;
            const mutation = status === 'requested' ? DECLINE_ORGANISATION_ACCESS : ORGANISATION_CANCEL_USER_INVITE;
            this.$apollo
                .mutate({
                    mutation,
                    variables: { 
                        id: this.$store.getters.getCurrentOrganisation.id, 
                        userId, 
                        email 
                    }
                })
                .then(response => {
                    vm.getOrganisationMinimal();
                    vm.fetch();
                    this.$store.commit('notify', {
                        type: 'success',
                        message: status === 'requested' ? `Verzoek afgewezen` : `Uitnodiging voor ${email} ingetrokken`
                    });
                })
                .catch(err => {
                    vm.$store.commit('notify', extractError(err));
                });
        },
        _fetch: _.debounce(function(skip, first) {
            return this.fetch(skip, first);
        }, 500),
        fetch(skip = 0, first = 600) {
            const organisation = this.$store.getters.getCurrentOrganisation;
            const userIds = organisation.users.map(user => user.userId);
            return this.$apollo
                .query({
                    query: ORGANISATION_USERS,
                    variables: {
                        skip: skip,
                        first: first,
                        sort: this.sort,
                        where: { 
                            id__in: userIds
                        }
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(result => {
                    this.usersLoading = false;
                    this.organisationUsers =
                        skip === 0 ? result.data.users : [...this.organisationUsers, ...result.data.users];
                })
                .catch(err => {
                    this.usersLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        getOrganisationMinimal() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            return this.$apollo
                .query({
                    query: ORGANISATION_USERS_MINIMAL,
                    variables: {
                        organisationId
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(result => {
                    this.organisationLoading = false;
                    this.organisationUsersMinimal = result.data.organisation.users;
                    this.globalTwofactorEnabled =
                        result.data.organisation.settings && result.data.organisation.settings.login
                            ? result.data.organisation.settings.login.twofactor
                            : false;
                })
                .catch(err => {
                    this.organisationLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        searchForInvitedUsers(input) {
            this.searchInvitedUsers = input.toLowerCase();
        },
        searchForActiveUsers(input) {
            this.searchActiveUsers = input.toLowerCase();
        }
    },
    computed: {
        users() {
            const vm = this;

            return this.organisationUsersMinimal.map(orgUser => {
                const userObject = vm.organisationUsers.find(user => user.id === orgUser.userId);
                return { ...userObject, organisation: orgUser };
            });
        },
        acceptedUsers() {
            return this.users.filter(user => {
                const includesEmail = user.email ? user.email.toLowerCase().includes(this.searchActiveUsers) : false
                const includesFirstName = user.firstName ? user.firstName.toLowerCase().includes(this.searchActiveUsers) : false
                const includesLastName = user.lastName ? user.lastName.toLowerCase().includes(this.searchActiveUsers) : false
                const includesFullName = user.firstName && user.lastName ? (user.firstName.toLowerCase()+' '+user.lastName.toLowerCase()).includes(this.searchActiveUsers) : false

                return user.organisation.status === 'active' && (includesEmail || includesFirstName || includesLastName || includesFullName);
            });
        },
        requestedAndInvitedUsers() {
            const users = this.users.filter(user => {
                if (!['invited','requested','remote_added','remote_invited'].includes(user.organisation.status)) return false;
                if (this.searchInvitedUsers === '') return true;

                const includesEmail = user.organisation.email ? user.organisation.email.toLowerCase().includes(this.searchInvitedUsers) : false
                const includesFirstName = user.firstName ? user.firstName.toLowerCase().includes(this.searchInvitedUsers) : false
                const includesLastName = user.lastName ? user.lastName.toLowerCase().includes(this.searchInvitedUsers) : false
                const includesFullName = user.firstName && user.lastName ? (user.firstName.toLowerCase()+' '+user.lastName.toLowerCase()).includes(this.searchInvitedUsers) : false
                return (includesEmail || includesFirstName || includesLastName || includesFullName);
            });

            return users;
        },
        loading() {
            return this.usersLoading || this.organisationLoading;
        },
        hybridModeActive: function() {
            return this.$store.getters.hasActiveHybridMode;
        }
    },
    created() {
        this.usersLoading = true;
        this.fetch();
        this.getOrganisationMinimal();
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

span {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.ellipsis {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.divider {
    margin-left: -3px;
}

.no-padding {
    padding: 0 !important;
}

.account-intro {
    padding: 40px 40px 0px 40px;
}

.account-intro > p {
    margin-top: 15px;
}

.row > .label > p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
}

.fixed-width p {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fixed-width {
    padding-left: 40px;
    height: 28px;
    display: flex;
    align-items: center;
}

// .fixed-width > div {
//     display: flex;
//     align-items: center;
// }

.end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.input-margin {
    margin: -1px 0px 0px -7px;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 150ms;
}

.cancel-button {
    margin-right: 8px;
}

.panel {
    max-width: 900px;
    width: 100%;
    // padding-bottom: 10px;
    margin-bottom: 40px;

    &.shared {
        .head {
            padding: 40px;
        }
    }

    & .padding-left {
        padding-left: 40px;
    }

    & .padding-right {
        padding-right: 40px;
    }

    .action-button {
        display: flex;
    }

    .avatar-row {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: -3px;
    }
}

.cancel-request {
    margin-bottom: -6px;
    margin-left: 16px;
    color: $color-grey-5;
    transition: color 200ms ease;

    &:hover {
        cursor: pointer;
        color: $color-red;
    }
}

* {
    font-family: Gotham;
}
</style>
