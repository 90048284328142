<template>
    <div class="modal">
        <div class="vertical-center">
            <h2>Gebruiker uitnodigen</h2>
        </div>

        <p class="mb-s mt-s">
            Nodig een collega uit door een e-mailadres<br />
            in te voeren en een rol te kiezen.
        </p>

        <div class="modal-content">
            <q-input labelPosition="inside"
                v-model="user.email"
                :errorMessage="emailErrorMessage"
                :state="emailState"
                inputType="email"
                placeholder="Geef een e-mailadres op"
                >E-mailadres</q-input
            >

            <q-dropdown
                placeholder="bijv. Gebruiker"
                v-if="roles.length > 0"
                v-model="user.roleId"
                :options="roles"
                class="choose-contact-person mt-xs mb-m"
                autocomplete
                >Kies een rol voor deze gebruiker</q-dropdown
            >
        </div>

        <div class="footer">
            <q-button
                @click="close"
                variation="blank"
            >Annuleren</q-button>
            <q-button :disabled="requiredFields || userExists" variation="primary" @click="inviteUser" :loading="createLoading"
                >Gebruiker uitnodigen</q-button
            >
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { validateEmail, extractError } from '@/assets/js/utils';
import { CHECK_EMAIL_AVAILABLE, ORGANISATION_USER_INVITE } from '@/graphql/mutations';
import { ROLES } from '@/graphql/queries';

export default {
    props: {
        users: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            createLoading: false,
            deleteLoading: false,
            rolesLoading: false,
            emailState: '',
            emailErrorMessage: '',
            user: {
                roleId: 'user-default',
                email: null
            },
            emailValid: false,
            roles: []
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        getRoles() {
            this.rolesLoading = true;

            this.$apollo
                .query({
                    query: ROLES,
                    fetchPolicy: 'no-cache'
                })
                .then(res => {
                    this.rolesLoading = false;

                    this.roles = res.data.roles.map(r => {
                        return {
                            value: r.id,
                            label: this.$t(r.name)
                        };
                    });
                })
                .catch(err => {
                    this.rolesLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        async inviteUser() {
            this.createLoading = true;
            const { id } = this.$store.getters.getCurrentOrganisation;
            const { email, roleId } = this.user;

            const validateEmail = (email) => {
                return String(email)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            };

            if (validateEmail(email) === null) {
                this.$store.commit('notify', { message: 'Ongeldig e-mailadres', type: 'danger' });
                this.createLoading = false;
            } else {
                this.$apollo
                    .mutate({
                        mutation: ORGANISATION_USER_INVITE,
                        variables: {
                            id,
                            email,
                            roleId, 
                        }
                    })
                    .then(res => {
                        this.createLoading = false;
                        this.$store.commit('notify', {
                            type: 'success',
                            message: `Gebruiker met e-mailadres ${email} is uitgenodigd`
                        });
                        this.$emit('close');
                    })
                    .catch(err => {
                        this.createLoading = false;
                        this.$store.commit('notify', { message: 'Er ging iets fout tijdens het uitnodigen van deze gebruiker', type: 'danger' });
                    });
            }
        }, 
    },
    computed: {
        requiredFields() {
            return !this.user.email;
        },
        userExists() {
            return this.users.some(user => [user.email, user.organisation?.email].includes(this.user.email));
        }
    },
    created() {
        this.getRoles();
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;

    .modal-content {
        height: 255px;
    }

    .footer {
        display: flex; 
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.permission-wrapper {
    display: flex;
    margin: 40px 0px 90px;
    min-height: 100px;

    .subhead {
        font-weight: 500;
        font-size: 1.1rem;
    }

    .left {
        width: 35%;
        min-width: 200px;
        border-right: 1px solid rgb(222, 226, 230);

        .category-item {
            padding: 4px 8px;
            border: 1px solid white;
            border-radius: 4px;
            margin-right: 24px;
            margin-left: -8px;
            // margin-bottom: 8px;

            &:hover {
                border-color: rgb(222, 226, 230);
                cursor: pointer;
            }

            .active {
                color: $color-primary;
                font-weight: 500;
            }

            .count {
                color: $color-grey-5;
            }
        }
    }

    .right {
        padding: 0px 40px;
        width: calc(100% - 35%);
        max-height: 250px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $color-white;
            border-radius: 4px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $color-grey-3;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $color-grey-5;
        }
    }
}

.red-hover :hover {
    color: $color-red;
    transition: color 200ms ease;
}
</style>
