<template>
    <div class="form">
        <div v-if="form && !loadingForm">
            <q-pageflow
                :value="getFormComponent.simplifiedStatus"
                :title="form.name"
                :back="'/projects/' + this.form.projectId"
                paddingLeft="40px"
                :pageflowBackground="pageflowBackground"
                class="pageflow"
            >
                <template v-slot:items>
                    <q-pageitem value="filling" :type="getPageItemType('startFilling')" class="first-page-item">
                        <span class="label">Invullen</span>
                    </q-pageitem>
                    <q-pageitem value="waitingForCompanionFill" :type="getPageItemType('waitingForCompanionFill')">
                        <span class="label">Vastgesteld</span>

                        <q-pill :values="getFilledStatusValues" size="small"></q-pill>
                    </q-pageitem>
                    <q-pageitem value="discussion" :type="getPageItemType('discussion')">
                        <span class="label">Gesprek</span>
                    </q-pageitem>
                    <q-pageitem
                        v-if="showRevisionTab"
                        :type="getPageItemType('revision')"
                        value="revision"
                    >
                        <span class="label">Revisie</span>
                    </q-pageitem>
                    <q-pageitem
                        value="agreement"
                        :type="getPageItemType('agreement')"
                    >
                        <span class="label">Akkoord</span>

                        <q-pill :values="getAgreedStatusValues" size="small"></q-pill>
                    </q-pageitem>
                    <q-pageitem value="finished" :type="getPageItemType('finished')" noArrow>
                        <span class="label"
                            >Formulier afgerond
                            <span v-if="getFormComponent.status === 'finished'">op {{ getFormFinishedDate }}</span>
                        </span>
                    </q-pageitem>
                </template>
                <template v-slot:end>
                    <q-button
                        :loading="downloadLoading"
                        icon="Download"
                        variation="ghost"
                        size="small"
                        class="download-button"
                        @click="exportForm"
                        >Download PDF</q-button
                    >
                </template>
            </q-pageflow>

            <div
                v-if="
                    (form.name === 'Tussentijdse meting' || 
                    (organisationType === 'client' && form.name === 'Eindmeting')) && 
                    getFourteenDays && 
                    form.status === 'completed' && 
                    (form.companions[0].status === 'open' || form.companions[0].status === 'concept')
                "
                class="finish-form"
            >
                <q-tooltip position="bottom">
                    <template v-slot:tooltip>
                        <p>Je kunt deze meting nu enkelzijdig afronden omdat de {{  organisationType === 'client' ? 'opdrachtnemer' : 'opdrachtgever' }}</p>
                        <p>in de afgelopen 14 dagen geen reactie heeft gegeven op dit formulier</p>
                    </template>
                    <q-icon type="QuestionCircle"></q-icon>
                </q-tooltip>

                <q-button
                    v-if="form.name === 'Tussentijdse meting'"
                    size="small"
                    @click="handleFinishIntermediateMeasurement"
                    :loading="agreeButtonLoading"
                    >Enkelzijdig afronden tussentijdse meting</q-button
                >
                <q-button
                    v-else-if="form.name === 'Eindmeting'"
                    size="small"
                    @click="handleFinishFinalMeasurement"
                    :loading="agreeButtonLoading"
                    >Enkelzijdig afronden eindmeting</q-button
                >
            </div>

            <component
                v-if="projectJwt"
                class="page-padding"
                :is="getFormComponent.component"
                :form="form"
                :status="getFormComponent.status"
                :isCrowForm="form.project.crowMeta != null"
                :projectJwt="projectJwt"
                :incompleteAnswers="incompleteAnswers"
                :nvtAnswers="nvtAnswers"
                :invalidMessage="invalidMessage"
                @componentUpdated="handleComponentUpdated"
                @reload="reload"
            ></component>
        </div>
        <jump-transition v-else>
            <div class="center">
                <div class="loader"></div>
            </div>
        </jump-transition>
    </div>
</template>

<script>
/**
 * Import for every stage the form can have
 *
 *
 * CROW flow:
 *
 * 1. Invullen (Fill.vue)
 * 2. Vastgesteld (Overview.vue)
 * 3. Gesprek (Overview.vue)
 * 4. Akkoord (Overview.vue)
 * 5. Formulier afgerond (Overview.vue)
 */
import Fill from './Fill';
import Overview from './Overview';

import { ACCESS_PROJECT, GET_FORM, GET_PROJECT } from '../../../graphql/queries';
import { AGREE_FORM, ADMIN_AGREE_FORM } from '../../../graphql/mutations';
import { extractError, getDate, crowExportFileName } from '@/assets/js/utils';

import axios from 'axios';
import download from 'downloadjs';
import gql from 'graphql-tag';

const FAKE_COMPANION = {
    finishedDate: Date.now(),
    id: '',
    name: '',
    project: {},
    projectId: 'id',
    responses: [{ values: [] }],
    status: 'finished',
    organisation: { name: '' },
    loadingForm: true,
    components: []
};

export default {
    name: 'FormPage',
    /**
     * These component registrations need to match the 'current' attribute which the pageflow v-models
     */
    components: {
        fill: Fill,
        overview: Overview,
    },
    data() {
        return {
            dateInFourteenDays: null,
            finishedDate: null,
            agreeButtonLoading: false,
            form: null,
            downloadLoading: false,
            downloadUrl: process.env.API_GATEWAY_URI || 'http://localhost:5050',
            projectJwt: undefined
        };
    },
    methods: {
        async handleFinishIntermediateMeasurement() {
            this.agreeButtonLoading = true;

            try {
                // agree own score (companion form)
                await this.$apollo
                    .mutate({
                        mutation: AGREE_FORM,
                        variables: { 
                            id: this.form.companions[0].id 
                        }
                    })
                // agree companion score (own form)
                await this.$apollo
                    .mutate({
                        mutation: AGREE_FORM,
                        variables: { 
                            id: this.form.id 
                        }
                    })

                this.$store.commit('notify', {
                    type: 'success',
                    message: 'Akkoord gegeven',
                });

                this.agreeButtonLoading = false;
                this.getForm();
            } catch (error) {
                console.log(error);
                this.$store.commit('notify', {
                    type: 'danger',
                    message: 'Er ging iets fout tijdens het afronden van de meting',
                });
            }
        },
        async handleFinishFinalMeasurement() {
            this.agreeButtonLoading = true;

            try {
                // agree own score (companion form)
                await this.$apollo
                    .mutate({
                        mutation: AGREE_FORM,
                        variables: { 
                            id: this.form.companions[0].id 
                        }
                    })
                // agree companion score (own form)
                await this.$apollo
                    .mutate({
                        mutation: AGREE_FORM,
                        variables: { 
                            id: this.form.id 
                        }
                    })
                // crow akkoord
                await this.$apollo
                    .mutate({
                        mutation: ADMIN_AGREE_FORM,
                        variables: { 
                            id: this.form.id
                        },
                    })

                this.$store.commit('notify', {
                    type: 'success',
                    message: 'Eindmeting succesvol afgerond',
                });
                this.agreeButtonLoading = false;
                this.getForm();
            } catch (error) {
                this.$store.commit('notify', {
                    type: 'danger',
                    message: 'Er ging iets fout tijdens het enkelzijdig afronden',
                });
                console.log(error);
            }
        },
        fillQuestionAnswer(component, values) {
            if (component.type !== 'question') return component

            const value = values.find((value) => value.componentId === component.id);

            if (value) {
                component.indicator.answer = value.value;
                component.indicator.satisfied = value.satisfied || '';
                component.indicator.improvement = value.improvement || '';
                component.indicator.agreement = value.agreement || '';
            } else {
                component.indicator.answer = undefined;
                component.indicator.satisfied = '';
                component.indicator.improvement = '';
                component.indicator.agreement = '';
            }

            return component
        },
        /**
         * Setting up the form object as queried from the api-gateway into an object we can easily use.
         *
         * This method currently only support Single Value Past performance type forms
         */
        setForm(form) {
            if (form.companions) {
                for (let i = 0; i < form.companions.length; i++) form.companions[i] = this.setForm(form.companions[i]);
            }

            console.log(`%cform ${form.id} found`, 'color:lightgreen');

            if (form.responses.length === 0) {
                form.responseId = undefined;
                return form;
            }

            

            form.responseId = form.responses[0].id;
            const values = form.responses[0].values;

            form.components.map(component => {
                component = this.fillQuestionAnswer(component, values);
                if(component.indicator) component.indicator.answerValues = component.indicator.answerValues.sort((a, b) => b.value - a.value); // sort answer negative to positive
            })

            return form;
        },
        getProject() {
            this.$apollo.query({
                query: gql`
                    query AccessProject($id: String!) {
                        project_access(id: $id) {
                            jwt
                            project {
                                id
                                name
                            }
                        }
                    }
                `,
                variables: {
                    id: this.form.projectId
                }
            })
            .then(result => {
                this.projectJwt = result.data.project_access.jwt;
                
                this.setJwt(result.data.project_access.jwt, this.form.projectId);
            })
        },
        setJwt(jwt, projectId) {
            const projectJwt = {
                id: projectId,
                jwt
            }
            localStorage.setItem('projectJwt', JSON.stringify(projectJwt))

            this.projectJwt = jwt;
        },
        getForm() {
            Date.prototype.addDays = function (days) {
                let date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            };

            const id = this.$route.params.id;
            const vm = this;
            this.loadingForm = true;

            this.$apollo
                .query({
                    query: GET_FORM,
                    variables: { id },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    vm.loadingForm = false;
                    const form = response.data.form;

                    if (form.companions.length === 0) form.companions = [FAKE_COMPANION];

                    this.form = this.setForm(form);

                    let timestamp = this.form.finishedDate;

                    this.dateInFourteenDays = timestamp + 1209600000 // 14 days in ms

                    document.getElementById('content-overflow').scrollTo(0,0);

                    this.updateUserRoles();
                    this.getProject();
                })
                .catch(() => {
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Er ging iets fout tijdens het ophalen van dit formulier.',
                    });
                    this.$router.push('/projects');
                });
        },
        getPageItemType(status) {
            if (this.form.companions.length === 0 || this.form.status === 'finished') return { status: 'succes' };

            const active = this.getFormComponent.simplifiedStatus;

            const statusses = [
                'concept',
                'startFilling',
                'waitingForCompanionToStart',
                'filling',
                'waitingForCompanionFill',
                'discussion',
                'agreement',
                'waitingForCrowAdmin',
                'finished',
            ];
            
            const yourKey = statusses.indexOf(status);
            const activeKey = statusses.indexOf(active);

            /**
             * if the form is not agreed the tab 'agreement' turns red
             */
            if (
                status === 'agreement' &&
                activeKey >= yourKey
            ) {
                if(this.form.status === 'disagreed' && this.form.companions[0].status === 'disagreed') 
                    return {
                        status: '',
                    }

                if(this.form.status === 'pending_crow_admin' && this.form.companions[0].status === 'pending_crow_admin')
                    return {
                        status: 'succes'
                    }
            }
            /**
             * if the revision tab is shown, calculate the status
             */
            else if (status === 'revision' && activeKey >= yourKey) {
                const inRevision = (this.form.status === 'disagreed' || this.form.companions[0].status === 'disagreed')

                return { 
                    status: inRevision ? '' : 'succes' 
                }
            } 

            /**
             * Generally, if the active tab is this one or after this one, the color is green. else is white
             */
            return activeKey > yourKey
                ? {
                      status: 'succes',
                  }
                : {
                      status: 'not_done',
                  };
        },
        exportForm() {
            this.downloadLoading = true;

            let fileName = crowExportFileName(this.form);

            const formIds = [this.form.id, this.form.companions[0].id];

            const headers = {
                'application-context': 'qfact',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            };

            axios
                .get(`${this.downloadUrl}/download/export/crow_forms_export/${formIds[0]}/${formIds[1]}`, {
                    headers,
                    responseType: 'blob',
                })
                .then((response) => {
                    const content = response.headers['content-type'];
                    download(response.data, fileName, content);
                    this.downloadLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.commit('notify', extractError(error));
                    this.downloadLoading = false;
                });
        },
        getReportDownload(fileName) {
            window.open(`${process.env.DOWNLOAD_REPORT_URL}/${fileName}`);
        },
        updateUserRoles() {
            const organisation = this.$store.getters.getCurrentOrganisation;
            
            let members = this.form.project.members || [];
            let organisationAdmins = organisation.users.filter(user => user.roleId === 'admin-default')
            organisationAdmins = organisationAdmins.map(user => {
                return { userId: user.userId, organisationId: organisation.id, roles: ['admin'] }
            })

            members = members.concat(organisationAdmins);

            const user = this.$store.getters.getUser;
            const member = members.find(member => member.userId === user.id && member.organisationId === organisation.id);
            const isGeustOrganisation = organisation.id !== this.form.project.client.id && organisation.id !== this.form.project.contractor.id;

            this.projectAbility.set(this.form.projectId, member, this.ability, isGeustOrganisation);
        },
        handleComponentUpdated(component) {
            this.form.components = this.form.components.map(formComponent => {
                if(formComponent.id !== component.id) return formComponent
                return component
            });
        },
        getInvalidTooltip(nvtAnswers = [], incompleteAnswers = [], totalAnswers = []) {
            if(nvtAnswers.length <= 2 && incompleteAnswers.length === 0) return null

            if(nvtAnswers.length > 2) return `Er zijn te veel vragen met 'Niet van toepassing' beantwoord`

            if(incompleteAnswers.length > 0) return this.getNotifyText(incompleteAnswers, totalAnswers)
        },
        getNotifyText(incompleteAnswers = [], totalAnswers = []) {
            const incompleteAnswerIndexes = incompleteAnswers.map(answer => answer.questionIndex);
            let prefix = 'Feedback ontbreekt ';
            let answers = '';

            if (incompleteAnswerIndexes.length === 1) answers = `bij vraag ${incompleteAnswerIndexes[0]} `;
            else if (incompleteAnswerIndexes.length === 2) answers = `bij vraag ${incompleteAnswerIndexes.join(' en ')} `;
            else if (incompleteAnswerIndexes.length < 6) 
                answers = `bij vragen ${incompleteAnswerIndexes.slice(0, -1).join(', ')} en ${incompleteAnswerIndexes.slice(-1)} `;
            else if (incompleteAnswerIndexes.length !== totalAnswers.length)
                answers = ' bij bijna alle vragen '
            else answers = ' om vast te kunnen stellen'

            return prefix + answers;
        },
        reload() {
            this.getForm();
        }
    },
    computed: {
        answers: function() {
            if(!this.form) return []
            const questions = this.form.components.filter(component => component.type !== 'content');
            const answers = questions.map((component, index) => {
                return {
                    satisfied: component.indicator.satisfied,
                    improvement: component.indicator.improvement,
                    value: component.indicator.answer,
                    questionIndex: index+1
                }
            });

            return answers
        },
        nvtAnswers: function() {
            const nvtAnswers = this.answers.filter(answer => !isNaN(answer.value) && answer.value === 0);
            return nvtAnswers
        },
        incompleteAnswers: function() {
            const incompleteAnswers = this.answers.filter(answer => {
                return answer.value !== 0 && (!answer.improvement || !answer.satisfied)
            });

            return incompleteAnswers
        },
        invalidMessage: function() {
            return this.getInvalidTooltip(this.nvtAnswers, this.incompleteAnswers, this.answers)
        },
        /**
         * Uses the method in formStatus method in ./utils.js to determine at what stage the form is
         */
        getFormComponent: function () {
            const { formStatus } = require('./utils');
            const component = formStatus(this.form);

            let simplifiedStatus = component.status;
            if(['discussion','revision','waitingForCompanionDiscussion','waitingForCompanionRevision','waitingForCrowAdmin'].includes(simplifiedStatus)) simplifiedStatus = 'agreement';

            component.simplifiedStatus = simplifiedStatus;

            return component
        },
        /**
         * Return values for the q-pill indicating if the form or companion form has been filled.
         * contractor is always left
         */
        getFilledStatusValues: function () {
            const organisationType = this.organisationType;

            const youFilled = !['open', 'concept'].includes(this.form.status);
            const companionFilled =
                this.form.companions.length > 0 && !['open', 'concept'].includes(this.form.companions[0].status);

            const yourLabel = organisationType === 'contractor' ? 'Opdrachtnemer' : 'Opdrachtgever';
            const companionLabel = organisationType === 'contractor' ? 'Opdrachtgever' : 'Opdrachtnemer';

            return [
                {
                    text: organisationType === 'contractor' ? 'ON' : 'OG',
                    color: youFilled ? '#37b24e50' : '#adb5bd49',
                    tooltip: youFilled ? null : `${yourLabel} heeft nog niet vastgesteld`,
                },
                {
                    text: organisationType === 'contractor' ? 'OG' : 'ON',
                    color: companionFilled ? '#37b24e50' : '#adb5bd49',
                    tooltip: companionFilled ? null : `${companionLabel} heeft nog niet vastgesteld`,
                },
            ];
        },
        /**
         * Return values for the q-pill indicating if the form or companion form has been agreed,
         * both the status 'agreed' and 'finished' means its been approved
         * 'disagreed' means not approved and starts the revision
         *
         * contractor is always left
         */
        getAgreedStatusValues: function () {
            const organisationType = this.$store.getters.getOrganisationType;

            const youAgreed = ['agreed', 'pending_crow_admin', 'finished'].includes(this.form.status);
            const youDisagreed =  ['disagreed','crow_admin_disagreed'].includes(this.form.status);
            const companionAgreed =
                this.form.companions.length > 0 && ['agreed', 'pending_crow_admin', 'finished'].includes(this.form.companions[0].status);
            const companionDisagreed =
                this.form.companions.length > 0 && ['disagreed','crow_admin_disagreed'].includes(this.form.companions[0].status);;

            const ORANGE = 'rgb(254, 191, 139)';
            const GREEN = '#37b24e50';
            const GREY = '#adb5bd49';

            const yourLabel = organisationType === 'contractor' 
                ? 'Opdrachtnemer' 
                : this.form.status === 'crow_admin_disagreed' 
                    ? 'CROW Beheerder'
                    : 'Opdrachtgever';
            const companionLabel = organisationType === 'contractor' 
                ? this.form.companions[0].status === 'crow_admin_disagreed' 
                    ? 'CROW Beheerder'
                    : 'Opdrachtgever' 
                : 'Opdrachtnemer';

            return [
                {
                    text: organisationType === 'contractor' ? 'ON' : 'OG',
                    color: youAgreed ? GREEN : youDisagreed ? ORANGE : GREY,
                    tooltip: youAgreed
                        ? null
                        : youDisagreed
                        ? `${yourLabel} is niet akkoord`
                        : `${yourLabel} is nog niet akkoord`,
                },
                {
                    text: organisationType === 'contractor' ? 'OG' : 'ON',
                    color: companionAgreed ? GREEN : companionDisagreed ? ORANGE : GREY,
                    tooltip: companionAgreed
                        ? null
                        : companionDisagreed
                        ? `${companionLabel} is niet akkoord`
                        : `${companionLabel} is nog niet akkoord`,
                },
            ];
        },
        getFormFinishedDate: function () {
            let { finishedDate } = this.form;

            /**
             * if no finishedDate exists it means the form has been finished in this session and has not been updated
             * We can assume finished date is today.
             */
            if (!finishedDate) {
                finishedDate = Date.now();
            }

            return getDate(finishedDate);
        },
        pageflowBackground: function () {
            return this.getFormComponent.status === 'finished' ? 'rgb(240, 249, 241)' : 'transparent';
        },
        /**
         * Calculate if the finished date + 14 days of the form is gt 14 days.
         */
        getFourteenDays: function () {
            let dateInFourteenDays = this.dateInFourteenDays;
            let dateToday = Date.now();

            return dateToday >= dateInFourteenDays;

            // use this line of code to test if button works
            // return dateToday >= dateToday;
        },
        showRevisionTab: function() {
            const hasBeenDisagreed = this.form.hasBeenDisagreed || this.form.companions[0].hasBeenDisagreed;
            return hasBeenDisagreed
        },
        organisationType: function() {
            return this.$store.getters.getOrganisationType;
        }
    },
    created() {
        this.getForm();
    },
    beforeDestroy() {
        this.projectAbility.remove();
        localStorage.removeItem('projectJwt')
    },
    watch: {
        '$route.path': function() {
            this.loadingForm = true;
            this.form = null;
            this.reload();
        }
    }
};
</script>

<style lang="scss" scoped>

.form {
    // max-height: calc(100vh - 81px);
    display: flex;
    flex-direction: column;

    .pageflow {

    }
    .page-padding {
        // overflow-y: auto;
    }
}
.check {
    margin-right: 4px;
    color: rgb(240, 62, 62);
}

.first-page-item {
    padding-left: 40px !important;
}

.q-pageitem > .label {
    margin: 0 16px;
}

.download-button {
    margin: 0 50px 0 0;
    z-index: 100;
}

.center {
    position: absolute;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    $loader-color: #d1d5da;
    $loader-size: 100px;
    $loader-height: 14px;
    $loader-border-size: 5px;
    $loader-gap: 20px;
    $loader-animation-duration: 700ms;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}
.download-loader {
    $loader-color: #d1d5da;
    $loader-size: 100px;
    $loader-height: 14px;
    $loader-border-size: 5px;
    $loader-gap: 20px;
    $loader-animation-duration: 700ms;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}

.finish-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin: 35px 50px 0 0;
}
</style>
