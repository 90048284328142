<template>
  <div class="rsx-answer-reference">
    <rsx-table class="rsx-table" :specificationPosts="specificationPosts"></rsx-table>
    <div class="about">
        <div class="name">
            Resultaat gevonden in: <span class="document-link" @click="openSource">{{ documentName }}</span>
        </div>
        <div class="user-name">
            Geüpload door {{ documentUserName }}
        </div>
    </div>
  </div>
</template>

<script>
import RsxTable from './RsxTable.vue'
export default {
    name: 'rsx-answer-reference',
    components: { RsxTable },
    props: {
        documentId: {
            type: String,
            required: true
        },
        documentName: {
            type: String,
            required: true
        },
        documentUserName: {
            type: String,
            required: true
        },
        specificationPosts: {
            type: Array,
            required: true,
            validator: (value) => {
                return value.every((specificationPost) => {
                    return specificationPost.hasOwnProperty('specificationsPostNumber') 
                    && specificationPost.hasOwnProperty('unit') 
                    && specificationPost.hasOwnProperty('value') 
                    && specificationPost.hasOwnProperty('description')
                })
            }
        },
    },
    methods: {
        openSource() {
            this.documentViewerController.OpenDocumentViewer(this.documentId)
        }
    }
}
</script>

<style scoped lang="scss">

.rsx-answer-reference {
    margin: 20px 0 20px 0;
}


* {
    font-size: 9px;
}

.document-link {
    font-weight: bold;
    font-size: 9px;
    transition: 100ms;
}

.document-link:hover {
    cursor: pointer;
    text-decoration: underline;
    transition: 100ms;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2px;

    .name, .user-name {
        font-size: 9px;
        line-height: 1.5;
    }

    .user-name {
        color: grey;
    }
}

.rsx-table {
    margin: -20px 0 0 0;
}

</style>