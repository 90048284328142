<template>
    <div class="modal">
        <div class="vertical-center">
            <h2>{{ isNewRole ? 'Rol toevoegen' : defaultRole ? 'Rol bekijken' : 'Rol bijwerken' }}</h2>
            <div>
                <q-tag v-if="defaultRole" style="margin-left: 16px;" size="small" variation="default"
                    >default rol</q-tag
                >
            </div>
        </div>
        <p class="mb-m mt-s">
            Maak hier een rol aan door een naam in te voeren<br />
            en een aantal permissies te selecteren.
        </p>

        <div class="modal-content">
            <q-input labelPosition="inside"
                :disabled="defaultRole"
                v-model="role.name"
                placeholder="Geef de rol een naam. (e.g. Vestigingsmanager)"
                >Naam</q-input
            >

            <div v-if="!permissionsLoading" class="permission-wrapper">
                <div class="left">
                    <p class="subhead mb-s">Kies een categorie</p>

                    <div
                        v-for="(category, key) in permissions"
                        :key="key"
                        @click="currentCategory = category.type"
                        class="category-item space-between"
                    >
                        <p :class="{ active: category.type === currentCategory }">
                            {{ $t(`roles.${category.type}.categoryTitle`) }}
                        </p>
                        <p class="count">{{ getPermissionCount(category.type) }}</p>
                    </div>
                </div>
                <div class="right">
                    <p class="subhead mb-xxs">{{ $t(`roles.${activeCategory.type}.categoryTitle`) }}</p>
                    <p class="mb-m">{{ $t(`roles.${activeCategory.type}.categoryDescription`) }}</p>

                    <div v-for="permission in activeCategory.permissions" :key="permission">
                        <q-tooltip :disabled="$t(`roles.${activeCategory.type}.${permission}.description`) === ''">
                            <template #tooltip>
                                <span>{{ $t(`roles.${activeCategory.type}.${permission}.description`) }}</span>
                            </template>
                            <q-checkbox
                                :disabled="defaultRole"
                                v-model="role.permissions[currentCategory][permission]"
                                class="mb-s"
                                >{{ $t(`roles.${activeCategory.type}.${permission}.label`) }}</q-checkbox
                            >
                        </q-tooltip>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="footer space-between"
            :class="{ spaceBetween: isNewRole || defaultRole || !ability.get().can('delete', 'Role') }"
        >
            <q-button
                v-if="!isNewRole && !defaultRole && ability.get().can('delete', 'Role')"
                variation="blank"
                class="red-hover"
                @click="handleDeleteRole"
                :loading="deleteLoading"
                >Verwijderen</q-button
            >
            <div v-else></div>
            
            <div class="save-buttons">
                <q-button
                    v-if="!isNewRole && ability.get().can('create', 'Role')"
                    variation="blank"
                    @click="duplicateRole"
                    style="margin-right: 24px;"
                    >Rol dupliceren</q-button
                >
                <q-button
                    :disabled="defaultRole || !canCreateOrUpdate(isNewRole)"
                    variation="primary"
                    @click="submitRole"
                    :loading="createLoading"
                    >{{ isNewRole ? 'Rol toevoegen' : 'Rol bijwerken' }}</q-button
                >
            </div>
        </div>
        <q-popup v-if="confirmDeleteRole">
            <div>
                <confirm-delete-modal :deleteMessage="`Weet u zeker dat u de rol ${role.name} ${role.name.length > 25 ? '<br>' : ''}wilt <b>verwijderen</b>?`" cancelButtonText="Annuleren" confirmButtonText="Rol verwijderen" @close="closePopup()" @confirm="deleteRole"></confirm-delete-modal>
            </div>
        </q-popup>
    </div>
</template>

<script>
import { capitalize, extractError } from '@/assets/js/utils';
import { CREATE_ROLE, UPDATE_ROLE, DELETE_ROLE } from '@/graphql/mutations';
import { ORG_SETTINGS, ROLES, PERMISSIONS, GET_ORGANISATION_USERS } from '@/graphql/queries';
import confirmDeleteModal from './confirmDeleteModal';

export default {
    props: ['editRole', 'defaultRole'],
    components: { confirmDeleteModal },
    data() {
        return {
            permissions: [],
            permissionsLoading: true,
            createLoading: false,
            deleteLoading: false,
            currentCategory: 'user',
            role: {
                permissions: {}
            },
            confirmDeleteRole: false,
            organisationUsers: [],
            hasUserRole: false
        };
    },
    methods: {
        canCreateOrUpdate(isNewRole) {
            const roleType = isNewRole ? 'create' : 'update';
            return this.ability.get().can(roleType, 'Role');
        },
        getPermissions() {
            this.permissionsLoading = true;

            this.$apollo
                .query({
                    query: PERMISSIONS,
                    variables: {}
                })
                .then(res => {
                    const permissions = res.data.permissions;
                    this.setRoleObject(permissions);
                    this.permissions = permissions;
                    this.permissionsLoading = false;
                })
                .catch(err => {
                    this.permissionsLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        submitRole() {
            return this.isNewRole ? this.createRole() : this.updateRole();
        },
        createRole() {
            this.createLoading = true;
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const role = this.prepareRole(this.role);

            this.$apollo
                .mutate({
                    mutation: CREATE_ROLE,
                    variables: {
                        organisationId,
                        role
                    }
                })
                .then(res => {
                    this.createLoading = false;
                    this.$store.commit('notify', {type: 'success', message: 'Rol is succesvol toegevoegd.'})
                    this.$emit('close');
                })
                .catch(err => {
                    this.createLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        updateRole() {
            this.createLoading = true;
            const { id, name, permissions } = this.prepareRole(this.role);

            this.$apollo
                .mutate({
                    mutation: UPDATE_ROLE,
                    variables: {
                        id,
                        name,
                        permissions
                    }
                })
                .then(res => {
                    this.createLoading = false;
                    this.$emit('close');
                })
                .catch(err => {
                    this.createLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        handleDeleteRole() {
            this.confirmDeleteRole = true
        },
        closePopup() {
            this.confirmDeleteRole = false
        },
        closePopupModal() {
            this.$emit('close');
        },
        getUsers() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id
            this.$apollo 
                .query({
                    query: GET_ORGANISATION_USERS, 
                    variables: {
                        organisationId: organisationId,
                    }
                })
                .then(response => {
                   this.organisationUsers = response.data.organisation.users;
                })
                .catch(err => {
                    console.log(err);
                })
        },  
        deleteRole() {
            this.deleteLoading = true;
            const { id } = this.role;

            this.organisationUsers.forEach((user) => {
                if (user.role.name === this.role.name) {
                    this.$store.commit('notify', {type: 'danger', message: 'Er zijn nog gebruikers met deze rol.'})
                    this.hasUserRole = true;
                    this.deleteLoading = false;
                    this.confirmDeleteRole = true;
                    
                } 
            })
            if (this.hasUserRole === true) {
                return;
            } else {
                this.$apollo
                    .mutate({
                        mutation: DELETE_ROLE,
                        variables: {
                            id
                        }
                    })
                    .then(res => {
                        this.deleteLoading = false;
                        this.$store.commit('notify', {type: 'success', message: 'Rol is succesvol verwijderd.'})
                        this.$emit('close');
                    })
                    .catch(err => {
                        console.log(err);
                        this.deleteLoading = false;
                        // this.$store.commit('notify', extractError(err));
                    });
                }
        },
        prepareRole({ id, name, permissions }) {
            const result = { id, name, permissions: [] };

            Object.keys(permissions).forEach(type => {
                const setPermissions = [];
                Object.keys(permissions[type]).forEach(p => (permissions[type][p] ? setPermissions.push(p) : null));
                result.permissions.push({ type, permissions: setPermissions });
            });

            return result;
        },
        setRoleObject(permissions) {
            const result = {};
            if (permissions.length > 0) {
                const categories = permissions.forEach(p => {
                    result[p.type] = {};
                });
            }
            this.role.permissions = { ...result, ...this.role.permissions };
        },
        getPermissionCount(category) {
            let count = 0;
            const context = this.permissions.find(context => context.type === category);
            if(context) context.permissions.forEach(p => {
                if (this.role.permissions[category][p]) count++;
            });
            return count;
        },
        parseRole(editRole) {
            const result = { ...editRole };

            result.name = this.defaultRole ? this.$t(editRole.name) : editRole.name;
            result.permissions = {};

            editRole.permissions.forEach(p => {
                const permissionObject = {};
                p.permissions.forEach(permission => {
                    permissionObject[permission] = true;
                });
                result.permissions[p.type] = permissionObject;
            });

            return result;
        },
        duplicateRole() {
            this.role.id = null;
            delete this.role.id;
            this.role.name = `Kopie van ${this.role.name}`;
            this.defaultRole = false;
        },
        capitalize(string) {
            return capitalize(string);
        }
    },
    computed: {
        activeCategory() {
            return this.permissions ? this.permissions.find(p => p.type === this.currentCategory) || {} : {};
        },
        isNewRole() {
            return !this.role.id;
        },
        organisationType() {
            return this.$store.getters.getOrganisationType
        }
    },
    created() {
        if (this.editRole) this.role = this.parseRole(this.editRole);
        this.getPermissions();
        this.getUsers();
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.modal {
    width: 550px;
    display: flex;
    flex-direction: column;

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);

        .save-buttons {
            display: flex;
        }

    }
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.permission-wrapper {
    display: flex;
    margin: 40px 0px 90px;
    min-height: 100px;

    .subhead {
        font-weight: 500;
        font-size: 1.1rem;
    }

    .left {
        width: 35%;
        min-width: 200px;
        border-right: 1px solid rgb(222, 226, 230);

        .category-item {
            padding: 4px 8px;
            border: 1px solid white;
            border-radius: 4px;
            margin-right: 24px;
            margin-left: -8px;
            // margin-bottom: 8px;

            &:hover {
                border-color: rgb(222, 226, 230);
                cursor: pointer;
            }

            .active {
                color: $color-primary;
                font-weight: 500;
            }

            .count {
                color: $color-grey-5;
            }
        }
    }

    .right {
        padding: 0px 40px;
        width: calc(100% - 35%);
        max-height: 250px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $color-white;
            border-radius: 4px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $color-grey-3;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $color-grey-5;
        }
    }
}

.red-hover :hover {
    color: $color-red;
    transition: color 200ms ease;
}
</style>
