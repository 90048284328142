class DocumentViewerController {
    constructor() {
        this.initialized = false;
        this.ref = null;
    }

    Initialize(ref) {
        this.initialized = true;
        this.ref = ref;
    }

    async OpenDocumentViewer(documentId, options = {}) {
        if (!this.initialized)
            throw new Error('DocumentViewerController not initialized');

        return this.ref.open(documentId, options);
    }
}

export default DocumentViewerController;