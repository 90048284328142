<template>
    <div v-if="visible" class="panel q-card-small other">
        <div class="context-option">
            <div class="context-name">
                <h4 v-html="markedContextName"></h4>
            </div>
            <div v-if="showFilledConversion" class="tag-container">
                <q-tooltip :disabled="!conversion.tooltip" position="top">
                    <template v-slot:tooltip>
                        <p v-html="conversion.tooltip"></p> 
                    </template>
                    <q-tag :variation="conversion.variation" size="small">{{
                        conversion.conversion
                    }}</q-tag>
                </q-tooltip>
            </div>
        </div>

        <div class="buttons">
            <q-button
                v-if="isDuplicating"
                class="cancel-button"
                size="xsmall"
                variation="blank"
                @click="handleCancelIndicatorDuplication"
                >Annuleren</q-button
            >
            <q-button
                v-if="allowGroupingFunctionality"
                :disabled=" (isSelecting && duplicatingComponentIds.length == 0 && !isEditing) || !validName"
                size="xsmall"
                variation="primary"
                @click="handleDuplicateIndicators"
                :loading="loading"
            >
                {{ groupButtonLabel }}
            </q-button>
            <q-options
                v-if="!isDuplicating && (duplicatingEnabled || showVisibilityOption)"
                color="#C4C4C4"
                class="add-indicator-option"
                position="left"
                :options="options"
                @input="handleOptionSelected"
            ></q-options>
        </div>

        <div
            v-for="(listItem, index) in listItems"
            :key="listItem.componentId"
            :class="['indicator', { selected: editing == listItem.componentId, 'is-group': listItem.isGroup }]"
        >
            <div
                v-if="listItem.isGroup"
                class="indicator-group"
                :class="{ collapsed: !openedGroupIds.includes(listItem.groupId) }"
            >
                <q-clear-input 
                    v-if="listItem.isBeingCreated"
                    id="indicator-group-input"
                    v-model="listItem.groupName"
                    @input="validateGroupName"
                    type="text"
                    size="small"
                    placeholder="Vul een groepsnaam in"
                    class="name-input"
                    @enter="handleDuplicateIndicators"
                />
                <div v-else class="group-header">
                    <div class="left">
                        <q-checkbox
                            v-if="(isDuplicating && isSelecting) || listItem.isBeingCreated"
                            class="group-checkbox"
                            :value="getGroupCheckboxValue(listItem)"
                            :disabled="
                                listItem.indicators.filter((indicator) => indicatorIsDisabled(indicator, listItem))
                                    .length === listItem.indicators.length
                            "
                            @input="handleGroupChecked(listItem, $event)"
                        ></q-checkbox>
                        
                        <q-icon
                            v-else
                            class="folder"
                            type="folder"
                            @click="handleGroupToggle(listItem.groupId)"
                        ></q-icon>
                        <p class="group-name" @click="handleGroupToggle(listItem.groupId)" v-html="listItem.groupName"></p>
                        <q-icon
                            type="chevronDown"
                            class="chevron"
                            @click="handleGroupToggle(listItem.groupId)"
                        ></q-icon>
                    </div>
                    <q-options
                        v-if="!isSelecting"
                        class="group-options"
                        :options="groupOptions"
                        color="#C4C4C4"
                        position="left"
                        @input="handleGroupOptionSelected(listItem, $event)"
                    ></q-options>
                </div>
                <div
                    class="indicators-wrapper"
                    :style="`--max-height: ${calculateTotalHeight(listItem.indicators)}; --animation-length: ${
                        listItem.isBeingCreated ? 0 : listItem.indicators.length * animationStagger
                    }s`"
                >
                    <div class="content">
                        <div
                            v-for="(groupedIndicator, index) in listItem.indicators"
                            :key="groupedIndicator.componentId"
                            class="grouped-indicator"
                            :style="`--animation-stagger: ${
                                openedGroupIds.includes(listItem.groupId)
                                    ? index * animationStagger
                                    : (listItem.indicators.length - 1 - index) * animationStagger
                            }s`"
                        >

                            <indicator-answer
                                :indicator="groupedIndicator"
                                :isEditing="editing == groupedIndicator.componentId"
                                :isDuplicating="isDuplicating && isSelecting"
                                :selected="getIndicatorCheckboxValue(groupedIndicator, listItem)"
                                :disabled="(isDuplicating && isSelecting) || !canEditIndicator(groupedIndicator)"
                                :checkboxDisabled="indicatorIsDisabled(groupedIndicator, listItem)"
                                :search="search"
                                :showVisibilityOption="showVisibilityOption"
                                @removeIndicator="removeIndicator"
                                @saveIndicator="onSaveIndicator"
                                @startLoading="onStartLoading"
                                @stopLoading="onStopLoading"
                                @edit="edit"
                                @cancel="cancelEdit"
                                @checkboxUpdated="handleIndicatorChecked(groupedIndicator, $event)"
                                @toggleVisibility="handleToggleIndicatorVisibility"
                                :data-testid="'input-' + groupedIndicator.answerType"
                                :style="`--animation-stagger: ${index * animationStagger}s`"
                                class="indicator-answer"
                            >
                            </indicator-answer>

                        </div>
                    </div>
                </div>
            </div>
            <indicator-answer
                v-else
                :indicator="listItem"
                :isEditing="editing == listItem.componentId"
                :isDuplicating="isDuplicating && isSelecting"
                :selected="getIndicatorCheckboxValue(listItem)"
                :disabled="(isDuplicating && isSelecting) || !canEditIndicator(listItem)"
                :search="search"
                :showVisibilityOption="showVisibilityOption"
                @saveIndicator="onSaveIndicator"
                @startLoading="onStartLoading"
                @stopLoading="onStopLoading"
                @removeIndicator="removeIndicator"
                @edit="edit"
                @cancel="cancelEdit"
                @checkboxUpdated="handleIndicatorChecked(listItem, $event)"
                @toggleVisibility="handleToggleIndicatorVisibility"
                :data-testid="'input-' + listItem.answerType"
                :style="`--animation-stagger: ${
                    isDuplicating ? index * animationStagger : (listItems.length - 1 - index) * animationStagger
                }s`"
            >
            </indicator-answer>
        </div>
        
        <q-popup v-if="deletingGroup" width="50%">
            <div class="popup-delete">
                <div class="popup-header">
                    <h2>Weet je zeker dat je deze groep wilt verwijderen?</h2>
                </div>
                <div class="popup-body">
                    <p>Als je deze groep ({{ deletingGroup.groupName }}) verwijderd worden alle, ook ingevulde, kenmerken in de prestatie verwijderd. Je kunt daarna opnieuw kenmerken samenvoegen en hier een groep van maken. Wil je alleen de groep en niet de reeds ingevulde kenmerken verwijderen? Verplaats deze dan eerst terug naar de prestatie of naar een andere groep vóórdat je de groep verwijderd.</p>
                </div>
                <div class="popup-buttons">
                    <q-button variation="blank" @click="deletingGroup = null">Annuleren</q-button>
                    <q-button variation="danger" @click="handleDeleteGroup(deletingGroup)">Groep verwijderen</q-button>
                </div>
            </div>
        </q-popup>

        <q-popup v-if="showIndicatorAddModal" width="40%">
            <indicator-add-modal @close="closeIndicatorAddModal" @indicatorsAdded="handleIndicatorsAdded" :project="projectProp" :context="context"></indicator-add-modal>
        </q-popup>
    </div>
</template>

<script>
import IndicatorAnswer from './IndicatorAnswer';
import IndicatorAddModal from './IndicatorAddModal.vue';

import { CREATE_INDICATOR_GROUP, DELETE_INDICATOR_GROUP, EDIT_INDICATOR_GROUP } from '../../../graphql/mutations';

import { isValidAnswer } from '@/assets/js/utils.js';

export default {
    name: 'context-card',
    components: {
        'indicator-answer': IndicatorAnswer,
        IndicatorAddModal,
    },
    props: {
        projectProp: {
            type: Object,
            required: true,
        },
        context: {
            type: Object,
            required: true,
        },
        indicators: {
            type: Array,
            required: true,
        },
        allIndicators: {
            type: Array,
            required: true,
        },
        editing: {
            type: String,
            default: '',
        },
        duplicatingEnabled: {
            type: Boolean,
            default: true
        },
        showFilledConversion: {
            type: Boolean,
            default: false
        },
        search: {
            type: String,
            default: ''
        },
        // boolean to show the option to hide the context
        showVisibilityOption: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showIndicatorAddModal: false,
            project: { ...this.projectProp },
            groupOptions: [
                {
                    name: 'Aanpassen',
                },
                {
                    name: 'Verwijderen',
                    color: 'red',
                },
            ],
            isDuplicating: false,
            isEditing: false,
            isSelecting: false,
            validName: true,
            addNewGroup: false,
            duplicatingGroupId: null,
            editingGroupId: null,
            duplicatingComponentIds: [],
            animationStagger: 0.05,
            indicatorGroupName: '',
            openedGroupIds: [],
            cachedGroups: [],
            listItems: [],
            deletingGroup: null,
            loading: false
        };
    },
    methods: {
        validateGroupName(value) {
            const groups = this.getContextGroups();
            const equalNameGroups = groups.filter(group => group.name === value);
            if(equalNameGroups.length > 1) this.validName = false;
            else this.validName = true;
        },
        removeIndicator(indicator) {
            this.$emit('removeIndicator', indicator);
        },
        handleOptionSelected(option) {
            if(option.name === 'Kenmerk toevoegen') {
                this.contextUpdated(this.project);
                this.getListItems();
                this.showIndicatorAddModal = true;
            } else if(option.name.includes('verbergen') ||
                option.name.includes('tonen')) {
                    this.$emit('toggleLabelVisibility', this.context)
                }
        },
        closeIndicatorAddModal() {
            this.showIndicatorAddModal = false;
        },
        handleIndicatorsAdded(project) {
            this.$emit('indicatorsAdded', project, this.context);
        },
        async handleDuplicateIndicators() {
            let groups = this.getContextGroups();
            var group = groups.find((indicator) => indicator.isBeingCreated);

            this.cancelEdit();
            this.$emit('setDuplicating', true);

            if (!this.isDuplicating) {
                this.openedGroupIds = this.listItems
                    .filter((indicator) => indicator.isGroup)
                    .map((group) => group.groupId);
                this.isDuplicating = true;
                this.isSelecting = true;
                this.cachedGroups = groups;
                return
            }

            if (this.isDuplicating && this.isSelecting && !this.editingGroupId) {
                this.isSelecting = false;
                this.isEditing = true;
                this.addNewGroup = true;
                this.listItems = this.getListItems();

                groups = this.getContextGroups();
                group = groups.find((group_) => group_.isBeingCreated);
                this.openedGroupIds = [group.id];
            }
            if (group.name == '') return this.focusInput('indicator-group-input')

            if (this.editingGroupId && this.editingGroupId !== '12345')
                return this.handleEditGroup(group.id, group.name, this.context.id, group.componentIds);

            this.handleCreateGroup(group.name, this.context.id, group.componentIds);
        },
        handleCancelIndicatorDuplication() {
            this.isDuplicating = false;
            this.isEditing = false;
            this.isSelecting = false;
            this.addNewGroup = false;
            this.indicatorGroupName = '';
            this.duplicatingGroupId = '';
            this.openedGroupIds = [];
            this.duplicatingComponentIds = [];
            this.editingGroupId = null;
            this.validName = true;
            this.project.references.groups = this.cachedGroups.filter((group) => !group.isBeingCreated);
            this.$emit('setDuplicating', false);

            this.listItems = this.getListItems();
        },
        handleDeleteGroup(group) {
            this.loading = true;
            this.deletingGroup = null;
            this.$apollo
                .mutate({
                    mutation: DELETE_INDICATOR_GROUP,
                    variables: {
                        formId: this.project.references.id,
                        groupId: group.groupId,
                    },
                })
                .then((result) => {
                    this.loading = false;
                    const { groups, components } = result.data.form_groupRemoved;
                    this.cachedGroups = groups;
                    this.project.references.groups = groups;
                    this.project.components = components;
                    this.contextUpdated({ ...this.project });
                    this.listItems = this.getListItems();
                    this.$store.commit('notify', {
                        type: 'success',
                        message: `Groep ${group.groupName} is verwijderd uit deze prestatie.`,
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.handleCancelIndicatorDuplication();
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Er is iets fout gegaan tijdens het verwijderen van de groep.',
                    });
                });
        },
        handleEditGroup(id, name, contextId, componentIds) {
            this.loading = true;
            this.$apollo
                .mutate({
                    mutation: EDIT_INDICATOR_GROUP,
                    variables: {
                        formId: this.project.references.id,
                        groupId: id,
                        name: name,
                        labelId: contextId,
                        componentIds: componentIds,
                    },
                })
                .then((result) => {
                    this.loading = false;
                    const groups = result.data.form_groupEdited.groups;
                    this.cachedGroups = groups;
                    this.project.references.groups = groups;
                    this.$store.commit('notify', { type: 'success', message: `Groep ${name} is succesvol aangepast` });

                    this.handleCancelIndicatorDuplication();
                    this.listItems = this.getListItems();
                })
                .catch((error) => {
                    this.loading = false;
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Er is iets fout gegaan tijdens het aanpassen van de groep.',
                    });
                    this.handleCancelIndicatorDuplication();
                });
        },
        handleCreateGroup(name, contextId, componentIds) {
            this.loading = true;
            this.$apollo
                .mutate({
                    mutation: CREATE_INDICATOR_GROUP,
                    variables: {
                        formId: this.project.references.id,
                        name: name,
                        labelId: contextId,
                        componentIds: componentIds,
                    },
                })
                .then(async (result) => {
                    this.loading = false;
                    const { groups, components, responses } = result.data.form_groupAdded;
                    this.cachedGroups = groups;
                    this.project.references.groups = [ ...groups ];
                    this.project.references.components = [ ...components ];
                    this.project.references.responses = [ ...responses ];
                    this.contextUpdated({ ...this.project });
                    this.$store.commit('notify', {
                        type: 'success',
                        message: `Groep ${name} is toegevoegd aan deze prestatie`,
                    });

                    const contextGroups = groups.filter(group => group.labelId === contextId)

                    if (contextGroups.length === 1) {
                        const newGroup = contextGroups.find((newGroup) => newGroup.name == name);
                        this.indicatorGroupName = newGroup.name + ' kopie';
                        this.duplicatingComponentIds = newGroup.componentIds;
                        this.isDuplicating = true;
                        this.addNewGroup = true;

                        this.listItems = this.getListItems();

                        this.isEditing = true;
                        this.isSelecting = true;
                        this.editingGroupId = '12345';
                        this.indicatorGroupName = '';
                        this.duplicatingComponentIds = [];

                        this.focusInput('indicator-group-input');
                        return
                    }

                    this.handleCancelIndicatorDuplication();
                })
                .catch((error) => {
                    this.loading = false;
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Er is iets fout gegaan tijdens het aanmaken van de groep.',
                    });
                    this.handleCancelIndicatorDuplication();
                });
        },
        async focusInput(id) {
            if(!id) return
            await new Promise((r) => setTimeout(r, 1));
            document.getElementById(id)?.focus();
        },
        handleGroupChecked(group, checked) {
            group.indicators.forEach((indicator) => this.handleIndicatorChecked(indicator, checked));
        },
        handleIndicatorChecked(indicator, checked) {
            if (checked) return this.groupIndicator(indicator);
            else this.unGroupIndicator(indicator);
        },
        handleToggleIndicatorVisibility(indicator) {
            this.$emit('toggleIndicatorVisibility', indicator);
        },
        groupIndicator(indicator) {
            if (this.indicatorIsDisabled(indicator)) return;
            if (!this.editingGroupId) {
                if (this.duplicatingComponentIds.includes(indicator.componentId)) return;
                return this.duplicatingComponentIds.push(indicator.componentId);
            }
            const contextGroups = this.getContextGroups();
            let updatedGroup = null;
            contextGroups.forEach((group) => {
                if (group.isBeingCreated && !group.componentIds.includes(indicator.componentId)) {
                    const originalGroup = contextGroups.find((cachedGroup) => cachedGroup.id === group.id);
                    const originalComponentId = originalGroup.componentIds.find((componentId) => {
                        const groupIndicator = this.indicators.find(
                            (projectIndicator) => projectIndicator.componentId === componentId
                        );
                        return groupIndicator.id === indicator.id;
                    });

                    group.componentIds.push(originalComponentId || indicator.componentId);
                    updatedGroup = group;
                }
            });
            this.project.references.groups = this.project.references.groups.map((group) => {
                if (group.isBeingCreated && updatedGroup) group = updatedGroup;
                return group;
            });
            this.listItems = this.getListItems();
        },
        unGroupIndicator(indicator) {
            if (!this.editingGroupId) {
                const index = this.duplicatingComponentIds.indexOf(indicator.componentId);
                if (index == -1) return;
                this.duplicatingComponentIds.splice(index, 1);
                if (this.duplicatingComponentIds.length === 0) this.handleCancelIndicatorDuplication();
                return;
            }
            const contextGroups = this.getContextGroups();
            let updatedGroup = null;
            contextGroups.forEach((group) => {
                if (group.isBeingCreated) {
                    const originalGroup = this.cachedGroups.find((cachedGroup) => cachedGroup.id === group.id);
                    let originalComponentId = null;
                    if(originalGroup) 
                            originalComponentId = originalGroup.componentIds.find((componentId) => {
                            const groupIndicator = this.indicators.find(
                                (projectIndicator) => projectIndicator.componentId === componentId
                            );
                            return groupIndicator.id === indicator.id;
                        });

                    const index = group.componentIds.indexOf(originalComponentId || indicator.componentId);
                    if (index == -1) return;
                    group.componentIds.splice(index, 1);
                    updatedGroup = group;
                }
            });

            const groupsWithComponentIds = contextGroups.filter((group) => group.componentIds.length > 0);
            if (groupsWithComponentIds.length < contextGroups.length) return this.handleCancelIndicatorDuplication();

            this.project.references.groups = this.project.references.groups.map((group) => {
                if (group.isBeingCreated && updatedGroup) group = updatedGroup;
                return group;
            });
            this.listItems = this.getListItems();
        },
        openGroup(groupId) {
            if (this.openedGroupIds.includes(groupId)) return;
            this.openedGroupIds.push(groupId);
        },
        closeGroup(groupId) {
            const index = this.openedGroupIds.indexOf(groupId);
            if (index == -1) return;
            this.openedGroupIds.splice(index, 1);
        },
        handleGroupToggle(groupId) {
            if (!this.openedGroupIds.includes(groupId)) this.openGroup(groupId);
            else this.closeGroup(groupId);
        },
        calculateTotalHeight(indicators) {
            let maxHeight = 0;
            indicators.forEach((indicator) => {
                if (typeof indicator.answer === 'string') {
                    let heightOfIndicatorAnswer = indicator.answer.length / 22;
                    maxHeight += heightOfIndicatorAnswer * 43;
                }
                let answers = indicator.answerValues.filter((value) =>
                    indicator.answer ? indicator.answer.includes(value.value) : false
                );
                if (answers.length === 0) answers.length = 1;
                maxHeight += answers.length * 43;
            });
            return `${maxHeight}px`;
        },
        async handleGroupOptionSelected(group, option) {
            this.cancelEdit();

            if (option.name === 'Aanpassen') {
                this.cachedGroups = [ ...this.project.references.groups ];
                this.project.references.groups = this.project.references.groups.filter((formGroup) => {
                    if (formGroup.id !== group.groupId) return true;

                    this.editingGroupId = formGroup.id;
                    this.duplicatingComponentIds = formGroup.componentIds;
                    this.indicatorGroupName = formGroup.name;
                    this.isDuplicating = true;
                    this.isSelecting = true;
                    this.isEditing = true;
                    this.addNewGroup = true;
                });
                this.listItems = this.getListItems();
                this.$emit('setDuplicating', true);

                this.focusInput('indicator-group-input');
            } else if (option.name === 'Verwijderen') {
                this.deletingGroup = group;
            }
        },
        getGroupCheckboxValue(group) {
            const disabledIndicators = group.indicators.filter((indicator) => this.indicatorIsDisabled(indicator));
            const checkedIndicators = group.indicators.filter((indicator) =>
                this.getIndicatorCheckboxValue(indicator, group)
            );
            if (checkedIndicators.length === 0) return false;
            return checkedIndicators.length > 0 && disabledIndicators.length === group.indicators.length;
        },
        getIndicatorCheckboxValue(indicator, group) {
            if (group?.isBeingCreated) return true;

            if (
                !this.editingGroupId &&
                this.duplicatingComponentIds.length > 0 &&
                this.duplicatingComponentIds.includes(indicator.componentId)
            )
                return true;

            const creatingGroup = this.project.references.groups?.find((group_) => group_.isBeingCreated);
            if (!creatingGroup) return false;

            if (creatingGroup.componentIds.includes(indicator.componentId)) return true;

            return false;
        },
        indicatorIsDisabled(indicator, group) {
            if (group?.isBeingCreated) return false;

            if (this.duplicatingComponentIds.length > 0) {
                const duplicatingIndicators = this.duplicatingComponentIds.map((componentId) =>
                    this.indicators.find((projectIndicator) => projectIndicator.componentId === componentId)
                );
                if (
                    duplicatingIndicators.find((duplicatingIndicator) => duplicatingIndicator.id === indicator.id) &&
                    !this.duplicatingComponentIds.includes(indicator.componentId)
                )
                    return true;
            }

            const creatingGroup = this.project.references.groups?.find((group_) => group_.isBeingCreated);
            if (!creatingGroup) return false;

            const originalGroup = this.cachedGroups.find((cachedGroup) => cachedGroup.id === creatingGroup.id);
            if (originalGroup) {
                const originalGroupIndicators = originalGroup.componentIds.map((componentId) =>
                    this.indicators.find((projectIndicator) => projectIndicator.componentId === componentId)
                );
                const originalGroupIndicator = originalGroupIndicators.find(
                    (groupIndicator) => groupIndicator.componentId === indicator.componentId
                );
                const indicatorIsInOriginalGroup =
                    originalGroupIndicators.filter((groupIndicator) => groupIndicator.id === indicator.id).length > 0;
                if (indicatorIsInOriginalGroup && !originalGroupIndicator) return true;
            }

            const creatingGroupIndicatorIds = this.indicators
                .filter((indicator_) => creatingGroup.componentIds.includes(indicator_.componentId))
                .map((indicator_) => indicator_.id);

            return creatingGroupIndicatorIds.includes(indicator.id);
        },
        getContextGroups() {
            let groups = [];
            this.listItems.forEach((indicator) => {
                if (indicator.isGroup) {
                    const newGroup = {
                        id: indicator.groupId,
                        name: indicator.groupName,
                        isGroup: true,
                        labelId: indicator.labelId,
                        componentIds: indicator.indicators.map((i) => i.componentId),
                        isBeingCreated: indicator.isBeingCreated || false,
                    };
                    groups.push(newGroup);
                }
            });

            return groups;
        },
        onSaveIndicator(data) {
            this.$emit('saveIndicator', data);
        },
        onStartLoading(indicator) {
            this.$emit('startLoading', indicator);
        },
        onStopLoading(indicator) {
            this.$emit('stopLoading', indicator);
        },
        edit(indicator) {
            this.$emit('editIndicator', indicator);
        },
        cancelEdit() {
            this.$emit('editIndicator');
        },
        canEditIndicator(indicator) {
            return this.$parent.canEditIndicator(indicator);
        },
        contextUpdated(project) {
            this.$emit('contextUpdated', project, this.context);
        },
        indicatorFilter(indicator) {
            if(indicator.disabled) return false

            const search = this.search.toLowerCase();
            if (search == '') return true;

            if(this.context.name.toLowerCase().includes(search)) return true

            if (indicator.name.toLowerCase().includes(search)) return true
            if (!isValidAnswer(indicator.answer)) return false

            if (['dropdown','radio'].includes(indicator.answerType)) {
                const answerLabel = indicator.answerValues.find((value) => value.value === indicator.answer);
                return answerLabel.label.toLowerCase().includes(search);
            }
            if (['dropdown-multiple','checkbox'].includes(indicator.answerType)) {
                const answerLabels = indicator.answerValues.filter((value) => indicator.answer.includes(value.value));
                for (let i = 0; i < answerLabels.length; i++) {
                    const answerLabel = answerLabels[i];
                    if (answerLabel.label.toLowerCase().includes(search)) return true;
                }
            }
            if(typeof indicator.answer === 'string') return indicator.answer?.toLowerCase().includes(search)
            
            return indicator.answer.toString().includes(search)
            
        },
        getListItems() {
            const otherIndicators = this.indicators.filter(this.indicatorFilter);

            const projectDetails = this.project.references;
            let groups = projectDetails?.groups || [];

            if (this.isDuplicating && this.duplicatingComponentIds.length > 0 && this.addNewGroup) {
                const groupId = this.editingGroupId || '12345';

                groups.push({
                    id: groupId,
                    name: this.indicatorGroupName,
                    labelId: this.context.id,
                    componentIds: [...this.duplicatingComponentIds],
                    isBeingCreated: true,
                    isGroup: true,
                });
                this.openGroup(groupId);
                this.addNewGroup = false;
            }

            const listItems = [];

            const contextGroups = groups.filter((group) => group.labelId === this.context.id);
            contextGroups.forEach((group) => {
                const newGroup = {
                    groupId: group.id,
                    groupName: this.markName(group.name),
                    isGroup: true,
                    labelId: group.labelId,
                    indicators: otherIndicators.filter((indicator) =>
                        group.componentIds.includes(indicator.componentId)
                    ),
                    isBeingCreated: group.isBeingCreated || false,
                };
                listItems.push(newGroup);
            });
            otherIndicators.forEach((indicator) => {
                const isInGroup =
                    groups.filter((group) => group.componentIds.includes(indicator.componentId)).length > 0;
                if (isInGroup) return;
                listItems.push(indicator);
            });

            const filteredListItems = listItems.filter(listItem => listItem.isGroup ? listItem.indicators.length > 0 : true)

            return filteredListItems;
        },
        markName(name) {
            if(!this.search) return name
            let newName = name;
            const re = new RegExp(this.search, 'i');
            const match = newName.match(re);
            const marked = newName.replace(re, `<span style="background-color: #F4B976;border-radius:3px;">${match}</span>`);
            return marked
        },
        getConversionVariation(conversion) {
            const [ filled, all ] = conversion.split('/');
            if(filled == 0) return 'danger'
            if(filled == all) return 'success'
            return 'warning'
        },
    },
    computed: {
        groupButtonLabel: function () {
            if (this.duplicatingGroupId) return 'Duplicaat opslaan';
            if (this.editingGroupId) return 'Wijzigingen opslaan';
            let label = '';

            if (this.isDuplicating) {
                if (this.duplicatingComponentIds.length == 0) label = 'Kenmerken dupliceren';
                else if (this.duplicatingComponentIds.length == 1) label = '1 kenmerk dupliceren';
                else label = `${this.duplicatingComponentIds.length} kenmerken dupliceren`;
            } else label = 'Kenmerken dupliceren';

            return label;
        },
        allowGroupingFunctionality: function () {
            if(!this.duplicatingEnabled) return false
            return this.$store.getters.hasQfactPD
        },
        visible: function() {
            if(!this.search) return true
            const contextName = this.$t(this.context.name);
            const re = new RegExp(this.search, 'i');
            const match = contextName.match(re);
            return match || this.listItems.length > 0
        },
        markedContextName: function() {
            const name = this.$t(this.context.name);
            if(!this.search) return name
            return this.markName(name)
        },
        conversion: function() {
            const contextComponents = this.project.references.components.filter(component => component.indicator.context.id === this.context.id && !component.disabled);

            let total = 0;
            let answered = 0;

            let required = 0;
            let requiredAnswered = 0;

            contextComponents.forEach(component => {
                total++;
                const isRequired = component.indicator.required && !component.hidden;
                if (isValidAnswer(component.indicator.answer)) answered++;
                if (isRequired) required++;
                if (isRequired && isValidAnswer(component.indicator.answer)) requiredAnswered++;
            })

            const conversion = `${answered}/${total}`;
            const variation = this.getConversionVariation(conversion);

            let tooltip = '';
            const totalText = `<b>${answered}</b> van de <b>${total}</b> kenmerken ingevuld<br>`;
            const requiredText = `<b>${requiredAnswered}</b> van de <b>${required}</b> verplichte kenmerken<span style="color:#00a1ae">*</span> ingevuld<br>`;
            if (total !== required) tooltip += totalText;
            if (required > 0) tooltip += requiredText;

            return {
                conversion,
                variation,
                tooltip
            }
        },
        options: function() {
            const options = [];

            if(!['default-context','crow-project-details'].includes(this.context.id)) 
                options.push({
                    name: 'Kenmerk toevoegen'
                });

            const hiddenLabelIndicators = this.allIndicators.filter(indicator => indicator.hidden);
            const labelIsHidden = this.allIndicators.length === hiddenLabelIndicators.length;

            if(this.showVisibilityOption) {
                const option = {
                    name: `Prestatie ${labelIsHidden ? 'tonen' : 'verbergen'}`
                }
                options.push(option)
            }

            return options
        }
    },
    watch: {
        projectProp: function () {
            this.project = this.projectProp;
            this.listItems = this.getListItems();
        },
        search: function() {
            this.listItems = this.getListItems();
        },
        indicators: function() {
            this.listItems = this.getListItems();
        }
    },
    created() {
        this.listItems = this.getListItems();
    },
};
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.context-option {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 24px;

    .tag-container {
        display: grid;
        place-items: center;

        .tag {
            font-size: 12px;
            padding: 6px 10px;
            border-radius: 16px;
            font-weight: 500;
    
            &.red {
                color: $color-red;
                background-color: $color-red-lighter;
            }
            &.orange {
                color: $color-orange;
                background-color: $color-orange-lighter;
            }
            &.green {
                color: $color-green;
                background-color: $color-green-lighter;
            }
        }
    }

}

.popup-delete {
    .popup-header {
        padding-bottom: 24px;
    }
    .popup-body {
        padding-bottom: 32px;
    }
    .popup-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 32px;
        border-top: 1px solid #dee2e6;
    }
}

.other {
    display: flex;
    flex-direction: column;

    .buttons {
        transition: 0.2s ease;
    }

    .buttons:has(.cancel-button) {
        opacity: 1 !important;
        user-select: all !important;
    }

    .context-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &:not(:hover) .buttons {
        opacity: 0;
        user-select: none;
    }
}

.panel {
    position: relative;
    padding: 24px 24px 16px 24px;
    width: 100%;

    .buttons {
        position: absolute;
        top: 24px;
        right: 20px;
        display: flex;
        gap: 8px;
        background-color: #ffffff;
        padding-left: 8px;
        padding-bottom: 8px;
    }
}

.indicator-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 4px;
    width: 100%;

    &:not(:hover) {
        .group-header .group-options {
            opacity: 0;
            user-select: none;
        }
    }

    .name-input {
        width: 60%;
        margin-bottom: 4px;
    }

    .group-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 4px;

        .left {
            display: flex;
            align-items: center;
            gap: 8px;

            .group-checkbox {
                margin-right: -8px;
            }

            .folder {
                user-select: none;
                cursor: pointer;
            }

            .group-name {
                font-size: 15px;
                font-weight: 500;
                user-select: none;
                cursor: pointer;
            }

            .chevron {
                scale: 0.7;
                rotate: 180deg;
                transition: 0.3s ease;
                user-select: none;
                cursor: pointer;
            }
        }

        .group-options {
            position: absolute;
            right: 20px;
            transition: 0.2s ease;
        }
    }

    &.collapsed {
        .group-header .chevron {
            rotate: 0deg;
        }
        .indicators-wrapper {
            grid-template-rows: 0fr;

            .content {
                overflow: hidden;
            }

            .grouped-indicator {
                user-select: none;
                pointer-events: none;

                .indicator-answer {
                    opacity: 0;
                    translate: 0 -100%;
                }
            }
        }
    }

    .indicators-wrapper {
        display: grid;
        grid-template-rows: 1fr;
        transition: grid-template-rows .3s ease;
        -webkit-transition: grid-template-rows .3s ease;
        will-change: grid-template-rows;

        .grouped-indicator {
            position: relative;
            animation: slide-in 0.15s ease-in-out forwards;

            .indicator-answer {
                width: calc(100% - var(--padding-inline));
                position: relative;
                transition: translate 0.25s ease var(--animation-stagger), opacity 0.25s ease var(--animation-stagger);

                &:before {
                    position: absolute;
                    left: 0;
                    content: '';
                    inset-block: 0;
                    width: 1px;
                    background-color: #dee2e6;
                    border-radius: 1px;
                }
            }

            &:first-of-type .indicator-answer:before {
                inset-block-start: 10px;
            }
            &:last-of-type .indicator-answer:before {
                inset-block-end: 10px;
            }
        }
    }
}

@keyframes slide-in {
    from {
        padding-left: 0;
    }
    to {
        padding-left: 12px;
    }
}

@keyframes set-overflow-visible {
    0% {
        overflow: hidden;
    } 100% {
        overflow: visible;
    }
}
</style>
