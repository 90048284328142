<template>
    <div class="modal">
        <div class="vertical-center">
            <h2>Tussentijdse meting overslaan</h2>
        </div>

        <div class="modal-content">
            <p>
                Als je de tussentijdse Better Performance meting overslaat kun je direct de eindmeting starten.
            </p>
            <br />
            <p>
                Let op! Als je aan de eindmeting bent begonnen kun je geen tussentijdse metingen meer toevoegen aan het
                project.
            </p>
            <br />
            <p>Licht hieronder toe waarom je de tussentijdse meting voor dit project wil overslaan.</p>
            <div class="input flex-end">
                <q-tag
                    class="required-tag"
                    :class="skippedReason && skippedReason.length !== 0 ? 'hide' : 'show'"
                    size="small"
                    >verplicht</q-tag
                >
                </div>
                <q-text
                    class="input-text"
                    placeholder="Type hier je toelichting"
                    :value="skippedReason"
                    v-model="skippedReason"
                    maxlength="1000"
                ></q-text>
            
        </div>
        <div class="footer">
            <q-button variation="blank" class="red-hover" @click="closePopup">Annuleren</q-button>
            <q-button @click="handleUpdateSettings" :loading="loadingUpdateSettings">Ja, tussentijdse meting overslaan</q-button>
        </div>
    </div>
</template>

<script>
import { UPDATE_SETTINGS } from '@/graphql/mutations';
import { DELETE_FORM } from '../../../graphql/mutations';

export default {
    name: 'form-skip-review',
    emits: ['inputReviewValue'],
    props: ['settings'],
    data() {
        return {
            skipped: false,
            skippedReason: '',
            user: this.$store.getters.getUser,
            intermediateMeasurement: this.settings.categories.find(category => category.id === 'intermediate-measurement'),
            loadingUpdateSettings: false
        };
    },
    methods: {
        async deleteFormIds() {
            const formId = this.intermediateMeasurement.formIds[0]; // backend will delete companion formId

            await new Promise(resolve => {
                this.$apollo.mutate({
                    mutation: DELETE_FORM, 
                    variables: { id: formId }
                })
                .then(result => {
                    this.$store.commit('notify', {
                        message: 'De tussentijdse meting wordt in dit project overgeslagen',
                        type: 'success'
                    });
                    resolve()
                })
                .catch(err => {
                    this.$store.commit('notify', {
                        message: 'Er ging iets fout tijdens het overslaan van de tussentijdse meting',
                        type: 'warning'
                    });
                    resolve()
                });
            });

            return
        },
        async handleUpdateSettings() {
            this.loadingUpdateSettings = true;

            if(!this.skippedReason.length) {
                this.loadingUpdateSettings = false;
                this.$store.commit('notify', {
                    type: 'warning',
                    message: 'Vul een reden in voor het overslaan van de tussentijdse meting.',
                });
                return
            }

            await this.deleteFormIds();
            console.log('deleted formIds')
            await this.updateSettings();
            console.log('updated settings')
        },
        async updateSettings() {
            const id = this.$route.params.id;
            const skippedReason = this.skippedReason;
            const skippedReasonUser = this.user.firstName + ' ' + this.user.lastName;
            const settings = this.settings;

            for (let category of settings.categories) {
                if (category.id === 'intermediate-measurement') {
                    category.skipped = true;
                    category.formIds = [];
                    category.skippedReason = skippedReason;
                    category.skippedDate = Date.now();
                    category.skippedUserId = skippedReasonUser;
                    category.required = false;
                } else {
                    category.dependsOn = [];
                }
            }

            await new Promise(resolve => {
                this.$apollo
                    .mutate({
                        mutation: UPDATE_SETTINGS,
                        variables: {
                            id,
                            settings,
                        },
                    })
                    .then(result => {
                        console.log('emit success')
                        this.$emit('succes', result.data.project_changeSettings);
                        resolve();
                    })
                    .catch((error) => {
                        this.$store.commit('notify', { type: 'danger', message: 'Er is iets misgegaan' });
                        this.closePopup();
                        resolve();
                    });
            });

            return
        },
        closePopup() {
            return this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.modal {
    width: 600px !important;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 5px 5px 5px;
        border-top: 2px solid #dee2e6;
    }

    .red-hover :hover {
        color: $color-red;
        transition: color 200ms ease;
    }
}

.modal-content {
    margin: 20px 0 20px 0;
}

.input {
    margin: 0 0 8px 0;
}

.required-tag {
    transition: 0.4s ease;

    &.hide {
        transform: translateY(30px);
    }
}
</style>
