<template>
    <vue-pdf-app 
        :pdf="url"
        :loading="loading"
        :style="{ height: '100%', width: '100%' }"
        ref="pdfViewer"
        @pages-rendered="onPagesRendered"
        :config="{ toolbar: 'viewFind' }"
    ></vue-pdf-app>
</template>

<script>

import gql from 'graphql-tag'
import VuePdfApp from "vue-pdf-app";
import axios from 'axios';


export default {
    name: 'pdf-document-view-frame',
    components: {
        VuePdfApp
    },
    data() {
        return {
            url: undefined,
            loading: true
        }
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        projectJwtData: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: true
        }
    },
    methods: {
        onPagesRendered() {
            if (this.options.searchQuery)
                document.getElementById('viewFind').click();
                setTimeout(() => {
                    document.getElementById('findInput').value = this.options.searchQuery;
                    document.getElementById('findHighlightAll').click();
                    setTimeout(() => {
                        document.getElementById('findNext').click();
                    }, 100);
                }, 0);
        },
        async getDocument() {

            this.loading = true;

            const result = await this.$apollo.query({
                query: gql`
                    mutation($documentId: String!, $projectJwt: String!) {
                        documents_getPresignedUrl(documentId: $documentId, projectJwt: $projectJwt)
                    }
                `,
                variables: {
                    documentId: this.document.documentId,
                    projectJwt: this.projectJwtData.jwt
                },
                fetchPolicy: 'no-cache'
            })

            const response = await axios.get(result.data.documents_getPresignedUrl, { responseType: 'blob' });

            this.url =  URL.createObjectURL(response.data);

            this.loading = false;
        }
    },
    created() {
        this.getDocument();
    }
}
</script>

<style scoped>



</style>