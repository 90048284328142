<template>
    <div class="rsx-metadata-fields">
        <rsx-answer-reference
            v-for="(answerReference, key) in answerReferences"
            :key="key"
            :documentId="answerReference.documentId"
            :documentName="answerReference.documentName"
            :documentUserName="answerReference.documentUserName"
            :specificationPosts="answerReference.specificationPosts"
        >
        </rsx-answer-reference>
    </div>
</template>

<script>
import RsxAnswerReference from './RsxAnswerReference.vue'

export default {
    name: 'rsx-metadata-fields',
    components: {
        'rsx-answer-reference': RsxAnswerReference
    },
    props: {
        answerReferences: {
            type: Array,
            required: true
        },

    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style scoped>

.rsx-metadata-fields {
    width: 100%
}

</style>