<template>
  <div class="table">
        <table>
            <thead>
                <tr>
                    <th>Bestekpostnummer</th>
                    <th>Omschrijving</th>
                    <th>Eenheid</th>
                    <th>Hoeveelheid</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(specificationPost, key) in specificationPosts" :key="key">
                    <td v-html="specificationPost.specificationsPostNumber"></td>
                    <td v-html="specificationPost.description"></td>
                    <td v-html="specificationPost.unit"></td>
                    <td v-html="specificationPost.value"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'rsx-table',
    props: {
        specificationPosts: {
            type: Array,
            required: true,
            validator: (value) => {
                return value.every((specificationPost) => {
                    return specificationPost.hasOwnProperty('specificationsPostNumber') 
                    && specificationPost.hasOwnProperty('unit') 
                    && specificationPost.hasOwnProperty('value') 
                    && specificationPost.hasOwnProperty('description')
                })
            }
        },
        
    }
}
</script>

<style scoped lang="scss">


th {
    font-weight: 400;
    font-size: 10px;
    padding: 4px 0;
}

tr {
    margin: 0;
}

td {
    padding: 4px;
}

td {
    border: 1px solid black;
    font-size: 12px;
    background-color: white;

}
table { 
    width: 100%;
    border-collapse: collapse;
}


</style>