<template>
    <component
        :is="type"
        :href="href"
        :type="submit"
        :for="formFor"
        :actions="actions"
        :icon="icon"
        :class="['button', disabled ? 'disabled' : '', ripple, size, state, variation, { buttonColor }]"
        :style="`cursor: ${loading || disabled ? 'default' : 'pointer'}; --button-color: ${buttonColor}; --percentage-x: ${percentageX}%; --percentage-y: ${percentageY}%; --bubble-scale: ${bubbleScale}`"
        @click="handleClick"
        :loading="loading"
    >
        <div ref="hitBox" class="interactive"></div>
        <div class="bubble-wrapper">
            <span class="button-bubble" :class="{ hide: !initializedPosition }"></span>
        </div>
        <q-icon
            v-if="icon && !loading && iconPosition === 'left'"
            class="button_icon"
            :style="iconStyleObject"
            :type="icon"
            :width="iconSizeInput"
            :height="iconSizeInput"
        />
        <div :class="{ hide: loading }">
            <slot />
        </div>
        <q-icon
            v-if="icon && !loading && iconPosition === 'right'"
            class="button_icon"
            :style="iconStyleObject"
            :type="icon"
            :width="iconSizeInput"
            :height="iconSizeInput"
        />
        <div class="preloader" v-if="loading"><div class="loader"></div></div>
    </component>
</template>

<script>
import QIcon from './QIcon.vue';

/**
 * Buttons are generally used for interface actions. Suitable for all-purpose use.
 * Defaults to appearance that has white background with grey border.
 * Primary style should be used only once per view for main call-to-action.
 */
export default {
    name: 'q-button',
    status: 'prototype',
    components: { QIcon },
    release: '0.0.1',
    props: {
        loadingText: {
            type: String,
            default: 'loading...'
        },
        finishedText: {
            type: String,
            default: 'Done'
        },
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * The html element used for the button.
         * `button, a`
         */
        type: {
            type: String,
            default: 'button',
            validator: value => {
                return value.match(/(button|a)/);
            }
        },
        /**
         * The size of the button. Defaults to medium.
         * `small, medium, large`
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => {
                return value.match(/(small|medium|large|xsmall)/);
            }
        },
        /**
         * When setting the button’s type to a link, use this option to give a href.
         */
        to: {
            type: String,
            default: null
        },
        /**
         * When setting the button’s type to a link, use this option to give a href.
         */
        go: {
            type: String,
            default: null
        },
        /**
         * When setting the button’s type to a link, use this option to give a href.
         */
        href: {
            type: String,
            default: null
        },
        /**
         * Set the button’s type to “submit”.
         */
        submit: {
            type: String,
            default: null,
            validator: value => {
                return value.match(/(null|submit)/);
            }
        },
        formFor: {
            type: String,
            default: null
        },
        /**
         * Manually trigger various states of the button.
         * `hover, disabled, active`
         */
        state: {
            type: String,
            default: null,
            validator: value => {
                return value.match(/(hover|disabled|active)/);
            }
        },
        /**
         * Style variation to give additional meaning.
         * `primary, secondary`
         */
        variation: {
            type: String,
            default: 'primary',
            validator: value => {
                return value.match(/(primary|secondary|danger|danger-light|secondary-light|ghost|blank|light)/);
            }
        },
        /**
         * Style variation to give additional meaning.
         * `primary, secondary`
         */
        actions: {
            type: Array,
            default: null
        },
        /**
         * Sets icon type.
         */
        icon: {
            type: String,
            default: null
        },
        /**
         * Sets icon type.
         */
        iconStyle: {
            type: Object,
            default: null
        },

        /**
         * Sets icon size.
         */
        iconSize: {
            type: String,
            default: null
        },

        /**
         * Position for icon in button ('left', 'right')
         */
        iconPosition: {
            type: String,
            default: 'left'
        },

        /**
         * Sets icon color
         */
        color: {
            type: String,
            default: null
        },

        buttonColor: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // icon: req("./down_arrow.svg").replace(/^<svg /, `<svg style="fill: ${this.fill}" `),
            width: 18,
            height: 18,
            percentageX: -10,
            percentageY: -10,
            bubbleScale: 0.3,
            initializedPosition: false
        };
    },
    methods: {
        getIconPadding() {
            switch (this.size) {
                case 'large':
                    return !this.loading ? '55px' : '27px';
                case 'medium':
                    return !this.loading ? '45px' : '22px';
                case 'small':
                    return !this.loading ? '32px' : '16px';
            }
        },
        handleClick(event) {
            if (this.disabled || this.loading) {
                return false;
            } else {
                this.$emit('click', event);
                // eslint-disable-next-line no-undef
                if (this.to && this.$router) this.$router.push(this.to);
                else if (this.go && this.$router) this.$router.go(parseInt(this.go));
            }
        },
        handleMouseMove(event) {
            if(this.disabled) return
            this.initializedPosition = true;
            const hitBox = this.$refs.hitBox;
            const { x, y, width, height } = hitBox.getBoundingClientRect();
            if(
                hitBox.offsetParent === null ||
                x > window.innerWidth || 
                (x + width) < 0 || 
                y > window.innerHeight ||
                (y + height) < 0
            ) return

            const pixelsFromLeft = event.pageX - window.scrollX - x - 30;
            const pixelsFromtop = event.pageY - window.scrollY - y - 30;
            this.percentageX = pixelsFromLeft / (width - 60) * 100;
            this.percentageY = pixelsFromtop / (height - 60) * 100;
            const centerX = width / 2 - 30;
            const centerY = height / 2 - 30;
            const deltaCenterX = pixelsFromLeft < centerX ? centerX - pixelsFromLeft : pixelsFromLeft - centerX;
            const deltaCenterY = pixelsFromtop < centerY ? centerY - pixelsFromtop : pixelsFromtop - centerY;
            const percentageCenterX = deltaCenterX / (centerX) * 130;
            const percentageCenterY = deltaCenterY / (centerY) * 100;
            const bubbleScale = 1 - (((percentageCenterX + percentageCenterY) / 200) * 0.7);
            if(bubbleScale > 0) this.bubbleScale = bubbleScale;
        }
    },
    computed: {
        iconSizeInput() {
            let defaultValue = '18';
            switch (this.size) {
                case 'large':
                    defaultValue = '24';
                case 'medium':
                    defaultValue = '18';
                case 'small':
                    defaultValue = '15';
            }

            const size = this.iconSize ? this.iconSize : defaultValue;

            return size;
        },
        iconMargin() {
            const style = {
                marginLeft: '0px'
            };
            switch (this.size) {
                case 'large':
                    style.marginLeft = '-3px';
                    break;
                case 'medium':
                    style.marginLeft = '-5px';
                    break;
                case 'small':
                    style.marginLeft = '-7px';
                    break;
            }
            return style;
        },
        iconStyleObject() {
            return this.iconStyle ? this.iconStyle : this.iconMargin;
        },
        ripple() {
            return this.loading || this.state === 'disabled' ? null : 'ripple';
        }
    },
    watch: {
        disabled: function(after, before) {
            if(!before && after) {
                this.percentageX = -10;
                this.percentageY = -10;
                this.bubbleScale = 0.3;
            }
        }
    },
    mounted() {
        this.$root.$on('mousemove', this.handleMouseMove);
    },
    beforeDestroy() {
        this.$root.$off("mousemove", this.handleMouseMove);
    },
};
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

.button {
    will-change: transform;
    transition: all 0.2s ease;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: $weight-semi-bold;
    font-size: $size-m;
    font-family: $font-text;
    line-height: $line-height-m;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: $radius-default;
    background: transparent;
    color: $color-primary;
    white-space: nowrap;
    overflow: hidden;

    transition: box-shadow width color 0.4s ease;

    --width: 60%;
    --time: .4s;

    &:disabled,
    &.disabled {
        transition: none;
        background: $color-primary;
        opacity: 0.7;
        box-shadow: none;
        color: $color-white;
        cursor: initial;
    }

    &:focus,
    &.focus {
        background: inherit;
        box-shadow: none;
        outline: 0;
    }

    &:active,
    &:focus {
        .button-bubble {
            width: 300% !important;
        }
    }

    &:focus {
        .button-bubble {
            transition: width var(--time) ease;
            left: 50%;
            top: 50%;
        }
    }

    &:active {
        .button-bubble {
            transition: left var(--time) ease, top var(--time) ease, width var(--time) ease !important;
        }
    }

    // Various button sizes
    &.large {
        padding: 11px $space-m;
        font-size: $size-l;
        border-radius: 4.5px;
        gap: 12px;

        & .loader {
            //   $loader-color: #fff;
            $loader-size: 10px;
            $loader-height: 20px;
            $loader-border-size: 3px;
            $loader-gap: 20px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
        }
    }
    &.medium {
        // @include inset-squish-space($space-s);
        padding: 9px $space-m;
        font-size: $size-m;
        gap: 12px;

        & .loader {
            $loader-size: 6px;
            $loader-height: 14px;
            $loader-border-size: 3px;
            $loader-gap: 13px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
        }
    }
    &.small {
        padding: 6px $space-s;
        line-height: 17px;
        font-size: $size-xs;
        gap: 8px;

        & .loader {
            $loader-size: 4px;
            $loader-height: 14px;
            $loader-border-size: 3px;
            $loader-gap: 11px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
        }
    }
    &.xsmall {
        padding: 1px 8px;
        font-size: $size-xs;

        & .loader {
            $loader-size: 3px;
            $loader-height: 14px;
            $loader-border-size: 3px;
            $loader-gap: 8px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
        }
    }

    // Primary button
    &.primary {
        background: $color-primary;
        color: $color-white;
        box-shadow: none;

        &:disabled,
        &.disabled {
            transition: none;
            background: $color-primary;
            opacity: 0.5;
            box-shadow: none;
            color: $color-white;
            cursor: initial;
        }

        &:focus,
        &.focus {
            box-shadow: 0 0 0 2pt lighten($color-primary, 8%);
        }

        &.ripple .button-bubble {
            background: lighten($color-primary, 3%);
        }

        .user-is-tabbing &:focus,
        &.focus {
        }
    }

    // Secondary button
    &.secondary {
        background: $color-secondary;
        color: $color-white;
        box-shadow: none;

        &:hover,
        &.hover {
            color: $color-white;
        }

        &:disabled,
        &.disabled {
            transition: none;
            background: $color-secondary;
            opacity: 0.5;
            box-shadow: none;
            color: $color-white;
            cursor: initial;
        }

        &:focus,
        &.focus {
            box-shadow: 0 0 0 2pt lighten($color-secondary, 40%);
        }

        &.ripple .button-bubble {
            background: lighten($color-secondary, 15%);
        }

        .user-is-tabbing &:focus,
        &.focus {
        }
    }

    // Danger button
    &.danger {
        background: $color-red;
        color: $color-white;
        box-shadow: none;

        // &:hover,
        // &.hover {
        //   color: $color-white;
        //   opacity: 0.8;
        //   background: $color-primary;
        // }

        &:disabled,
        &.disabled {
            transition: none;
            background: $color-red;
            opacity: 0.5;
            box-shadow: none;
            color: $color-white;
            cursor: initial;
        }

        &:focus,
        &.focus {
            box-shadow: 0 0 0 2pt lighten($color-red, 8%);
        }

        &.ripple .button-bubble {
            background: lighten($color-red, 10%);
        }

        .user-is-tabbing &:focus,
        &.focus {
        }
    }

    // Danger Light button with blank background and red text 
    &.danger-light {
        background: transparent;
        color: $color-red;
        box-shadow: none;
        
    }

    // Secondary Light button
    &.secondary-light {
        background: #d9c8e0;
        color: $color-secondary-light;
        box-shadow: none;

        &:hover,
        &.hover {
            color: $color-secondary-light;
        }

        &:disabled,
        &.disabled {
            transition: none;
            background: #d9c8e0;
            opacity: 0.5;
            box-shadow: none;
            color: $color-secondary-light;
            cursor: initial;
        }

        &:focus,
        &.focus {
            box-shadow: 0 0 0 2pt darken(#d9c8e0, 10%);
        }

        &.ripple .button-bubble {
            background: darken(#d9c8e0, 10%);
        }

        .user-is-tabbing &:focus,
        &.focus {
        }
    }
    // Ghost button
    &.ghost {
        background: transparent;
        color: $color-grey-7;
        border: 1px solid $color-grey-7;
        box-shadow: none;

        &.medium .loader {
            $loader-color: $color-black;
            $loader-size: 6px;
            $loader-height: 14px;
            $loader-border-size: 3px;
            $loader-gap: 13px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
        }
        &.small .loader {
            $loader-color: $color-black;
            $loader-size: 4px;
            $loader-height: 14px;
            $loader-border-size: 3px;
            $loader-gap: 11px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
        }
        &.xsmall .loader {
            $loader-color: $color-black;
            $loader-size: 3px;
            $loader-height: 14px;
            $loader-border-size: 3px;
            $loader-gap: 8px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
        }

        &:hover,
        &.hover {
            color: $color-primary;
            border-color: $color-primary;
        }

        &:disabled,
        &.disabled {
            transition: none;
            color: $color-grey-5;
            border-color: $color-grey-5;
            box-shadow: none;
            cursor: initial;
        }

        &:focus,
        &.focus {
            //   box-shadow: 0 0 0 2pt lighten($color-primary, 20%);
            color: $color-primary;
            border-color: $color-primary;
        }

        .user-is-tabbing &:focus,
        &.focus {
        }
    }

    // Ghost button
    &.light {
        background: transparent;
        color: $color-grey-5;
        border: 1px solid $color-grey-5;
        box-shadow: none;

        &:hover,
        &.hover {
            color: $color-grey-7;
            border-color: $color-grey-7;
        }

        &:disabled,
        &.disabled {
            transition: none;
            color: $color-grey-3;
            border-color: $color-grey-3;
            box-shadow: none;
            cursor: initial;
        }

        &:focus,
        &.focus {
            //   box-shadow: 0 0 0 2pt lighten($color-grey-3, 5%);
            color: $color-grey-7;
            border-color: $color-grey-7;

            //   &:hover,
            //   &.hover {
            //     box-shadow: 0 0 0 2pt lighten($color-grey-5, 20%);
            //   }
        }

        .user-is-tabbing &:focus,
        &.focus {
        }
    }

    // Blank button
    &.blank {
        background: transparent;
        color: $color-grey-5;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;

        &.medium .loader {
            $loader-color: $color-grey-5;
            $loader-size: 6px;
            $loader-height: 14px;
            $loader-border-size: 3px;
            $loader-gap: 13px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
            cursor: default;
        }

        &.small .loader {
            $loader-color: $color-grey-5;
            $loader-size: 6px;
            $loader-height: 14px;
            $loader-border-size: 3px;
            $loader-gap: 13px;
            $loader-animation-duration: 1.2s;
            @import '../assets/loaders/loaders.scss';
            @include loader12;
            cursor: default;
        }

        &:disabled,
        &.disabled {
            transition: none;
            color: $color-grey-5;
            box-shadow: none;
            cursor: initial;
        }

        &:focus,
        &.focus {
            color: darken($color-grey-5, 20%);
        }

        &:hover,
        &.hover {
            opacity: 0.8;
            color: $color-primary;
        }

        .user-is-tabbing &:focus,
        &.focus {
        }
    }

    &.buttonColor {
        background-color: var(--button-color) !important;
        outline-color: var(--button-color) !important;

        &.ripple .button-bubble {
            background: #ffffff20 !important;
        }

        &:focus {
            box-shadow: 0 0 0 2pt var(--button-color);
        }
    }

    .button_icon {
        display: grid;
        place-items: center;
    }

    .interactive {
        position: absolute;
        inset: -30px;
        pointer-events: none;
    }
}

.ripple {
    position: relative;
}

.ripple text {
    position: relative;
    z-index: 5;
    transition: color var(--time);
}

.ripple:hover text {
    color: #fff;
}

.bubble-wrapper {
    position: absolute;
    inset: 0;
    overflow: hidden;
    pointer-events: none;
    border-radius: $radius-default;
}

.ripple .button-bubble {
    border-radius: 100%;
    position: absolute;
    display: block;
    content: '';
    z-index: -1;
    width: calc(var(--width) * var(--bubble-scale));
    aspect-ratio: 1/1;

    left: var(--percentage-x);
    top: var(--percentage-y);

    translate: -50% -50%;

    &.hide {
        --width: 0px;
    }
}

.hide {
    color: transparent;
}

.preloader {
    margin: 0;
    position: absolute;
}
</style>

<docs>
  ```jsx
  <div>
    <q-button variation="primary" size="large" loading>Primary Button</q-button>
    <q-button variation="primary" size="medium">Medium</q-button>
    <q-button variation="primary" size="small">Small</q-button>
    <q-button variation="primary" size="xsmall">Small</q-button>
    <q-button variation="primary" size="medium" state="hover">Hover</q-button>
    <q-button variation="primary" size="medium" state="disabled">Disabled</q-button>

    <br /><br />
    <q-button variation="secondary" size="large" icon="check">Afronden</q-button>
    <q-button variation="secondary" size="medium">Medium</q-button>
    <q-button variation="secondary" size="small">Small</q-button>
    <q-button variation="secondary" size="xsmall">Small</q-button>
    <q-button variation="secondary" size="medium" state="hover">Hover</q-button>
    <q-button variation="secondary" size="medium" state="disabled">Disabled</q-button>
    <br /><br />
    <q-button variation="ghost" size="large">Primary Button</q-button>
    <q-button variation="ghost" size="medium">Medium</q-button>
    <q-button variation="ghost" size="small">Small</q-button>
    <q-button variation="ghost" size="xsmall">Small</q-button>
    <q-button variation="ghost" size="medium" state="hover">Hover</q-button>
    <q-button variation="ghost" size="medium" state="disabled">Disabled</q-button>
    <br /><br />
    <q-button variation="light" size="large">Primary Button</q-button>
    <q-button variation="light" size="medium">Medium</q-button>
    <q-button variation="light" size="small">Small</q-button>
    <q-button variation="light" size="xsmall">Small</q-button>
    <q-button variation="light" size="medium" state="hover">Hover</q-button>
    <q-button variation="light" size="medium" state="disabled">Disabled</q-button>
    <br /><br />
    <q-button variation="blank" size="large">Primary Button</q-button>
    <q-button variation="blank" size="medium">Medium</q-button>
    <q-button variation="blank" size="small">Small</q-button>
    <q-button variation="blank" size="xsmall">Small</q-button>
    <q-button variation="blank" size="medium" state="hover">Hover</q-button>
    <q-button variation="blank" size="medium" state="disabled">Disabled</q-button>
    
  </div>
  ```
</docs>
